import "./UsersMngt.scss";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { UsersContext } from "../context/UsersContext";
import { SnackBarsContext } from "../context/SnackBarsContext";
import { AuthContext } from "../context/AuthContext";
import Loader from "../components/Loader";
import UserForm from "../components/forms/UserForm";
import * as api from "../shared/api";

const UsersMngt = () => {
  const { usersContextData, usersContextDispatch } = useContext(UsersContext);
  const { authContextData } = useContext(AuthContext);
  const { dispatchSnackBar } = useContext(SnackBarsContext);
  const [search, setSearch] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [deleterUser, setDeleteUser] = useState(null);
  const [loading, setLoding] = useState(false);

  const getUsers = useCallback(async () => {
    const res = await api.getUsers();
    if (res.data.success) {
      usersContextDispatch({ type: "SET_USERS", payload: res.data.data });
      setLoding(false);
    } else {
      dispatchSnackBar({
        type: "SET_ERROR",
        payload: "Something went wrong please contact support",
      });
    }
  });

  const deleteUser = useCallback(async () => {
    try {
      const res = await api.deleteUser(deleterUser.id);
      if (res.data.success) {
        usersContextDispatch({ type: "DELETE_USERS", payload: deleterUser.id });
        dispatchSnackBar({
          type: "SET_SUCCESS",
          payload: "User Deleted.",
        });
        setDeleteUser(null);
      }
      if (!res.data.success) {
        dispatchSnackBar({
          type: "SET_ERROR",
          payload: "Something went wrong please contact support",
        });
      }
    } catch (err) {
      dispatchSnackBar({
        type: "SET_ERROR",
        payload: "Something went wrong please contact support",
      });
    }
  });

  useEffect(() => {
    if (usersContextData.usersList.length === 0 && authContextData.isAuth) {
      setLoding(true);
      (async () => {
        await getUsers();
      })();
    }
  }, [authContextData.isAuth]);

  useEffect(() => {
    setFilteredUsers(
      usersContextData.usersList?.filter((user) => {
        return user.name.toLowerCase().includes(search.toLowerCase()) || 
        user.company.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase())
      })
    );
  }, [search, usersContextData.usersList, authContextData]);

  return (
    <div className="UsersMngt">
      {deleterUser ? (
        <div className="popup">
          <div className="content">
            <h2>
              Delete <strong>{deleterUser?.name}</strong> ?
            </h2>
            <div
              className="btn blue"
              onClick={() => {
                setDeleteUser(null);
              }}
            >
              Discard
            </div>
            <div
              className="btn red"
              onClick={() => {
                deleteUser();
              }}
            >
              Delete
            </div>
          </div>
        </div>
      ) : null}
      <div className="container">
        <div className="content">
          <div className="users">
            <div className="search-wrapper">
              <input
                type="text"
                placeholder="Search..."
                name="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div
              className={
                loading ? "users-list loading" : filteredUsers?.length === 0 ? "users-list loading" : "users-list"
              }
            >
              {loading ? (
                <Loader />
              ) : filteredUsers?.length === 0 ? (
                <h2 className="no-search-results">No results</h2>
              ) : (
                filteredUsers?.map((item) => {
                  return (
                    <div className="user" key={item.id}>
                      <img src={require("../assets/images/user-icon.png").default} alt="" />
                      <div className="details">
                        <h2 className="name">{item?.name}</h2>
                        <h3 className="company">{item?.company}</h3>
                        <h3 className="email">{item?.email}</h3>
                      </div>
                      <div className="actions">
                        <div
                          className="btn blue"
                          onClick={() => {
                            usersContextDispatch({ type: "EDIT_FORM", payload: item });
                          }}
                        >
                          Edit
                        </div>
                        <div
                          className="btn red"
                          onClick={() => {
                            setDeleteUser(item);
                          }}
                        >
                          Delete
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
            <div
              className="add-new"
              onClick={() => {
                usersContextDispatch({
                  type: "NEW_FORM",
                });
              }}
            >
              <img src={require("../assets/images/plus.png").default} alt="" />
              <h2 className="text">Create New User</h2>
            </div>
          </div>
          <div className="form">
            {usersContextData.user ? (
              <UserForm />
            ) : (
              <h2 className="instruction">
                In this window you can create a new user, delete an existing one or update users info.
              </h2>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersMngt;
