import axios from "axios";

const url = "/priority";
const priorityUser = 'A7C54A1D7F2349959C957EDB5D325FEE';
const priorityUserPass = 'PAT';


export const getSites = async (data, top, skip, isIncludePagination) => {
  let requestData = {
    text: `FULLDES eq '*${data?.text?.length === 0 ? "*" : data.text}*'`,
    group_size: (data.group_size === '0' || !data.group_size) ? "" : `and MAXNUM gt ${data.group_size}`,
    area: `AREAS eq '*${data.area}*'`,
    classification:
      data.classification.length === 0
        ? "and ATTRTYPES eq '***'"
        : data.classification
            .map((item) => {
              return `and ATTRTYPES eq '*${item}*'`;
            })
            .join(""),
    holy_places:
      data.holy_places.length === 0
        ? "and HOLLYSITES eq '***'"
        : data.holy_places
            .map((item) => {
              return `and HOLLYSITES eq '*${item}*'`;
            })
            .join(""),
    language: `and LANGUAGES eq '*${data.language}*'`,
    food: data.food ? "and FOOD eq 'Y'" : "",
    kosher: data.kosher ? "and KOSHER eq 'Y'" : "",
    wine_and_alcohol: data.wine_and_alcohol ? "and WINE eq 'Y'" : "",
    accessibility: data.accessibility ? "and ACCESSIBILITY eq 'Y'" : "",
    suitable_for_children: data.suitable_for_children ? "and KIDS eq 'Y'" : "",
    activities_for_schools: data.activities_for_schools ? "and SCHOOL eq 'Y'" : "",
    weekend_and_holiday:
      data.weekend_and_holiday.length === 0
        ? "and HOLIDAYS eq '***'"
        : data.weekend_and_holiday
            .map((item) => {
              return `and HOLIDAYS eq '*${item}*'`;
            })
            .join(""),

    free_activities:
    data.free_activities.length === 0
      ? "and F_CODE eq '***'"
      : data.free_activities
          .map((item) => {
            return `and F_CODE eq '*${item}*'`;
          })
          .join(""),
  };

  const urlToRequest = isIncludePagination ? 
  `${url}/ZITA_WEBATTRACTIONS?$top=${top}&$skip=${skip*top}&$filter=${
    requestData.area +
    requestData.classification +
    requestData.holy_places +
    requestData.language +
    requestData.food +
    requestData.kosher +
    requestData.wine_and_alcohol +
    requestData.accessibility +
    requestData.suitable_for_children +
    requestData.activities_for_schools +
    requestData.weekend_and_holiday
  } &$expand=ZITA_WATTRACTIONSTXT_SUBFORM,ZITA_WEBATTREXTFILE_SUBFORM,ZITA_WEBACT_SUBFORM`
  // ($filter=(${
  //   requestData.text + 
  //   requestData.group_size
  // }))
  : 

  `${url}/ZITA_WEBATTRACTIONS?$filter=${
    requestData.area +
    requestData.classification +
    requestData.holy_places +
    requestData.language +
    requestData.food +
    requestData.kosher +
    requestData.wine_and_alcohol +
    requestData.accessibility +
    requestData.suitable_for_children +
    requestData.activities_for_schools +
    requestData.weekend_and_holiday
  } &$expand=ZITA_WATTRACTIONSTXT_SUBFORM,ZITA_WEBATTREXTFILE_SUBFORM,ZITA_WEBACT_SUBFORM`;

  // ($filter=(${
  //   requestData.text + 
  //   requestData.group_size
  // }))
  return axios(
    urlToRequest,
    {
      auth: { 
        username: priorityUser,
        password: priorityUserPass,
      },
    }
  );
};

export const getSitesByProvider = async (provider, top, skip) => {
  return axios(`${url}/ZITA_WEBATTRACTIONS?$top=${top}&$skip=${skip*top}&$expand=ZITA_WATTRACTIONSTXT_SUBFORM,ZITA_WEBATTREXTFILE_SUBFORM`, {
    auth: { 
      username: priorityUser,
      password: priorityUserPass,
    },
  });
  // return axios(`${url}/ZITA_WEBATTRACTIONS`, {
  //   auth: { 
  //     username: priorityUser,
  //     password: priorityUserPass,
  //   },
  // });
};

// export const getSitesByProvider = async (username, password, provider) => {
//   return axios(
//     `${url}/ZITA_WEBATTRACTIONS?$filter=SUPNAME eq '${provider}'&$expand=ZITA_WATTRACTIONSTXT_SUBFORM,ZITA_WEBATTREXTFILE_SUBFORM`,
//     {
//       auth: {
//         username,
//         password,
//       },
//     }
//   );
// };

export const getSite = async (id) => {
  return axios(
    // `${url}/ZITA_ATTRACTIONTEXTQ?$filter=ATTRCODE eq ${id}&$expand=ZITA_WATTRACTIONSTXT_SUBFORM,ZITA_WEBATTREXTFILE_SUBFORM,ZITA_WEBACT_SUBFORM($expand=ZITA_WEBACTTEXT_SUBFORM,ZITA_WEBACTTEXT2_SUBFORM,ZITA_WEBACTADD_SUBFORM,ZITA_WEBACTPLS_SUBFORM)`,
    `${url}/ZITA_WEBATTRACTIONS?$filter=ATTRCODE eq ${id}&$expand=ZITA_WATTRACTIONSTXT_SUBFORM,ZITA_WEBATTREXTFILE_SUBFORM,ZITA_WEBACT_SUBFORM($expand=ZITA_WEBACTTEXT_SUBFORM,ZITA_WEBACTTEXT2_SUBFORM,ZITA_WEBACTADD_SUBFORM,ZITA_WEBACTPLS_SUBFORM)`,
    {
      auth: { 
        username: priorityUser,
        password: priorityUserPass,
      },
    }
  );
};

export const getSitesByStr= async (substr) => {
  ////($filter=(FULLDES eq '*alexander*'))
  return axios(
    `${url}/ZITA_WEBATTRACTIONS?$filter=ATTRDES eq '*${substr}*'&$expand=ZITA_WATTRACTIONSTXT_SUBFORM,ZITA_WEBATTREXTFILE_SUBFORM,ZITA_WEBACT_SUBFORM($expand=ZITA_WEBACTTEXT_SUBFORM,ZITA_WEBACTTEXT2_SUBFORM,ZITA_WEBACTADD_SUBFORM,ZITA_WEBACTPLS_SUBFORM)`,
    {
      auth: { 
        username: priorityUser,
        password: priorityUserPass,
      },
    }
  );
};

export const getUserOrders = async (custname, email) => {
  //ORDERS?$filter=CUSTNAME eq '50045'
  return axios(
    //`${url}/ZITA_ATTRACTIONS?$filter=ATTRCODE eq ${custname} &$expand=ZITA_ACT_SUBFORM($select=KLINE,ACTDES; $expand=ZITA_ACTPLS_SUBFORM)&$select=ATTRCODE,ATTRDES`,
    `${url}/ORDERS?$filter=CUSTNAME eq '${custname}' and ZITA_USERNAME eq '${email}'`,
    {
      auth: { 
        username: priorityUser,
        password: priorityUserPass,
      },
    }
  );
};

export const getOrderData = async (order) => {
  //ORDERS?$filter=CUSTNAME eq '50045'
  return axios(
    //`${url}/ZITA_ATTRACTIONS?$filter=ATTRCODE eq ${custname} &$expand=ZITA_ACT_SUBFORM($select=KLINE,ACTDES; $expand=ZITA_ACTPLS_SUBFORM)&$select=ATTRCODE,ATTRDES`,
    `${url}/ORDERS('${order}')/ORDERITEMS_SUBFORM?$select=PDES,TQUANT,PRICE,QPRICE,ZITA_ATTRDES,REMARK1`,
    {
      auth: { 
        username: priorityUser,
        password: priorityUserPass,
      },
    }
  );
};

export const getActivity = async (site) => {
  return axios(
    `${url}/ZITA_WEBATTRACTIONS?$select=ATTRCODE&$filter=ATTRCODE eq ${site}&$expand=ZITA_WEBACT_SUBFORM($expand=ZITA_WEBACTTEXT_SUBFORM, ZITA_WEBACTADD_SUBFORM, ZITA_WEBACTPLS_SUBFORM, ZITA_WEBACTTEXT2_SUBFORM)`,
    {
      auth: { 
        username: priorityUser,
        password: priorityUserPass,
      },
    }
  );
};

export const getActivityNew = async (site) => {
  return axios(
    `${url}/ZITA_ATTRACTIONS?$filter=ATTRCODE eq ${site} &$expand=ZITA_ACT_SUBFORM($select=KLINE,ACTDES; $expand=ZITA_ACTPLS_SUBFORM)&$select=ATTRCODE,ATTRDES`,
    {
      auth: { 
        username: priorityUser,
        password: priorityUserPass,
      },
    }
  );
};

export const getTariff = async (site, activity) => {
  return axios(
    `${url}/ZITA_WEBATTRACTIONS?$select=ATTRCODE&$filter=ATTRCODE eq ${site}&$expand=ZITA_WEBACT_SUBFORM($select=KLINE ;$filter=KLINE eq ${activity};$expand=ZITA_WEBACTPLS_SUBFORM)`,
    {
      auth: { 
        username: priorityUser,
        password: priorityUserPass,
      },
    }
  );
};

export const createUser = async (data) => {
  return axios({
    method: "post",
    url: "/priority/ZCOR_GENERALLOADLOG",
    auth: { 
        username: priorityUser,
        password: priorityUserPass,
    },
    data,
  });
};

export const createSite = async (data) => {
  return axios({
    method: "post",
    url: "/priority/ZCOR_GENERALLOADLOG",
    auth: { 
        username: priorityUser,
        password: priorityUserPass,
    },
    data,
  });
};

export const createSiteWithExcel = async (data) => {
  return axios({
    method: "post",
    url: "/priority/ZCOR_GENERALLOADLOG",
    auth: { 
        username: priorityUser,
        password: priorityUserPass,
},
    data,
  });
}

export const createSiteLngLatExcel = async (data) => {
  return axios({
    method: "post",
    url: "/priority/ZCOR_GENERALLOADLOG",
    auth: { 
        username: priorityUser,
        password: priorityUserPass,
},
    data,
  });
}

export const createActivity = async (data) => {
  return axios({
    method: "post",
    url: "/priority/ZCOR_GENERALLOADLOG",
    auth: { 
        username: priorityUser,
        password: priorityUserPass,
},
    data,
  });
};

export const verifyPriorityUser = async (data) => {
  return axios({
    method: "post",
    url: "/priority/ZCR_WEBPASSWORDCHK",
    auth: { 
      username: priorityUser,
      password: priorityUserPass,
    },
    data,
  });
};

export const addFileToSite = async (data) => {
  return axios({
    method: "post",
    url: "/priority/ZCOR_GENERALLOADLOG",
    auth: { 
      username: priorityUser,
      password: priorityUserPass,
    },
    data,
  });
};

export const deletingExistingFile = async (fileId, siteId) => {
  return axios({
    method: "delete",
    url: `/priority/ZITA_ATTRACTIONS(${siteId})/ZITA_ATTREXTFILE_SUBFORM(${fileId})`,
    auth: { 
      username: priorityUser,
      password: priorityUserPass,
    },
    // data,
  });
}

export const addFileToSiteExcel = async (data) => {
  return axios({
    method: "post",
    url: "/priority/ZCOR_GENERALLOADLOG",
    auth: { 
      username: priorityUser,
      password: priorityUserPass,
    },
    data,
  });
};

export const addAactivityCancellation = async (data) => {
  return axios({
    method: "post",
    url: "/priority/ZCOR_GENERALLOADLOG",
    auth: { 
      username: priorityUser,
      password: priorityUserPass,
    },
    data,
  });
};

export const addAactivityAddition = async (data) => {
  return axios({
    method: "post",
    url: "/priority/ZCOR_GENERALLOADLOG",
    auth: { 
      username: priorityUser,
      password: priorityUserPass,
    },
    data,
  });
};

export const createTariff = async (data) => {
  return axios({
    method: "post",
    url: "/priority/ZCOR_GENERALLOADLOG",
    auth: { 
        username: priorityUser,
        password: priorityUserPass,
    },
    data,
  });
};

export const createOrder = async (data) => {
  return axios({
    method: "post",
    url: "/priority/ZCOR_GENERALLOADLOG",
    auth: { 
        username: priorityUser,
        password: priorityUserPass,
    },
    data,
  });
};

export const getSiteExcel = async () => {
  return axios({
    method: "get",
    url: `${url}/ZITA_WEBATTRACTIONS`,
    auth: { 
      username: priorityUser,
      password: priorityUserPass,
    },
    // data,
  });
};

export const createAgent = async (data, username, password) => {
  return axios({
    method: "post",
    url: "/priority/ZCOR_GENERALLOADLOG",
    auth: { 
      username: priorityUser,
      password: priorityUserPass,
    },
    data,
  });
};

export const ClearEntityMetadata = async () => {
  return axios({
    method: "post",
    url: "/priority/ClearEntityMetadata",
    auth: { 
        username: priorityUser,
        password: priorityUserPass,
    },
    data: {
      "Entity": "ORDERS"
    },
  });
};
