import "./Footer.scss";
import React from "react";

const Footer = () => {
  return (
    <div className='Footer'>
      <div className='content'>
        <div className='logo'>
          <img src={require("../assets/images/logo.png").default} alt='logo' />
          <h2>Information Center</h2>
        </div>
        <div className='rights'>
          <h2>All Rights Reserved © {new Date().getFullYear()}</h2>
        </div>
        <div className='theguy'>
          <a href='https://theguy.co.il/' target='_blanck'>
            <img src={require("../assets/images/theguy.png").default} alt='theguy logo' />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
