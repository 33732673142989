export const SnackBarReducer = (state, action) => {
  switch (action.type) {
    case "SET_SUCCESS":
      return { ...state, class: "SnackBars success", msg: action.payload };
    case "SET_ERROR":
      return { ...state, class: "SnackBars error", msg: action.payload };
    case "RESET_SNACKBAR":
      return { ...state, class: "SnackBars", msg: "" };
    default:
      return state;
  }
};
