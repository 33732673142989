import "./Map.scss";
import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
import SliderInput from "../components/mapinputs/SliderInput";
import CheckboxInput from "../components/mapinputs/CheckboxInput";
import SelectInput from "../components/mapinputs/SelectInput";
import ReactMapGL, { NavigationControl, Marker } from "react-map-gl";
import MapPopup from "../components/MapPopup";
import * as priorityApi from "../shared/priorityApi";
import * as api from "../shared/api";
import { AuthContext } from "../context/AuthContext";
import FullSite from "../components/FullSite";
import Loader from "../components/Loader";
import { useHistory, useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import FloatingSelect from "../components/inputs/FloatingSelect";
import {ReactComponent as PrevIcon} from '../assets/images/arrow_back.svg'; 
import {ReactComponent as NextIcon} from '../assets/images/arrow_forward.svg'; 

const Map = () => {
  const [data, setData] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [popup, setPopup] = useState(null);
  const [searchMsg, setSearchMsg] = useState("");
  const [searchData, setSearchData] = useState({
    text: "",
    group_size: "",
    area: "*",
    classification: [],
    holy_places: [],
    language: "*",
    food: null,
    kosher: null,
    wine_and_alcohol: null,
    accessibility: null,
    suitable_for_children: null,
    activities_for_schools: null,
    weekend_and_holiday: [],
    free_activities: [],
  });
  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
    latitude: 32.109333,
    longitude: 34.855499,
    zoom: 7,
    padding: 20,
    offset: [0, -100],
    mapboxApiAccessToken: process.env.REACT_APP_MAPBOX_TOKEN,
  });
  const [loading, setLoding] = useState(false);
  const [filterStatus, setFilterStatus] = useState({ az: false, reviews: false });
//katy
  const location = useLocation();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const step = 100;

  const handleChange = (e) => {
    setSearchData({
      ...searchData,
      [e.target.name]: e.target.value.toLowerCase(),
    });
    localStorage.setItem('searchData', JSON.stringify({
      ...searchData,
      [e.target.name]: e.target.value.toLowerCase(),
    }));
  };

  const handleChangeSlider = (event, newValue) => {
    setSearchData({ ...searchData, group_size: newValue });    
  };

  const handleCleanSearchParams = (group, searchValue) => {
    const searchParams = new URLSearchParams(location.search);
    const keyValueObject = [...searchParams].reduce((acc, [key, value]) => {
        acc[key] = value.includes(',') ? value.split(',').map(item => item.trim()) : [value];
        return acc;
    }, {});

    let valueIndexInGroup = keyValueObject[group]?.indexOf(searchValue.replace(/ /g, '_'));

    if(Object.keys(keyValueObject).length === 0) return;

    if (valueIndexInGroup !== -1) keyValueObject[group] = keyValueObject[group].filter(value => value !== searchValue.replace(/ /g, '_'));
    

    let query_str = Object.entries(keyValueObject)?.filter(([key, value]) =>
        value &&
        (Array.isArray(value) ? value.length > 0 : value !== '*')
      )
      .map(([key, value]) => {
        const inner_string = Array.isArray(value)
          ? value.map(element => element.replaceAll(' ', '_')).join(',')
          : value.replaceAll(' ', '_');

        return `${key}=${inner_string}`;
      })
      .join('&');

    history.replace({ pathname: location.pathname, search: query_str });
  }

  const handleChangeSelect = (value, name) => {
    if(value?.label === 'All') handleCleanSearchParams(name, searchData[name]);
    setSearchData({
      ...searchData,
      [name]: value?.value ? value.value : value,
    });
    
  };

  const handleChangeCheckBox = (group, value, e) => {
    setSearchData({ ...searchData, [e.target.name]: e.target.checked ? "Y" : null });
    if(!e.target.checked){
      handleCleanSearchParams(e.target.name, "Y");
    }
  };

  const handleChangeCheckBoxMulti = (group, value) => {
    let newData = [...searchData[group]];

    if (newData.includes(value)) {
      //if clicked at value that already in newData, means user wants to remove it (displayed as checkbox)
      let index = newData.indexOf(value);
      newData.splice(index, 1);
            
      handleCleanSearchParams(group, value);
    } else {
      newData.push(value);
    }
    setSearchData({ ...searchData, [group]: newData });
  };

  const closePopup = () => {
    setPopup(null);
  };

  const getSites = useCallback(async (e, searchedQuery, newPage) => {  
    if(!newPage) newPage = page;
    const temp_searched_data = searchedQuery ? {...searchedQuery} : {...searchData};

    let query_str = '';

    for (const key in temp_searched_data) {
      if (
        temp_searched_data[key] &&
        (Array.isArray(temp_searched_data[key]) ? temp_searched_data[key].length > 0 : temp_searched_data[key] !== '*')
      ) {
        let inner_string = '';
    
        if (Array.isArray(temp_searched_data[key])) {
          inner_string = temp_searched_data[key].map(element => element.replaceAll(' ', '_')).join(',');
        } else {
          inner_string = temp_searched_data[key].replaceAll(' ', '_');
        }
    
        query_str += `${key}=${inner_string}&`;
      }
    }
    
    if (query_str) {
      query_str = query_str.slice(0, -1);
      history.replace({ pathname: location.pathname, search: query_str });
      localStorage.setItem('searchData', JSON.stringify(temp_searched_data));
    }
    
    setLoding(true);
    const objToCompare = {
      text: "",
      group_size: "",
      area: "*",
      classification: [],
      holy_places: [],
      language: "*",
      food: null,
      kosher: null,
      wine_and_alcohol: null,
      accessibility: null,
      suitable_for_children: null,
      activities_for_schools: null,
      weekend_and_holiday: [],
    };
    let res = await priorityApi.getSites(
      {...temp_searched_data, group_size: ''}, 
      step, 
      newPage, 
      JSON.stringify(objToCompare) === JSON.stringify(temp_searched_data)
    );
    res = res.data.value;

    if (temp_searched_data.text.length > 0) {
      res = res.filter((item) => item?.ATTRDES && (item?.ATTRDES.toLowerCase().includes(temp_searched_data.text.toLowerCase())) /* && item.ACTIVEDES === "פעילה" */);
    }

    if (+temp_searched_data.group_size > 0) {
      let updated_group_size_to = +temp_searched_data.group_size;
      let updated_group_size_from;

      if(updated_group_size_to === 10){
        updated_group_size_from = 0;
      } else if(updated_group_size_to === 50){
        updated_group_size_from = 11;
      } else if(updated_group_size_to === 150){
        updated_group_size_from = 51;
      } else {
        updated_group_size_from = 151;
      }

      // res = res.filter((item) => item?.ATTRDES && item?.ATTRDES.includes(temp_searched_data.text) /* && item.ACTIVEDES === "פעילה" */);
      res = res.filter((item=> item?.ZITA_WEBACT_SUBFORM[0]?.MAXNUM <= updated_group_size_to));
    }

    if(temp_searched_data.free_activities.length > 0){
      const activities = temp_searched_data.free_activities.map(activity=>{
        return activity === '00' ? null : activity;
      })
      // console.log('activities', activities);
      // console.log('res', res)
      res = res.filter((item) => 
        item?.ZITA_WEBACT_SUBFORM?.find(subitem => 
          activities.includes(subitem?.F_CODE)
        )
      );
    }

    if (!res) {
      setLoding(false);
      setSearchMsg("No results, please use another keyword.");
    } else {
      let ids = res.map((item) => item.ATTRCODE);

      let ratingResult = await api.getBulkRating(ids);
      res.forEach((atr) => {
        const rating = ratingResult.data.find((rate) => rate.attraction_id === atr.ATTRCODE);
        if (rating) {
          atr.rating = rating.ratings;
        } else {
          atr.rating = 0;
        }
      });
      let commentsResult = await api.getBulkComments(ids);

      res.forEach((atr) => {
        const comments = commentsResult?.data[0]?.find((coment) => coment.attraction_id == atr.ATTRCODE);
        if (comments) {
          atr.comments = comments.comments;
        } else {
          atr.comments = 0;
        }
      });
      setData(res);
      setPage(newPage);
      setLoding(false);
    }
  });

  const filterByAZ = () => {
    const sortedData = [...data];
    if (filterStatus.az) {
      sortedData.sort(function (a, b) {
        if ((a?.ATTRDES && a?.ATTRDES.toLowerCase()) > (b?.ATTRDES && b?.ATTRDES.toLowerCase())) return -1;
        if ((a?.ATTRDES && a?.ATTRDES.toLowerCase()) < b?.ATTRDES && b?.ATTRDES.toLowerCase()) return 1;
        return 0;
      });
      setFilterStatus({ ...filterStatus, az: false });
    } else {
      sortedData.sort(function (a, b) {
        if ((a?.ATTRDES && a?.ATTRDES.toLowerCase()) < (b?.ATTRDES && b?.ATTRDES.toLowerCase())) return -1;
        if ((a?.ATTRDES && a?.ATTRDES.toLowerCase()) > (b?.ATTRDES && b?.ATTRDES.toLowerCase())) return 1;
        return 0;
      });
      setFilterStatus({ ...filterStatus, az: true });
    }
    setData(sortedData);
  };

  const filterByReviews = () => {
    const sortedData = [...data];
    if (filterStatus.reviews) {
      sortedData.sort(function (a, b) {
        if (a.rating < b.rating) return -1;
        if (a.rating > b.rating) return 1;
        return 0;
      });
      setFilterStatus({ ...filterStatus, reviews: false });
    } else {
      sortedData.sort(function (a, b) {
        if (a.rating > b.rating) return -1;
        if (a.rating < b.rating) return 1;
        return 0;
      });
      setFilterStatus({ ...filterStatus, reviews: true });
    }
    setData(sortedData);
  };

  const fillterRef = useRef(null);
  const fillterRefBtn = useRef(null);
  const searchRef = useRef(null);
  const fillterRefBtn2 = useRef(null);

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (
        e.target !== fillterRef.current &&
        !fillterRef?.current?.contains(e.target) &&
        e.target !== fillterRefBtn.current &&
        !fillterRefBtn?.current?.contains(e.target) &&
        e.target !== fillterRefBtn2.current &&
        !fillterRefBtn2?.current?.contains(e.target) &&
        e.target !== searchRef.current &&
        !searchRef?.current?.contains(e.target)
      ) {
        setOpenFilter(false);
      }
    });
  }, []); 

  //adding search data to local storage to save searched query
  useEffect(() => {
    const searchedQuery = JSON.parse(localStorage.getItem('searchData'));
    if(searchedQuery && history.location.search.length > 0){
      setSearchData(searchedQuery);
      return getSites(null, searchedQuery);
    } else{
      return getSites();
    }

  }, [])

  useEffect(() => {
    localStorage.setItem('searchData', JSON.stringify({ ...searchData}));
  }, [searchData])
  
  
  const blurValidation = () => {
    return true
  };


  return (
    <div className="Map">
      <div className="container">
        <div className="content">
          <div className="group-wrapper">
            <div className="group">
              <div
                className="btn"
                onClick={() => {
                  setOpenFilter(!openFilter);
                }}
                ref={fillterRefBtn}
              >
                <i>
                  <img src={require("../assets/images/filter.png").default} />
                </i>
                Filters
              </div>
              <div className="input-wrapper">
                {/* <span className="title">Group Size: {searchData.group_size}</span> */}
                <FloatingSelect
                  color="#93c840"
                  label="Group Size"
                  selectValue={searchData.group_size}
                  onChange={(e) => handleChangeSelect(e.value, 'group_size')}
                  placeholder=""
                  name="activity_participates"
                  options={[
                    { value: '10', label: "Up to 10 person" },
                    { value: '50', label: "Up to 50 person" },
                    { value: '150', label: "51 - 150 person" },
                    { value: '500', label: "More than 150 person" },
                  ]}
                  validation={blurValidation}
                  validationType="text"
                  validationRoles={{ name: "Group Size", require: true, min: 1 }}
                  formStep="1"
                  direction="left"
                />
                {/* <SliderInput defaultValue={searchData.group_size} value={searchData.group_size} onChange={handleChangeSlider} /> */}
              </div>
            </div>
          </div>

          <div className="search-wrapper">
            <div className="search">
              <input
                type="text"
                class="style"
                placeholder="Search..."
                defaultValue={searchData.text}
                value={searchData.text}
                onChange={handleChange}
                name="text"
              />
              <SelectInput
                label="Area"
                placeholder="Select Area"
                options={[
                  { value: "*", label: "Select Area" },
                  { value: "North", label: "North" },
                  { value: "Around the Sea of ​​Galilee", label: "Around the Sea of ​​Galilee" },
                  { value: "Haifa", label: "Haifa" },
                  { value: "Center", label: "Center" },
                  { value: "Tel Aviv", label: "Tel Aviv" },
                  { value: "Jerusalem", label: "Jerusalem" },
                  { value: "West Bank", label: "West Bank" },
                  { value: "South", label: "South" },
                ]}
                name="area"
                onChange={(value) => {
                  handleChangeSelect(value, "area");
                }}
                defaultValue={searchData.area}
                selectValue={searchData.area}
                styles="areaStyles"
              />
              <div className="search-btn" onClick={getSites}>
                Search
              </div>
            </div>
          </div>

          <div className="data-filters-wrapper">
            <div className={openFilter ? "filters-wrapper open" : "filters-wrapper"}>
              <div className={openFilter ? "group-search open" : "group-search"}>
                <div className="search-wrapper" ref={searchRef}>
                  <div className="search">
                    <input
                      type="text"
                      class="style"
                      placeholder="Search..."
                      defaultValue={searchData.text}
                      value={searchData.text}
                      onChange={handleChange}
                      name="text"
                    />
                    <SelectInput
                      label="Area"
                      placeholder="Area"
                      options={[
                        { value: "*", label: "All" },
                        { value: "North", label: "North" },
                        { value: "Around the Sea of ​​Galilee", label: "Around the Sea of ​​Galilee" },
                        { value: "Haifa", label: "Haifa" },
                        { value: "Center", label: "Center" },
                        { value: "Tel Aviv", label: "Tel Aviv" },
                        { value: "Jerusalem", label: "Jerusalem" },
                        { value: "West Bank", label: "West Bank" },
                        { value: "South", label: "South" },
                        { value: "Negev", label: "Negev" },
                        { value: "Sharon", label: "Sharon" },
                        { value: "Judea and Samaria", label: "Judea and Samaria"},
                      ]}
                      name="area"
                      onChange={(value) => {
                        handleChangeSelect(value, "area");
                      }}
                      selectValue={searchData.area}
                      defaultValue={searchData.area}
                      styles="areaStyles"
                    />
                    <div className="input-wrapper">
                      <span className="title">Group Size: {searchData.group_size}</span>
                      <SliderInput value={searchData.group_size} defaultValue={searchData.group_size} onChange={handleChangeSlider} />
                    </div>
                    <div
                      className="search-btn"
                      onClick={() => {
                        setOpenFilter(!openFilter);
                        getSites();
                      }}
                    >
                      Search
                    </div>
                  </div>
                </div>
              </div>
              <div className={openFilter ? "filter open" : "filter"} ref={fillterRef}>
                <div className="title">Classification</div>
                <div className="classification-checkboxs-wrapper">
                  <CheckboxInput
                    placeholder="Museum"
                    group="classification"
                    value="Museum"
                    defaultChecked={searchData.classification.includes('Museum') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  {/* <CheckboxInput
                    placeholder="National Garden"
                    group="classification"
                    value="National Garden"
                    onChange={handleChangeCheckBoxMulti}
                  /> */}
                  <CheckboxInput
                    placeholder="Nature Reserve and Parks"
                    group="classification"
                    value="Nature Reserve and Parks"
                    defaultChecked={searchData.classification.includes('Nature Reserve and Parks') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput
                    placeholder="Out Door and Sport Activities"
                    group="classification"
                    value="Out Door and Sport Activities"
                    defaultChecked={searchData.classification.includes('Out Door and Sport Activities') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput
                    placeholder="Amusement Park"
                    group="classification"
                    value="Amusement Park"
                    defaultChecked={searchData.classification.includes('Amusement Park') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput
                    placeholder="Workshops"
                    group="classification"
                    value="Workshops"
                    defaultChecked={searchData.classification.includes('Workshops') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput
                    placeholder="Wine Tasting"
                    group="classification"
                    value="Wine Tasting"
                    defaultChecked={searchData.classification.includes('Wine Tasting') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput
                    placeholder="Tours"
                    group="classification"
                    value="Tours"
                    defaultChecked={searchData.classification.includes('Tours') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput
                    placeholder="Home Hospitality"
                    group="classification"
                    value="Home Hospitality"
                    defaultChecked={searchData.classification.includes('Home Hospitality') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput
                    placeholder="Cable"
                    group="classification"
                    value="Cable"
                    defaultChecked={searchData.classification.includes('Cable') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput
                    placeholder="Historical Sites"
                    group="classification"
                    value="Historical Sites"
                    defaultChecked={searchData.classification.includes('Historical Sites') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput
                    placeholder="Spa and Pools"
                    group="classification"
                    value="Spa and Pools"
                    defaultChecked={searchData.classification.includes('Spa and Pools') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput
                    placeholder="Art and Culture"
                    group="classification"
                    value="Art and Culture"
                    defaultChecked={searchData.classification.includes('Art and Culture') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput
                    placeholder="Restaurants"
                    group="classification"
                    value="Restaurants"
                    defaultChecked={searchData.classification.includes('Restaurants') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput
                    placeholder="Agriculture"
                    group="classification"
                    value="Agriculture"
                    defaultChecked={searchData.classification.includes('Agriculture') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput
                    placeholder="Hospitality"
                    group="classification"
                    value="Hospitality"
                    defaultChecked={searchData.classification.includes('Hospitality') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />

                  <CheckboxInput
                    placeholder="Others"
                    group="classification"
                    value="Others"
                    defaultChecked={searchData.classification.includes('Others') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                </div>
                <div className="title">Holy Places</div>
                <div className="holyplaces-checkboxs-wrapper">
                  <CheckboxInput
                    placeholder="Judaism"
                    group="holy_places"
                    value="Judaism"
                    defaultChecked={searchData.holy_places.includes('Judaism') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput
                    placeholder="Christianity"
                    group="holy_places"
                    value="Christianity"
                    defaultChecked={searchData.holy_places.includes('Christianity') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput
                    placeholder="Islam"
                    group="holy_places"
                    value="Islam"
                    defaultChecked={searchData.holy_places.includes('Islam') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput
                    placeholder="Others"
                    group="holy_places"
                    value="Others"
                    defaultChecked={searchData.holy_places.includes('Others') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput 
                    placeholder="No" 
                    group="holy_places" 
                    value="No" 
                    defaultChecked={searchData.holy_places.includes('No') ? true : false}
                    onChange={handleChangeCheckBoxMulti} 
                  />
                </div>
                <div className="title">Languages</div>
                <SelectInput
                  label="Language"
                  placeholder="Language"
                  options={[
                    { value: "*", label: "All" },
                    { value: "Hebrew", label: "Hebrew" },
                    { value: "English", label: "English" },
                    { value: "French", label: "French" },
                    { value: "Spanish", label: "Spanish" },
                    { value: "German", label: "German" },
                    { value: "Italian", label: "Italian" },
                    { value: "Russian", label: "Russian" },
                    { value: "Chinese", label: "Chinese" },
                    { value: "Arabic", label: "Arabic" },
                    { value: "Portuguese", label: "Portuguese" },
                    { value: "Japanese", label: "Japanese" },
                  ]}
                  name="language"
                  onChange={(value) => {
                    handleChangeSelect(value, "language");
                  }}
                  defaultValue={searchData.language}
                  selectValue={searchData.language}
                  styles="languageStyles"
                />
                <div className="title">Food and Drink</div>
                <div className="foodanddrink-checkboxs-wrapper">
                  <CheckboxInput
                    placeholder="Food"
                    group="food_and_drink"
                    value="Food"
                    defaultChecked={searchData.food === "Y" ? true : false}
                    onChange={handleChangeCheckBox}
                    name="food"
                  />
                  <CheckboxInput
                    placeholder="Kosher"
                    group="food_and_drink"
                    value="Kosher"
                    defaultChecked={searchData.kosher === "Y" ? true : false}
                    onChange={handleChangeCheckBox}
                    name="kosher"
                  />
                  <CheckboxInput
                    placeholder="Wine and Alcohol"
                    group="food_and_drink"
                    value="Wine and Alcohol"
                    defaultChecked={searchData.wine_and_alcohol === "Y" ? true : false}
                    onChange={handleChangeCheckBox}
                    name="wine_and_alcohol"
                  />
                </div>
                <div className="title">Accessibility</div>
                <div className="accessibility-checkboxs-wrapper">
                  <CheckboxInput
                    placeholder="Accessibility"
                    group="accessibility"
                    value="Accessibility"
                    defaultChecked={searchData.accessibility === "Y" ? true : false}
                    onChange={handleChangeCheckBox}
                    name="accessibility"
                  />
                </div>
                <div className="title">Kids</div>
                <div className="kids-checkboxs-wrapper">
                  <CheckboxInput
                    placeholder="Suitable for Children"
                    group="kids"
                    value="Suitable for Children"
                    defaultChecked={searchData.suitable_for_children === "Y" ? true : false}
                    onChange={handleChangeCheckBox}
                    name="suitable_for_children"
                  />
                  <CheckboxInput
                    placeholder="Activities for Schools"
                    group="kids"
                    value="Activities for Schools"
                    defaultChecked={searchData.activities_for_schools === "Y" ? true : false}
                    onChange={handleChangeCheckBox}
                    name="activities_for_schools"
                  />
                </div>
                <div className="title">Weekend and Holiday</div>
                <div className="weekendandholiday-checkboxs-wrapper">
                  <CheckboxInput
                    placeholder="Active On Fridays"
                    group="weekend_and_holiday"
                    value="Active On Fridays"
                    defaultChecked={searchData.weekend_and_holiday.includes('Active On Fridays') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput
                    placeholder="Active On Saturday"
                    group="weekend_and_holiday"
                    value="Active On Saturday"
                    defaultChecked={searchData.weekend_and_holiday.includes('Active On Saturday') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput
                    placeholder="Active On Sunday"
                    group="weekend_and_holiday"
                    value="Active On Sunday"
                    defaultChecked={searchData.weekend_and_holiday.includes('Active On Sunday') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput
                    placeholder="Active On Holidays"
                    group="weekend_and_holiday"
                    value="Active On Holidays"
                    defaultChecked={searchData.weekend_and_holiday.includes('Active On Holidays') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                </div>

                <div className="title">Free Activities</div>
                <div className="freeactivities-checkboxs-wrapper">
                  <CheckboxInput
                    placeholder="Free (with reservation)"
                    group="free_activities"
                    value="02"
                    defaultChecked={searchData.free_activities.includes('02') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput
                    placeholder="Free (without reservation)"
                    group="free_activities"
                    value="01"
                    defaultChecked={searchData.free_activities.includes('01') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                  <CheckboxInput
                    placeholder="Paid"
                    group="free_activities"
                    value={'00'}
                    defaultChecked={searchData.free_activities.includes('00') ? true : false}
                    onChange={handleChangeCheckBoxMulti}
                  />
                </div>
                <div
                  className="btn"
                  onClick={() => {
                    setOpenFilter(!openFilter);
                    getSites();
                  }}
                >
                  Apply Filters
                </div>
              </div>
            </div>
            <div className="data-wrapper">
              {loading ? (
                <div className="data loading">
                  <Loader />
                </div>
              ) : data.length > 0 ? (
                <>
                  <div className="data">
                    <div className="data-fillters">
                      <div className="btn by-reviews" onClick={filterByReviews}>
                        <img src={require("../assets/images/orderby.png").default} />
                        Reviews
                      </div>
                      <div className="btn by-az" onClick={filterByAZ}>
                        <img src={require("../assets/images/orderby.png").default} />A - Z
                      </div>
                    </div>
                    {data?.map((item) => {
                      return (
                        <FullSite
                          data={item}
                          onClick={() => {
                            setPopup(item);
                            window.scrollTo(0, 0);
                          }}
                        />
                      );
                    })}
                  </div>
                  <div className='bottom-btns'>
                    <div className="pagination">
                      <button className='btn prev' disabled={page===0} onClick={(e) => getSites(e, searchData, page-1)}>
                        <PrevIcon/>
                        <span>Previous Page</span>
                      </button>
                      <button className='btn next' disabled={data.length < 100} onClick={(e) => getSites(e, searchData, page+1)}>
                        <span>Next Page</span>
                        <NextIcon/>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="data loading">
                  {searchMsg.length === 0 ? <h2>Search Results Shown Here</h2> : <h2>{searchMsg}</h2>}
                </div>
              )}
            </div>
          </div>

          <div className="map-wrapper">
            <div
              className="btn"
              onClick={() => {
                setOpenFilter(!openFilter);
              }}
              ref={fillterRefBtn2}
            >
              <i>
                <img src={require("../assets/images/filter.png").default} />
              </i>
              Filters
            </div>

            <div className="map">
              <ReactMapGL
                {...viewport}
                onViewportChange={(nextViewport) => setViewport(nextViewport)}
                mapStyle="mapbox://styles/theguy/ckev6139w0wz21aofyw2gcsa7"
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              >
                <NavigationControl />
                {popup ? <MapPopup data={popup} close={closePopup} /> : null}
                {data.map((marker) => {

                  if(marker.MAPXPOINT !== null && marker.MAPYPOINT !== null){

                    if( 
                      (-90<=parseFloat(marker.MAPXPOINT) && parseFloat(marker.MAPXPOINT) <= 90) &&
                      (-180<=parseFloat(marker.MAPYPOINT) && parseFloat(marker.MAPYPOINT) <= 180) &&
                      (parseFloat(marker.MAPXPOINT) !== NaN) && (parseFloat(marker.MAPYPOINT) !== NaN)
                    )
                      
                      {
                        return(
                          <Marker latitude={parseFloat(marker.MAPXPOINT)} longitude={parseFloat(marker.MAPYPOINT)}>
                            <div>
                              <img
                                src={require("../assets/images/pin.png").default}
                                onClick={() => {
                                  setPopup(marker);
                                }}
                              />
                            </div>
                          </Marker>
                        )
                      }
                  } else {
                    // console.log("notvalid", marker);
                  }
                })}
              </ReactMapGL>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Map;
