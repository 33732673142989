import "./SitePage.scss";
import React, { useContext, useCallback, useEffect, useState, useRef } from "react";
import * as priorityApi from "../shared/priorityApi";
import { AuthContext } from "../context/AuthContext";
import * as api from "../shared/api";
import moment from "moment";
import { SnackBarsContext } from "../context/SnackBarsContext";
import Loader from "../components/Loader";
import Rating from "@material-ui/lab/Rating";
import Slider from "../components/Slider";
import { BrowserRouter as Router, Switch, Route, Link, useParams, useHistory } from "react-router-dom";
import ReactMapGL, { NavigationControl, Marker } from "react-map-gl";
import FullActivity from "../components/FullActivity";

const SitePage = () => {
  const history = useHistory();
  const [site, setSite] = useState(null);
  const { authContextData } = useContext(AuthContext);
  
  
  const { dispatchSnackBar } = useContext(SnackBarsContext);
  const id = useParams().id;
  const [rating, setRating] = useState(0);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState({
    display: false,
    siteID: id,
    name: authContextData?.logedUser.name,
    content: "",
  });
  const [map, setMap] = useState(false);
  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
    latitude: 32.109333,
    longitude: 34.855499,
    zoom: 7,
    padding: 20,
    offset: [0, -100],
    mapboxApiAccessToken: process.env.REACT_APP_MAPBOX_TOKEN,
  });
  const [report, setReport] = useState({
    display: false,
    name: authContextData?.logedUser.name,
    email: authContextData?.logedUser.email,
    message: "",
  });
  const [slider, setSlider] = useState([]);
  const [brochure, setBrochure] = useState([]);
  const [showSlider, setShowSlider] = useState(false);
  const [loading, setLoding] = useState(false);
  const [dropDownAgent, setDropDownAgent] = useState(false);
  const dropDownRefAgent = useRef(null);
  const [dropDownTourist, setDropDownTourist] = useState(false);
  const dropDownRefTourist = useRef(null);
  const [googleHref, setGoogleHref] = useState("");

  let img = require("../assets/images/img-ph.png").default;

  if (site?.ZITA_WEBATTREXTFILE_SUBFORM && site?.ZITA_WEBATTREXTFILE_SUBFORM.length > 0) {
    const files = site?.ZITA_WEBATTREXTFILE_SUBFORM;
    const file_position = files.findIndex(file => file.MAIN === 1);

    if(file_position > -1){
      img = files[file_position].EXTFILENAME;
    }else{
      img = files[0].EXTFILENAME;
    }
  }

  const closeSlider = () => {
    setShowSlider(false);
  };

  const onChangeRating = async (event) => {
    setLoding(true);
    const res = await api.setRating({
      user: authContextData?.logedUser.email,
      attraction_id: site.ATTRCODE,
      rating: event.target.value,
    });
    if (res.data.success) {
      getRating(site.ATTRCODE);
      dispatchSnackBar({
        type: "SET_SUCCESS",
        payload: "Thank you for rating !",
      });
      setLoding(false);
    } else {
      dispatchSnackBar({
        type: "SET_ERROR",
        payload: "Something went wrong please contact support",
      });
      setLoding(false);
    }
  };

  const getRating = useCallback(async (id) => {
    const res = await api.getRating(id);
    if (res.data[0].rating) {
      setRating(res.data[0].rating);
    }
  });

  const getComments = useCallback(async (id) => {
    const res = await api.getComments(id);
    setComments(res.data);
  });

  const getSite = useCallback(async () => {
    const res = await priorityApi.getSite(parseInt(id));
    if (res.data.value[0]) {
      getRating(res.data.value[0].ATTRCODE);
      getComments(res.data.value[0].ATTRCODE);
      setSite(res.data.value[0]);

      var d = new Date();
      d.setMonth(d.getMonth() - 3);
      let time = d.toLocaleDateString();

      setGoogleHref(`https://www.google.com/search?q=${res.data.value[0].ATTRDES}&tbs=cdr:1,cd_min:${time}`);
      setBrochure(
        res.data.value[0].ZITA_WEBATTREXTFILE_SUBFORM.filter((brochure) => {
          return brochure.BROCHURE === "Y";
        })
      );
      setSlider(
        res.data.value[0].ZITA_WEBATTREXTFILE_SUBFORM.filter((image) => {
          return image.PICTURE === "Y";
        })
      );
    }
  });

  const setNewComment = useCallback(async () => {
    setLoding(true);
    const res = await api.setComment(comment);
    if (res.data.success) {
      setComment({ ...comment, display: false, content: "" });
      dispatchSnackBar({
        type: "SET_SUCCESS",
        payload: "Comment Created Successfully!",
      });
      setComments([...comments, comment]);
      setLoding(false);
    } else {
      setComment({ ...comment, display: false, content: "" });
      setLoding(false);
      return dispatchSnackBar({
        type: "SET_ERROR",
        payload: "Something went wrong please contact support",
      });
      
    }
  });
  const sendReport = useCallback(async () => {
    setLoding(true);
    const res = await api.report(report);
    if (res.data.success) {
      setReport({ ...report, display: false, content: "" });
      dispatchSnackBar({
        type: "SET_SUCCESS",
        payload: "Report Send Successfully!",
      });
      setLoding(false);
    } else {
      setReport({ ...report, display: false, content: "" });
      setLoding(false);
      return dispatchSnackBar({
        type: "SET_ERROR",
        payload: "Something went wrong please contact support",
      });
    }
  });
  useEffect(() => {
    getSite();
    document.addEventListener("click", (e) => {
      if (e.target !== dropDownRefAgent.current) {
        setDropDownAgent(false);
      }
      if (e.target !== dropDownRefTourist.current) {
        setDropDownTourist(false);
      }
    });
  }, []);

  return (
    <div className="SitePage">
      {comment.display ? (
        <div className="comments-popup">
          <div className="popup-container">
            <form>
              <h2 className="title">New Comment</h2>
              <textarea
                value={comment.content}
                name="content"
                onChange={(e) => {
                  setComment({ ...comment, content: e.target.value });
                }}
              ></textarea>
              {loading ? (
                <div className="btns">
                  <Loader />
                </div>
              ) : (
                <div className="btns">
                  <div className="btn orange" onClick={setNewComment}>
                    Post
                  </div>
                  <div
                    className="btn red"
                    onClick={() => {
                      setComment({ ...comment, display: false, content: "" });
                    }}
                  >
                    Discard
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      ) : null}
      {report.display ? (
        <div className="report-popup">
          <div className="popup-container">
            <form>
              <h2 className="title">Report</h2>
              <textarea
                value={report.message}
                name="report"
                onChange={(e) => {
                  setReport({ ...report, message: e.target.value });
                }}
              ></textarea>
              {loading ? (
                <div className="btns">
                  <Loader />
                </div>
              ) : (
                <div className="btns">
                  <div className="btn orange" onClick={sendReport}>
                    Report
                  </div>
                  <div
                    className="btn red"
                    onClick={() => {
                      setReport({ ...report, display: false, content: "" });
                    }}
                  >
                    Discard
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      ) : null}
      {map ? (
        <div className="map-popup">
          <div className="popup-container">
            <div
              className="close"
              onClick={() => {
                setMap(false);
              }}
            >
              x
            </div>
            <div className="map">
              <ReactMapGL
                {...viewport}
                onViewportChange={(nextViewport) => setViewport(nextViewport)}
                mapStyle="mapbox://styles/theguy/ckev6139w0wz21aofyw2gcsa7"
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              >
                <NavigationControl />
                <>
                  <Marker
                    latitude={parseFloat(site ? site?.MAPXPOINT : 0)}
                    longitude={parseFloat(site ? site?.MAPYPOINT : 0)}
                  >
                    <div>
                      <img src={require("../assets/images/pin.png").default} alt="" />
                    </div>
                  </Marker>
                </>
              </ReactMapGL>
            </div>
          </div>
        </div>
      ) : null}
      {showSlider ? <Slider images={slider} close={closeSlider} /> : null}
      <div className="container">
        <div className="top-bar">
          <div
            className="btn btn-blue"
            onClick={() => {
              history.goBack();
            }}
          >
            <img src={require("../assets/images/back-arrow-icon.png").default} alt="" />
            Back
          </div>
          <div
            className="btn btn-orange"
            onClick={() => {
              setReport({ ...report, display: true });
            }}
          >
            <img src={require("../assets/images/report.png").default} alt="" />
            Report
          </div>
          <div className="btn btn-gray">
            Address: {site?.ADDRESS && site?.ADDRESS}, {site?.MAPXPOINT} {site?.MAPYPOINT}
          </div>
        </div>
        <div className="site-content">
          <div className="details">
            <div
              className="images"
              onClick={() => {
                setShowSlider(true);
              }}
            >
              <div className="gallery-icon">
                <img src={require("../assets/images/gallery.png").default} alt="" />
              </div>
              <img src={img} alt="" />
            </div>
            <div className="name-description">
              <h1 className="title">{site?.ATTRDES}</h1>
              <p className="text" dangerouslySetInnerHTML={{ __html: site?.ZITA_WATTRACTIONSTXT_SUBFORM?.TEXT }}></p>
            </div>
            <div className="social">
              <div className="rating">
                <Rating name="read-only" value={rating} readOnly />
              </div>
              <div className="comments">
                {comments.length}
                <img src={require("../assets/images/comments.png").default} alt="" />
              </div>
            </div>
          </div>
          <div className="info">
            <div className="info-items-warrper">
              {site?.POSTADDRESS ? (
                <div className="info-item">
                  <img src={require("../assets/images/postal-icon.png").default} alt="" /> {site?.POSTADDRESS}
                </div>
              ) : null}
              {site?.PHONENUM ? (
                <div className="info-item">
                  <img src={require("../assets/images/phone-icon.png").default} alt="" />
                  <a href={"tel:" + site?.PHONENUM}>{site?.PHONENUM}</a>
                </div>
              ) : null}
              {site?.FAX ? (
                <div className="info-item">
                  <img src={require("../assets/images/fax-icon.png").default} alt="" />
                  {site?.FAX}
                </div>
              ) : null}
              {site?.EMAIL && site?.EMAIL !== "noemail@gmail.com" ? (
                <div className="info-item">
                  <img src={require("../assets/images/email-icon.png").default} alt="" />
                  <a href={"mailto:" + site?.EMAIL}>{site?.EMAIL}</a>
                </div>
              ) : null}
              {site?.WEBSITE ? (
                <div className="info-item">
                  <img src={require("../assets/images/site-icon.png").default} alt="" />
                  <a href={site?.WEBSITE} target="_blank" rel="noreferrer">
                    {site?.WEBSITE}
                  </a>
                </div>
              ) : null}
            </div>
          </div>
          <div className="contact">
            <div className="person">
              {site?.PHONENAME1 ? (
                <div className="info-item">
                  <img src={require("../assets/images/person-icon.png").default} alt="" />
                  {site?.PHONENAME1 + ", " + site?.PHONEDES1}
                </div>
              ) : null}
              {site?.PHONENUM1 ? (
                <div className="info-item">
                  <img src={require("../assets/images/site-icon.png").default} alt="" />{" "}
                  <a href={"tel:" + site?.PHONENUM1}>{site?.PHONENUM1}</a>
                </div>
              ) : null}
              {site?.PHONEMAIL1 ? (
                <div className="info-item">
                  <img src={require("../assets/images/email-icon.png").default} alt="" />
                  <a href={"mailto:" + site?.PHONEMAIL1}>{site?.PHONEMAIL1}</a>
                </div>
              ) : null}
            </div>
            {site?.PHONENAME2 ? (
              <div className="person">
                <hr />
                <div className="info-item">
                  <img src={require("../assets/images/person-icon.png").default} alt="" />
                  {site?.PHONENAME2 + ", " + site?.PHONEDES2}
                </div>
                <div className="info-item">
                  <img src={require("../assets/images/cell-icon.png").default} alt="" />{" "}
                  <a href={"tel:" + site?.PHONENUM2}>{site?.PHONENUM2}</a>
                </div>
                <div className="info-item">
                  <img src={require("../assets/images/email-icon.png").default} alt="" />{" "}
                  <a href={"mailto:" + site?.PHONEMAIL2}>{site?.PHONEMAIL2}</a>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="site-bar">
          <div className="validity">
            <label>Validity:</label>
            <span>
              {moment(site?.PLDATE).format("DD.MM.YYYY")} - {moment(site?.ZITA_PLTILLDATE).format("DD.MM.YYYY")}
            </span>
          </div>
          <div className="btns">
            <div className="btn-wrapper">
              <a className="btn orange" target="_blanck" href={googleHref}>
                Search in Google
              </a>
            </div>
            <div className="btn-wrapper">
              <div
                className="btn green"
                onClick={() => {
                  setMap(true);
                }}
              >
                Map
              </div>
            </div>
            <div className="btn-wrapper">
              <div
                className="btn yello"
                ref={dropDownRefAgent}
                onClick={(e) => {
                  setDropDownAgent(!dropDownAgent);
                }}
              >
                Agent`s Brochure
              </div>
              <ul className="drop-down yello" style={dropDownAgent ? { display: "block" } : null}>
                {brochure.map((item) => {
                  if (JSON.parse(item.TYPE).for === "Agent") {
                    return (
                      <li>
                        <a target="_black" href={"https://infocenter.israeltravel.co.il/uploads/" + item.EXTFILENAME}>
                          {JSON.parse(item.TYPE).language}
                        </a>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
            <div className="btn-wrapper">
              <div
                className="btn turquoise"
                ref={dropDownRefTourist}
                onClick={(e) => {
                  setDropDownTourist(!dropDownTourist);
                }}
              >
                Tourist`s Brochure
              </div>
              <ul className="drop-down turquoise" style={dropDownTourist ? { display: "block" } : null}>
                {brochure.map((item) => {
                  if (JSON.parse(item.TYPE).for === "Tourist") {
                    return (
                      <li>
                        <a target="_black" href={"https://infocenter.israeltravel.co.il/uploads/" + item.EXTFILENAME}>
                          {JSON.parse(item.TYPE).language}
                        </a>
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="activities">
          {site?.ZITA_WEBACT_SUBFORM?.map((activity) => {
            return <FullActivity data={activity} siteName={site.ATTRDES} owner={site.EMAIL} 
              //new katy 2023 
              all_attraction_data = {site}
            />;
          })}
        </div>
        <div className="comments">
          <div className="container">
            <div className="title">Comments and Reviews</div>
            <div className="bar">
              <div
                className="add-comment"
                onClick={() => {
                  setComment({ ...comment, display: true });
                }}
              >
                <img src={require("../assets/images/plus.png").default} alt="" />
                Add Comment
              </div>
              <div className="add-rating">
                {loading ? <Loader /> : <Rating name="read-only" value={rating} onChange={onChangeRating} />}
              </div>
            </div>
            <div className="comments">
              {comments
                .map((comment) => {
                  return (
                    <div className="comment">
                      <span className="name">{comment.name}</span>
                      <span className="date">{moment(comment.date).format("MMM D, yyyy")}</span>
                      <p className="content">{comment.content}</p>
                    </div>
                  );
                })
                .reverse()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SitePage;
