import "./Slider.scss";
import React, { useEffect, useState } from "react";

const Slider = (props) => {
  const [images, setImages] = useState([]);
  const [sliderIndex, SetSliderIndex] = useState(0);
  const next = () => {
    if (sliderIndex + 1 > images.length - 1) {
      SetSliderIndex(0);
    } else {
      SetSliderIndex(sliderIndex + 1);
    }
  };
  const prev = () => {
    if (sliderIndex - 1 < 0) {
      SetSliderIndex(images.length - 1);
    } else {
      SetSliderIndex(sliderIndex - 1);
    }
  };

  useEffect(() => {
    setImages(props.images.map((image) => image.EXTFILENAME));
  }, []);

  return (
    <div className="Slider">
      <div className="container">
        <div className="close" onClick={props.close}>
          x
        </div>
        <div className="images-wrapper">
          <div className="nav nav-prev">
            <img src={require("../assets/images/black-arrow-down.png").default} onClick={prev} />
          </div>
          <div className="slider-content">
            {images.map((img, index) => {
              return <img src={img} className={sliderIndex === index ? "active" : null} />;
            })}
          </div>
          <div className="nav nav-next">
            <img src={require("../assets/images/black-arrow-down.png").default} onClick={next} />
          </div>
        </div>
        <div className="nvigation">
          <div className="img-wrapper">
            <img
              src={
                images[
                  sliderIndex - 2 < 0 ? (sliderIndex - 1 < 0 ? images.length - 2 : images.length - 1) : sliderIndex - 2
                ]
              }
            />
          </div>
          <div className="img-wrapper">
            <img src={images[sliderIndex - 1 < 0 ? images.length - 1 : sliderIndex - 1]} />
          </div>
          <div className="img-wrapper">
            {//console.log(images)
            }
            <img src={images[sliderIndex]} />
          </div>
          <div className="img-wrapper">
            <img src={images[sliderIndex + 1 > images.length - 1 ? 0 : sliderIndex + 1]} />
          </div>
          <div className="img-wrapper">
            <img
              src={
                images[
                  sliderIndex + 2 > images.length - 1 ? (sliderIndex + 1 > images.length - 1 ? 1 : 0) : sliderIndex + 2
                ]
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
