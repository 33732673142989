import "./Preloader.scss";
import React from "react";

const Preloader = () => {
  return (
    <div className="Preloader">
      <img src={require("../assets/images/logo.png").default} alt="" />
      <div className="text">
        <h1>Loading, Please wait</h1>
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
    </div>
  );
};

export default Preloader;
