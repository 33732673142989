import "./Main.scss";
import React, { useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { LoginTypeContext } from "../context/LoginTypeContext";
import { AuthContext } from "../context/AuthContext";
import News from "../components/news";

const Main = () => {
  const { loginTypeContextDispatch } = useContext(LoginTypeContext);
  const { authContextData } = useContext(AuthContext);
  const history = useHistory();
  useEffect(() => {
    if (authContextData.isAuth) {
      switch (authContextData.logedUser.type) {
        case 1:
          loginTypeContextDispatch({ type: "SYSTEM" });
          history.push("/login");
          break;
        case 2:
          loginTypeContextDispatch({ type: "SITE" });
          history.push("/login");
          break;
        case 3:
          loginTypeContextDispatch({ type: "USER" });
          history.push("/login");
          break;
      }
    }
  }, []);
  return (
    <div className="Main">
      <div className="top">
        <div className="content">
          <h1 className="title">Information Center</h1>
          <h2 className="sub-title">Israel Incoming Tour Operators Assoc.</h2>
          <Link
            className="btn btn-blue"
            to="/login"
            onClick={() => {
              loginTypeContextDispatch({ type: "SYSTEM" });
            }}
          >
            Agents login
          </Link>
          <Link
            className="btn btn-orange"
            to="/login"
            onClick={() => {
              loginTypeContextDispatch({ type: "SITE" });
            }}
          >
            Tourism site managers login
          </Link>
          <Link
            className="btn btn-gray"
            to="/login"
            onClick={() => {
              loginTypeContextDispatch({ type: "USER" });
            }}
          >
            System admin login
          </Link>
          <a className="to-news" href="#news">
            <img src={require("../assets/images/news-icon.png").default} />
            <h3>News</h3>
            <img src={require("../assets/images/arrow-down.png").default} />
          </a>
        </div>
      </div>
      <News />
    </div>
  );
};

export default Main;
