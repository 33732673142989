import "./News.scss";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import * as api from '../shared/api';

const News = () => {

  const [news, setNews] = useState([]);

  const getNews = useCallback(async () => {
    const res = await api.getNews();
    if (res.data) {
      setNews(res.data)
    }
  });

  useEffect(() => {
    getNews();
  }, [])

  return (
    <div id="news" className="News">
      <img src={require("../assets/images/news.png").default} />
      <div className="container">
        {news?.map((item) => {
          return (
            <div className="new-wrapper">
              <div className="new">
                <div className="data">{moment(item.date).format("MM/DD/YYYY")}</div>
                <div className="content">
                  <h2 className="title">{item.title.rendered}</h2>
                  <div className="data">{moment(item.date).format("MM/DD/YYYY")}</div>
                  <div className="text" dangerouslySetInnerHTML={{ __html: item.content.rendered }}></div>
                </div>
              </div>
            </div>
          );
        })}

      </div>
    </div>
  );
};

export default News;
