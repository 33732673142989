import "./PdfInput.scss";
import React, { useState, useCallback, useContext, useEffect } from "react";
import * as api from "../../shared/api";
import FloatingSelect from "../inputs/FloatingSelect";
import * as validate from "../../utils/validation";
import { SnackBarsContext } from "../../context/SnackBarsContext";
import Loader from "../Loader";

const url = "https://infocenter.israeltravel.co.il/uploads/";

const PdfInput = (props) => {
  const { dispatchSnackBar } = useContext(SnackBarsContext);
  const [pdfs, setPdfs] = useState([]);

  useEffect(()=>{
    const hasRelaventInfo = props.value.some(file => file.moreData.type === "pdf");
    if(hasRelaventInfo){
      setPdfs(
        props.value.filter((file) => file.moreData.type === "pdf")
      )
    }else{
      setPdfs([])
    }

  },[props.value]);

  const [moreData, setMoreData] = useState({ type: "pdf", for: "", language: "" });
  const [morePopup, setMorePopup] = useState(false);
  const [loading, setLoding] = useState(false);

  const blurValidation = (name, type, value, roles, formStep) => {
    return validate[type](value, roles);
  };

  const handleChangePds = (e) => {
    setMoreData({ ...moreData, file: e.target.files[0] });
    setMorePopup(true);
  };

  const upload = useCallback(async (e) => {
    setLoding(true);
    if (moreData.file.size / 1024 / 1024 > 5) {
      setLoding(false);
      e.target.value = null;
      return dispatchSnackBar({
        type: "SET_ERROR",
        payload: "File is too big, Upload up to 5 MB",
      });
    }
    const allowedFormats = ["pdf", "ppt", "PDF"];
    let fileFormat = moreData.file.name.split(".");
    fileFormat = fileFormat[fileFormat.length - 1];
    if (!allowedFormats.includes(fileFormat)) {
      setLoding(false);
      e.target.value = null;
      return dispatchSnackBar({
        type: "SET_ERROR",
        payload: "File format invalid, Upload only pdf.",
      });
    }

    setPdfs([
      ...pdfs,
      {
        src: "",
        uploading: true,
      },
    ]);
    const res = await api.uploadFile(moreData.file);
    if (res.data.success) {
      setPdfs([
        ...pdfs,
        {
          src : url + res.data.file,
          uploading: false,
          moreData,
        },
      ]);
      props.add([
        ...pdfs,
        {
          src : url + res.data.file,
          uploading: false,
          moreData,
        },
      ]);
      setMorePopup(false);
      setMoreData({ type: "pdf", for: "", language: "" });
    }
    setLoding(false);
  });

  const handleChangeSelect = (value, name) => {
    setMoreData({
      ...moreData,
      [name]: value ? value.value : null,
    });
  };

  const deleteFile = useCallback(async (file) => {
    let name = file.split("/");
    const res = await api.deleteFile(name[name.length - 1]);
    if (res.data.success) {
      handleRemoveItem(file);
    }
  });

  const handleRemoveItem = (file) => {
    setPdfs(
      pdfs.filter((pdf) => {
        return pdf.src !== file;
      })
    );
    props.remove(file);
  };

  // console.log(pdfs);
  return (
    <div className="PdfInput">
      {morePopup ? (
        <div className="more-data">
          <div className="container">
            <div className="title">Brochures Details</div>
            <form>
              <FloatingSelect
                color="#93c840"
                onChange={(value) => {
                  handleChangeSelect(value, "for");
                }}
                label="Type *"
                placeholder=""
                options={[
                  { value: "Tourist", label: "Tourist" },
                  { value: "Agent", label: "Agent" },
                ]}
                selectValue={moreData.for}
                validation={blurValidation}
                validationType="yesno"
                validationRoles={{ name: "Type", require: true }}
              />
              <FloatingSelect
                color="#93c840"
                onChange={(value) => {
                  handleChangeSelect(value, "language");
                }}
                label="Language *"
                placeholder=""
                options={[
                  { value: "Hebrew", label: "Hebrew" },
                  { value: "English", label: "English" },
                  { value: "French", label: "French" },
                  { value: "Spanish", label: "Spanish" },
                  { value: "German", label: "German" },
                  { value: "Polish", label: "Polish" },
                  { value: "Italian", label: "Italian" },
                  { value: "Russian", label: "Russian" },
                  { value: "Chinese", label: "Chinese" },
                  { value: "Arabic", label: "Arabic" },
                  { value: "Portuguese", label: "Portuguese" },
                  { value: "Japanese", label: "Japanese" },
                ]}
                selectValue={moreData.language}
                validation={blurValidation}
                validationType="yesno"
                validationRoles={{ name: "Language", require: true }}
              />
              {loading ? (
                <div className="btn-wrapper">
                  <Loader />
                </div>
              ) : (
                <div className="btn-wrapper">
                  <div
                    className="btn red"
                    onClick={() => {
                      setMorePopup(false);
                    }}
                  >
                    Discard
                  </div>
                  <div className="btn green" onClick={upload}>
                    Upload
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      ) : null}
      <div className="preview-wrapper">
        {pdfs.map((pdf) => {
          return (
            <div className={pdf.uploading ? "preview-image" : "preview-image loaded"}>
              <div className="options">
                <img
                  onClick={() => {
                    deleteFile(pdf.src);
                  }}
                  src={require("../../assets/images/white-delete.png").default}
                  alt=""
                />
                <a href={"uploads/" + pdf.src} target="_blanck">
                  <img src={require("../../assets/images/white-eye.png").default} alt="" />
                </a>
              </div>
              <div className="pdf">
                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="36" viewBox="0 0 27 36" fill="red">
                  <path
                    id="Icon_awesome-file-pdf"
                    data-name="Icon awesome-file-pdf"
                    d="M12.79,18.007c-.352-1.125-.345-3.3-.141-3.3C13.24,14.709,13.184,17.3,12.79,18.007Zm-.12,3.319a32.444,32.444,0,0,1-2,4.409,25.891,25.891,0,0,1,4.423-1.54A9.108,9.108,0,0,1,12.67,21.326ZM6.054,30.1c0,.056.928-.38,2.454-2.827A9.718,9.718,0,0,0,6.054,30.1ZM17.438,11.25H27V34.313A1.683,1.683,0,0,1,25.313,36H1.688A1.683,1.683,0,0,1,0,34.313V1.688A1.683,1.683,0,0,1,1.688,0H15.75V9.563A1.692,1.692,0,0,0,17.438,11.25Zm-.562,12.08a7.057,7.057,0,0,1-3-3.783c.316-1.3.816-3.277.436-4.514a1.761,1.761,0,0,0-3.361-.478c-.352,1.287-.028,3.1.57,5.414A66.025,66.025,0,0,1,8.648,26c-.007,0-.007.007-.014.007-1.905.977-5.175,3.129-3.832,4.781a2.185,2.185,0,0,0,1.512.7c1.259,0,2.51-1.266,4.3-4.345a40.079,40.079,0,0,1,5.555-1.631,10.656,10.656,0,0,0,4.5,1.371,1.82,1.82,0,0,0,1.385-3.052c-.977-.956-3.818-.682-5.175-.506ZM26.508,7.383,19.617.492A1.686,1.686,0,0,0,18.422,0H18V9h9V8.571A1.682,1.682,0,0,0,26.508,7.383ZM21.3,25.334c.288-.19-.176-.837-3.009-.633C20.9,25.812,21.3,25.334,21.3,25.334Z"
                  />
                </svg>
                <br />
                <label>for </label>
                {pdf.moreData?.for}
                <br />
                {pdf.moreData?.language}
              </div>
            </div>
          );
        })}
      </div>
      <div className="input-wrapper">
        <label htmlFor="pdf-input">Upload Brochures</label>
        <input id="pdf-input" type="file" onChange={handleChangePds} />
      </div>
    </div>
  );
};

export default PdfInput;
