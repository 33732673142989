import "./SiteForm.scss";
import React, { useState, useContext, useCallback } from "react";
import { AuthContext } from "../../context/AuthContext";
import { SnackBarsContext } from "../../context/SnackBarsContext";
import * as validate from "../../utils/validation";

//Inputs
import FloatingSelect from "../inputs/FloatingSelect";
import FloatingInput from "../inputs/FloatingInput";
import FloatingSelectMulti from "../inputs/FloatingSelectMulti";
import FloatingDateInput from "../inputs/FloatingDateInput";
import FloatingTextarea from "../inputs/FloatingTextarea";
import ImageInput from "../inputs/ImageInput";
import PdfInput from "../inputs/PdfInput";

import InteractiveMap, { Marker, WebMercatorViewport, NavigationControl } from "react-map-gl";

import * as api from "../../shared/api";
import * as priorityApi from "../../shared/priorityApi";
import * as priorityServices from "../../utils/priorityServices";

import Loader from "../Loader";
import MainImageInput from "../inputs/MainImageInput";

const SiteForm = (props) => {
  const { dispatchSnackBar } = useContext(SnackBarsContext);
  const [stepper, setStepper] = useState({
    current: 1,
    steps: [
      {
        num: 1,
        status: "uncompleted",
        title: "Details",
        inputs: {
          site_name: false,
          site_area: false,
          site_city: false,
          site_street_number: true,
          site_postal_address: true,
          site_phone: true,
          site_fax: true,
          site_mail: false,
          site_site: true,
          site_from_date: true,
          site_to_date: true,
          site_description: false,
          site_company_id: false
        },
      },
      {
        num: 2,
        status: "uncompleted",
        title: "Location",
        inputs: {
          site_lat: false,
          site_lon: false,
        },
      },
      {
        num: 3,
        status: "uncompleted",
        title: "Classification",
        inputs: {
          site_type: false,
          site_holy_place: false,
          site_food: false,
          site_alcohol: false,
          site_holidays: false,
          site_accessibility: false,
          site_kids: false,
          site_school: false,
          site_languages: false,
          site_kosher: false,
        },
      },
      {
        num: 4,
        status: "uncompleted",
        title: "Contacts",
        inputs: {
          person_first_name: true,
          person_first_phone: true,
          person_first_role: true,
          person_first_email: true,
          person_second_name: true,
          person_second_phone: true,
          person_second_role: true,
          person_second_email: true,
        },
      },
      { num: 5, status: "uncompleted", title: "Media" },
    ],
  });
  const { authContextData } = useContext(AuthContext);
  
  const [site, setSite] = useState({
    site_name: "",
    site_description: "",
    site_area: [],
    site_street_number: "",
    site_city: "",
    site_postal_address: "",
    site_files: [],
    site_main_img: [],
    site_lat: "",
    site_lon: "",
    site_phone: "",
    site_fax: "",
    site_mail: "",
    site_site: "",
    site_holidays: [],
    person_first_name: "",
    person_first_phone: "",
    person_first_role: "",
    person_first_email: "",
    person_second_name: "",
    person_second_phone: "",
    person_second_role: "",
    person_second_email: "",
    site_type: [],
    site_holy_place: [],
    site_kids: "",
    site_food: "",
    site_alcohol: "",
    site_accessibility: "",
    site_school: "",
    site_languages: [],
    site_from_date: "",
    site_to_date: "",
    site_kosher: '',
    site_company_id: '',
    site_provider: authContextData.logedUser.provider,
  });
  const [loading, setLoding] = useState(false);
  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
    latitude: 32.109333,
    longitude: 34.855499,
    zoom: 7,
    padding: 20,
    offset: [0, -100],
    mapboxApiAccessToken: process.env.REACT_APP_MAPBOX_TOKEN,
  });
  const [location, setLocation] = useState(false);
  const [addressSearch, setAddressSearch] = useState("");
  const [addressMarkers, setAddressMarkers] = useState([]);
  const [discard, setDiscard] = useState(false);
  const [noProvider, setNoProvider] = useState({
    popup: false,
  });
  const blurValidation = (name, type, value, roles, formStep) => {
    let inputs = { ...stepper.steps[formStep - 1].inputs };
    if (validate[type](value, roles).valid) {
      inputs[name] = true;
    } else {
      inputs[name] = false;
    }
    let newStemps = [...stepper.steps];
    newStemps[formStep - 1].inputs = inputs;
    setStepper({ ...stepper, steps: newStemps });
    return validate[type](value, roles);
  };

  const handleChange = (e) => {
    setSite({
      ...site,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangePhone = (e) => {
    if (e.target.value.length === 2 && e.nativeEvent.data && e.target.value !== "05" && e.target.value !== "07") {
      e.target.value = e.target.value + "-";
    } else {
      if (e.target.value.length === 3 && e.nativeEvent.data) {
        e.target.value = e.target.value + "-";
      }
    }
    setSite({
      ...site,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeSelectMulti = (value, name) => {
    setSite({
      ...site,
      [name]: value ? value.map((x) => x.value) : [],
    });
  };

  const handleChangeTime = (name, e) => {
    setSite({
      ...site,
      [name]: e,
    });
  };

  const handleChangeSelect = (value, name) => {
    setSite({
      ...site,
      [name]: value ? value.value : null,
    });
  };
  
  const handleChangeSelectFiles = (data) => {
    const prevSite = {...site};
    prevSite.site_files = [
      ...prevSite.site_files, // Existing files
      ...(Array.isArray(data) ? data : [data]) // Concatenate data if it's an array, or wrap data in an array if it's an object
    ];

    setSite(prevSite);
  };

  const handleChangeMainImage = (data) => {
    const prevSite = {...site};
    prevSite.site_main_img = data;
    setSite(prevSite);
  };

  const handleChangeRemoveFiles = (data) => {
    setSite({
      ...site,
      site_files: site.site_files.filter((file) => {
        return file.src !== data;
      }),
    });
  };

  const nextStep = (formnum) => {
    let inputs = { ...stepper.steps[formnum - 1].inputs };

    for (var name in inputs) {
      if (!inputs[name]) {
        return dispatchSnackBar({
          type: "SET_ERROR",
          payload: "Please complete all fields.",
        });
      }
    }

    // if (
    //   !inputs["site_name"] ||
    //   !inputs["site_area"] ||
    //   !inputs["site_city"] ||
    //   !inputs["site_street_number"] ||
    //   !inputs["site_mail"]
    // ) {
    //   return dispatchSnackBar({
    //     type: "SET_ERROR",
    //     payload: "Please complete all required fields.",
    //   });
    // }

    let steps = [...stepper.steps];
    steps[formnum - 1].status = "completed";
    setStepper({ ...stepper, current: formnum + 1, steps: steps });
  };

  const prevStep = (formnum) => {
    let steps = [...stepper.steps];
    steps[formnum - 1].status = "uncompleted";
    steps[formnum - 2].status = "uncompleted";
    setStepper({ ...stepper, current: formnum - 1, steps: steps });
  };

  const handleClick = ({ lngLat: [longitude, latitude] }) => {
    setAddressMarkers([[latitude, longitude]]);
  };

  const handleChangeAddressSearch = (e) => {
    setAddressSearch(e.target.value);
  };

  const searchAddress = useCallback(async (address) => {
    const res = await api.getGeo(addressSearch);
    if (res.data.length > 0) {
      setAddressMarkers(res.data.map((marker) => [parseFloat(marker.lat), parseFloat(marker.lon)]));
      let latArry = res.data.map((marker) => parseFloat(marker.lat));
      let lonArry = res.data.map((marker) => parseFloat(marker.lon));
      const map = new WebMercatorViewport(viewport).fitBounds(
        [
          [Math.min(...lonArry), Math.min(...latArry)],
          [Math.max(...lonArry), Math.max(...latArry)],
        ],
        {
          padding: 20,
          offset: [0, -100],
        }
      );
      setViewport(map);
    }
    setLoding(false);
  });

  const createSite = useCallback(async (noProvider) => {
    if (site.site_provider === "" && authContextData.logedUser.type === 3 && !noProvider) {
      return setNoProvider({ ...noProvider, popup: true });
    }
    setLoding(true);
    const res = await priorityApi.createSite(priorityServices.createSiteObj(site));
    // console.log(res);
    if (res.data) {
      //forMainIMG:
      if(site.site_main_img.length){
        await priorityApi.addFileToSite(
          priorityServices.createFileObj(res.data.INT2, site.site_main_img[0]),
        );
      }

      if(site.site_files.length){
        for (const file of site.site_files) { 
          await priorityApi.addFileToSite(
            priorityServices.createFileObj(res.data.INT2, file),
          );
        }
      }

      const createdSite = await priorityApi.getSite(parseInt(res.data.INT2));

      setLoding(false);
      
      dispatchSnackBar({
        type: "SET_SUCCESS",
        payload: "Tourism Site Created Successfully!",
      });

      if(createdSite && createdSite?.data?.value.length){
        props.addNewData(createdSite.data.value[0]);
      }
      
      props.discard();
      try {
        await api.createLog(site);
      } catch (err) {
        console.log(err);
      }
    }
  });

  // console.log(site)

  return (
    <div className="SiteForm">
      {discard ? (
        <div className="popup">
          <div className="content">
            <h2>Are you sure?</h2>
            <p>The information entered will not be saved!</p>
            <div
              className="btn red"
              onClick={() => {
                setDiscard(false);
              }}
            >
              No
            </div>
            <div className="btn green" onClick={props.discard}>
              Yes
            </div>
          </div>
        </div>
      ) : null}
      {noProvider.popup ? (
        <div className="popup provider">
          <div className="content">
            <h2>Are you sure?</h2>
            <p>
              As an admin you don't have a tourism site supplier number, Creating a tourism site without the supplier
              number will not show the data on the dashboard, You can now enter a tourism site supplier number or
              continuing without and add the code later on the Priority panel.
            </p>
            <input
              type="text"
              placeholder="Supplier Number"
              onChange={handleChange}
              name="site_provider"
              value={site.site_provider}
            />
            <br />
            <div
              className="btn red"
              onClick={() => {
                setNoProvider({ ...noProvider, popup: false });
              }}
            >
              Discard All
            </div>
            <div
              className="btn orange"
              onClick={() => {
                setNoProvider({ popup: false });
                createSite(true);
              }}
            >
              Create Without
            </div>
            <div
              className="btn green"
              onClick={() => {
                setNoProvider({ ...noProvider, popup: false });
                createSite();
              }}
            >
              Create
            </div>
          </div>
        </div>
      ) : null}
      <div className="container">
        <div className="content">
          <h1 className="title">New Tourism Site</h1>
          <div className="stepper">
            {stepper?.steps.map((step) => {
              return (
                <div className="step">
                  <div
                    className={
                      stepper.current === step.num
                        ? "num current"
                        : step.status === "completed"
                        ? "num completed"
                        : "num"
                    }
                  >
                    {step.status === "completed" ? "✔" : step.num}
                  </div>
                  <div className="text">{step.title}</div>
                </div>
              );
            })}
          </div>
          <p className="">*All data must be entered in english.</p>
          {stepper?.current === 1 ? (
            <form className="step-1">
              <div className="col">
                <FloatingInput
                  color="orange"
                  label="Tourism Site Name *"
                  value={site.site_name}
                  onChange={handleChange}
                  name="site_name"
                  validation={blurValidation}
                  validationType="text"
                  validationRoles={{ name: "Tourism Site Name", require: false }}
                  formStep="1"
                />
                <FloatingSelectMulti
                  color="#93c840"
                  label="Area *"
                  selectValue={site.site_area}
                  onChange={(value) => {
                    handleChangeSelectMulti(value, "site_area");
                  }}
                  placeholder=""
                  name="site_area"
                  options={[
                    { value: "North", label: "North" },
                    { value: "Around the Sea of ​​Galil", label: "Around the Sea of ​​Galil" },
                    { value: "Haifa", label: "Haifa" },
                    { value: "Center", label: "Center" },
                    { value: "Tel Aviv", label: "Tel Aviv" },
                    { value: "Jerusalem", label: "Jerusalem" },
                    { value: "West Bank", label: "West Bank" },
                    { value: "South", label: "South" },
                    { value: "Negev", label: "Negev" },
                    { value: "Sharon", label: "Sharon" },
                    { value: "Judea and Samaria", label: "Judea and Samaria"},
                  ]}
                  validation={blurValidation}
                  validationType="multi"
                  validationRoles={{ name: "Area", require: false }}
                  formStep="1"
                />
                <FloatingInput
                  color="orange"
                  label="City *"
                  value={site.site_city}
                  onChange={handleChange}
                  name="site_city"
                  validation={blurValidation}
                  validationType="text"
                  validationRoles={{ name: "City", require: false }}
                  formStep="1"
                />
                <FloatingInput
                  color="orange"
                  label="Address "
                  value={site.site_street_number}
                  onChange={handleChange}
                  name="site_street_number"
                  validation={blurValidation}
                  validationType="text"
                  validationRoles={{ name: "Address", require: false }}
                  formStep="1"
                />
                <FloatingInput
                  color="orange"
                  label="Delivery Address "
                  value={site.site_postal_address}
                  onChange={handleChange}
                  name="site_postal_address"
                  validation={blurValidation}
                  validationType="text"
                  validationRoles={{ name: "Delivery Address", require: false }}
                  formStep="1"
                />
                <FloatingInput
                  color="orange"
                  label="Phone "
                  value={site.site_phone}
                  onChange={handleChangePhone}
                  name="site_phone"
                  validation={blurValidation}
                  validationType="phone"
                  validationRoles={{ name: "Phone", require: false }}
                  formStep="1"
                />
                <FloatingInput
                  color="orange"
                  label="Fax"
                  value={site.site_fax}
                  onChange={handleChangePhone}
                  name="site_fax"
                  validation={blurValidation}
                  validationType="phone"
                  validationRoles={{ name: "Fax", require: false }}
                  formStep="1"
                />

                <FloatingInput
                  color="orange"
                  label="Company ID*"
                  onChange={handleChange}
                  value={site.site_company_id}
                  name="site_company_id"
                  validation={blurValidation}
                  validationType="text"
                  validationRoles={{ name: "Site Company", require: false }}
                  formStep="1"
                />
              </div>
              <div className="col">
                <FloatingInput
                  color="orange"
                  label="Mail *"
                  value={site.site_mail}
                  onChange={handleChange}
                  name="site_mail"
                  validation={blurValidation}
                  validationType="email"
                  validationRoles={{ name: "Mail", require: false }}
                  direction="left"
                  formStep="1"
                />
                <FloatingInput
                  color="orange"
                  label="Web Site"
                  value={site.site_site}
                  onChange={handleChange}
                  name="site_site"
                  validation={blurValidation}
                  validationType="website"
                  validationRoles={{ name: "Site", require: false }}
                  direction="left"
                  formStep="1"
                />
                <FloatingDateInput
                  color="orange"
                  label="Price List Valid From "
                  value={site.site_from_date}
                  onChange={(e) => {
                    handleChangeTime("site_from_date", e);
                  }}
                  name="site_from_date"
                  validation={blurValidation}
                  validationType="date"
                  validationRoles={{ name: "Date", require: false }}
                  direction="left"
                  formStep="1"
                />
                <FloatingDateInput
                  color="orange"
                  label="Price List Valid To "
                  value={site.site_to_date}
                  onChange={(e) => {
                    handleChangeTime("site_to_date", e);
                  }}
                  name="site_to_date"
                  validation={blurValidation}
                  validationType="date"
                  validationRoles={{ name: "Date", require: false }}
                  direction="left"
                  formStep="1"
                />
                <FloatingTextarea
                  color="orange"
                  label="Description *"
                  value={site.site_description}
                  onChange={handleChange}
                  name="site_description"
                  validation={blurValidation}
                  validationType="text"
                  validationRoles={{ name: "Description", require: false }}
                  direction="left"
                  formStep="1"
                />
                

              </div>
              <div className="btns">
                <div
                  className="btn red"
                  onClick={() => {
                    setDiscard(true);
                  }}
                >
                  Discard All
                </div>
                <div
                  className="btn gray"
                  onClick={() => {
                    nextStep(1);
                  }}
                >
                  Next
                </div>
              </div>
            </form>
          ) : null}
          {stepper?.current === 2 ? (
            <form className="step-2">
              <div className="col">
                <div className="map">
                  {loading ? (
                    <div className="loader-wrapper">
                      <Loader />
                    </div>
                  ) : null}
                  <InteractiveMap
                    {...viewport}
                    mapStyle="mapbox://styles/theguy/ckev6139w0wz21aofyw2gcsa7"
                    mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                    onClick={handleClick}
                    onViewportChange={(nextViewport) => setViewport(nextViewport)}
                  >
                    <NavigationControl />
                    {addressMarkers.map((marker) => {
                      return (
                        <>
                          <Marker latitude={marker[0]} longitude={marker[1]}>
                            <div
                              onClick={() => {
                                setSite({
                                  ...site,
                                  site_lat: marker[0],
                                  site_lon: marker[1],
                                });
                                let inputs = { ...stepper.steps[1].inputs };
                                inputs["site_lat"] = true;
                                inputs["site_lon"] = true;
                                let newStemps = [...stepper.steps];
                                newStemps[1].inputs = inputs;
                                setStepper({ ...stepper, steps: newStemps });
                                setLocation(true);
                              }}
                            >
                              <img src={require("../../assets/images/pin.png").default} />
                            </div>
                          </Marker>
                        </>
                      );
                    })}
                  </InteractiveMap>
                </div>
              </div>
              <div className="col">
                <p>
                  If you have the address of the site, please enter it and click Search. After the pins appears on the
                  map, click on the relevant pin, If you do not find the address or do not have an exact address, click
                  on the map on the desired location and then on the pin that appears. (Please be as precise as
                  possible). After clicking the desired pin a green label will appear to confirm your selection.
                </p>
                <FloatingInput
                  color="orange"
                  label="Search Address..."
                  onChange={handleChangeAddressSearch}
                  value={addressSearch}
                  name="addressSearch"
                  validation={blurValidation}
                  validationType="any"
                  validationRoles={{ name: "Search", require: false }}
                  formStep="2"
                />
                <div
                  className="btn-addresshearch"
                  onClick={(e) => {
                    e.preventDefault();
                    setLoding(true);
                    searchAddress();
                  }}
                >
                  Search
                </div>
                {location ? <div className="location-popup">Tourism Site Location Selected.</div> : null}
              </div>
              <div className="btns">
                <div
                  className="btn red"
                  onClick={() => {
                    setDiscard(true);
                  }}
                >
                  Discard All
                </div>
                <div
                  className="btn gray"
                  onClick={() => {
                    prevStep(2);
                  }}
                >
                  Back
                </div>
                <div
                  className="btn gray"
                  onClick={() => {
                    nextStep(2);
                  }}
                >
                  Next
                </div>
              </div>
            </form>
          ) : null}
          {stepper?.current === 3 ? (
            <form className="step-3">
              <div className="col">
                <FloatingSelectMulti
                  color="#93c840"
                  label="Classification *"
                  placeholder=""
                  selectValue={site.site_type}
                  onChange={(value) => {
                    handleChangeSelectMulti(value, "site_type");
                  }}
                  name="site_type"
                  options={[
                    { value: "Museum", label: "Museum" },
                    // { value: "National Garden", label: "National Garden" },
                    { value: "Nature Reserve and Parks", label: "Nature Reserve and Parks" },
                    { value: "Out Door and Sport Activities", label: "Out Door and Sport Activities" },
                    { value: "Amusement Park", label: "Amusement Park" },
                    { value: "Workshops", label: "Workshops" },
                    { value: "Wine Tasting", label: "Wine Tasting" },
                    { value: "Tours", label: "Tours" },
                    { value: "Home Hospitality", label: "Home Hospitality" },
                    { value: "Cable", label: "Cable" },
                    { value: "Historical Sites", label: "Historical Sites" },
                    { value: "Spa and Pools", label: "Spa and Pools" },
                    { value: "Art and Culture", label: "Art and Culture" },
                    { value: "Restaurants", label: "Restaurants" },
                    { value: "Agriculture", label: "Agriculture" },
                    { value: "Hospitality", label: "Hospitality" },
                    { value: "Others", label: "Others" },
                  ]}
                  validation={blurValidation}
                  validationType="multi"
                  validationRoles={{ name: "Classification", require: false }}
                  formStep="3"
                />
                <FloatingSelectMulti
                  color="#93c840"
                  label="Holy Places *"
                  placeholder=""
                  selectValue={site.site_holy_place}
                  onChange={(value) => {
                    handleChangeSelectMulti(value, "site_holy_place");
                  }}
                  name="site_holy_place"
                  options={[
                    { value: "Judaism", label: "Judaism" },
                    { value: "Christianity", label: "Christianity" },
                    { value: "Islam", label: "Islam" },
                    { value: "Other", label: "Other" },
                    { value: "No", label: "No" },
                  ]}
                  validation={blurValidation}
                  validationType="multi"
                  validationRoles={{ name: "Holy Places", require: false }}
                  formStep="3"
                />
                <FloatingSelect
                  color="#93c840"
                  onChange={(value) => {
                    handleChangeSelect(value, "site_food");
                  }}
                  label="Food *"
                  placeholder=""
                  options={[
                    { value: "Y", label: "Yes" },
                    { value: null, label: "No" },
                  ]}
                  name="site_food"
                  selectValue={site.site_food}
                  validation={blurValidation}
                  validationType="yesno"
                  validationRoles={{ name: "Food", require: false }}
                  formStep="3"
                />
                <FloatingSelect
                  color="#93c840"
                  onChange={(value) => {
                    handleChangeSelect(value, "site_alcohol");
                  }}
                  label="Wine and Alcohol *"
                  placeholder=""
                  options={[
                    { value: "Y", label: "Yes" },
                    { value: null, label: "No" },
                  ]}
                  name="site_alcohol"
                  selectValue={site.site_alcohol}
                  validation={blurValidation}
                  validationType="yesno"
                  validationRoles={{ name: "Wine and Alcohol", require: false }}
                  formStep="3"
                />
                <FloatingSelectMulti
                  color="#93c840"
                  label="Weekend and Holiday *"
                  placeholder=""
                  selectValue={site.site_holidays}
                  onChange={(value) => {
                    handleChangeSelectMulti(value, "site_holidays");
                  }}
                  name="site_holidays"
                  options={[
                    { value: "Active On Fridays", label: "Active on Fridays" },
                    { value: "Active On Saturdays", label: "Active on Saturdays" },
                    { value: "Active On Sundays", label: "Active on Sundays" },
                    { value: "Active On Holidays", label: "Active on Holidays" },
                  ]}
                  validation={blurValidation}
                  validationType="multi"
                  validationRoles={{ name: "Weekend and Holiday", require: false }}
                  formStep="3"
                />
              </div>
              <div className="col">
                <FloatingSelect
                  color="#93c840"
                  onChange={(value) => {
                    handleChangeSelect(value, "site_accessibility");
                  }}
                  label="Accessibility *"
                  placeholder=""
                  options={[
                    { value: "Y", label: "Yes" },
                    { value: null, label: "No" },
                  ]}
                  name="site_accessibility"
                  selectValue={site.site_accessibility}
                  direction="left"
                  validation={blurValidation}
                  validationType="yesno"
                  validationRoles={{ name: "Accessibility", require: false }}
                  formStep="3"
                />
                <FloatingSelect
                  color="#93c840"
                  onChange={(value) => {
                    handleChangeSelect(value, "site_kids");
                  }}
                  label="Suitable for Children *"
                  placeholder=""
                  options={[
                    { value: "Y", label: "Yes" },
                    { value: null, label: "No" },
                  ]}
                  name="site_kids"
                  selectValue={site.site_kids}
                  direction="left"
                  validation={blurValidation}
                  validationType="yesno"
                  validationRoles={{ name: "Suitable for Children", require: false }}
                  formStep="3"
                />
                <FloatingSelect
                  color="#93c840"
                  onChange={(value) => {
                    handleChangeSelect(value, "site_school");
                  }}
                  label="Activities for Schools *"
                  placeholder=""
                  options={[
                    { value: "Y", label: "Yes" },
                    { value: null, label: "No" },
                  ]}
                  name="site_school"
                  selectValue={site.site_school}
                  direction="left"
                  validation={blurValidation}
                  validationType="yesno"
                  validationRoles={{ name: "Activities for Schools", require: false }}
                  formStep="3"
                />
                <FloatingSelectMulti
                  color="#93c840"
                  label="Languages *"
                  placeholder=""
                  options={[
                    { value: "Hebrew", label: "Hebrew" },
                    { value: "English", label: "English" },
                    { value: "French", label: "French" },
                    { value: "Spanish", label: "Spanish" },
                    { value: "German", label: "German" },
                    { value: "Polish", label: "Polish" },
                    { value: "Italian", label: "Italian" },
                    { value: "Russian", label: "Russian" },
                    { value: "Chinese", label: "Chinese" },
                    { value: "Arabic", label: "Arabic" },
                    { value: "Portuguese", label: "Portuguese" },
                    { value: "Japanese", label: "Japanese" },
                  ]}
                  selectValue={site.site_languages}
                  onChange={(value) => {
                    handleChangeSelectMulti(value, "site_languages");
                  }}
                  name="site_languages"
                  direction="left"
                  validation={blurValidation}
                  validationType="yesno"
                  validationRoles={{ name: "Languages", require: false }}
                  formStep="3"
                />
                <FloatingSelect
                  color="#93c840"
                  onChange={(value) => {
                    handleChangeSelect(value, "site_kosher");
                  }}
                  label="Kosher *"
                  placeholder=""
                  options={[
                    { value: "Y", label: "Yes" },
                    { value: null, label: "No" },
                  ]}
                  name="site_kosher"
                  selectValue={site.site_kosher}
                  direction="left"
                  validation={blurValidation}
                  validationType="yesno"
                  validationRoles={{ name: "site_kosher", require: true }}
                  formStep="3"
                />
              </div>
              <div className="btns">
                <div
                  className="btn red"
                  onClick={() => {
                    setDiscard(true);
                  }}
                >
                  Discard All
                </div>
                <div
                  className="btn gray"
                  onClick={() => {
                    prevStep(3);
                  }}
                >
                  Back
                </div>
                <div
                  className="btn gray"
                  onClick={() => {
                    nextStep(3);
                  }}
                >
                  Next
                </div>
              </div>
            </form>
          ) : null}
          {stepper?.current === 4 ? (
            <form className="step-4">
              <div className="col">
                <h2 className="sub-title">First Contact information</h2>
                <FloatingInput
                  color="orange"
                  label="Name *"
                  value={site.person_first_name}
                  onChange={handleChange}
                  name="person_first_name"
                  validation={blurValidation}
                  validationType="text"
                  validationRoles={{ name: "Name", require: false }}
                  formStep="4"
                />
                <FloatingInput
                  color="orange"
                  label="Phone *"
                  value={site.person_first_phone}
                  onChange={handleChangePhone}
                  name="person_first_phone"
                  validation={blurValidation}
                  validationType="phone"
                  validationRoles={{ name: "Phone", require: false }}
                  formStep="4"
                />
                <FloatingInput
                  color="orange"
                  label="Position *"
                  value={site.person_first_role}
                  onChange={handleChange}
                  name="person_first_role"
                  validation={blurValidation}
                  validationType="text"
                  validationRoles={{ name: "Position", require: false }}
                  formStep="4"
                />
                <FloatingInput
                  color="orange"
                  label="Email *"
                  value={site.person_first_email}
                  onChange={handleChange}
                  name="person_first_email"
                  validation={blurValidation}
                  validationType="email"
                  validationRoles={{ name: "Mail", require: false }}
                  formStep="4"
                />
              </div>
              <div className="col">
                <h2 className="sub-title">Second Contact information</h2>
                <FloatingInput
                  color="orange"
                  label="Name"
                  value={site.person_second_name}
                  onChange={handleChange}
                  name="person_second_name"
                  direction="left"
                  validation={blurValidation}
                  validationType="text"
                  validationRoles={{ name: "Name", require: false }}
                  formStep="4"
                />
                <FloatingInput
                  color="orange"
                  label="Phone"
                  value={site.person_second_phone}
                  onChange={handleChangePhone}
                  name="person_second_phone"
                  direction="left"
                  validation={blurValidation}
                  validationType="phone"
                  validationRoles={{ name: "Phone", require: false }}
                  formStep="4"
                />
                <FloatingInput
                  color="orange"
                  label="Position"
                  value={site.person_second_role}
                  onChange={handleChange}
                  name="person_second_role"
                  direction="left"
                  validation={blurValidation}
                  validationType="text"
                  validationRoles={{ name: "Position", require: false }}
                  formStep="4"
                />
                <FloatingInput
                  color="orange"
                  label="Email"
                  value={site.person_second_email}
                  onChange={handleChange}
                  name="person_second_email"
                  direction="left"
                  validation={blurValidation}
                  validationType="email"
                  validationRoles={{ name: "Mail", require: false }}
                  formStep="4"
                />
              </div>
              <div className="btns">
                <div
                  className="btn red"
                  onClick={() => {
                    setDiscard(true);
                  }}
                >
                  Discard All
                </div>
                <div
                  className="btn gray"
                  onClick={() => {
                    prevStep(4);
                  }}
                >
                  Back
                </div>
                <div
                  className="btn gray"
                  onClick={() => {
                    nextStep(4);
                  }}
                >
                  Next
                </div>
              </div>
            </form>
          ) : null}
          {stepper?.current === 5 ? (
            <form className="step-5">
              <div className="col-3">
                <h2 className="sub-title">Tourism Site Main Image</h2>
                <MainImageInput add={handleChangeMainImage} remove={handleChangeRemoveFiles} value={site.site_main_img} />
              </div>
              <div className="col">
                <h2 className="sub-title">Tourism Site Images</h2>
                <ImageInput add={handleChangeSelectFiles} remove={handleChangeRemoveFiles} value={site.site_files} />
              </div>
              <div className="col">
                <h2 className="sub-title">Tourism Site Brochures</h2>
                <PdfInput add={handleChangeSelectFiles} remove={handleChangeRemoveFiles} value={site.site_files} />
              </div>
              {loading ? (
                <div className="btns">
                  <Loader />
                </div>
              ) : (
                <div className="btns">
                  <div
                    className="btn red"
                    onClick={() => {
                      setDiscard(true);
                    }}
                  >
                    Discard All
                  </div>
                  <div
                    className="btn gray"
                    onClick={() => {
                      prevStep(5);
                    }}
                  >
                    Back
                  </div>
                  <div
                    className="btn green"
                    onClick={(e) => {
                      e.preventDefault();
                      createSite();
                    }}
                  >
                    Create
                  </div>
                </div>
              )}
            </form>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SiteForm;
