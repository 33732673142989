import "./FloatingSelect.scss";
import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";

const FloatingSelectMulti = (props) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: " 100%",
      fontWeight: "400",
      fontSize: "16px",
      padding: "4px 20px",
      borderRadius: "30px",
      border: state.isFocused ? `1px solid ${props.color} ` : "1px solid #909090",
      boxShadow: state.isFocused ? "none" : "none",
      boxShadow: state.isSelected ? "none" : "none",
      "&:hover": {
        borderColor: state.isFocused ? props.color : "#909090",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? props.color : state.isFocused ? `${props.color}10` : "#fff",
      padding: "10px 15px",
      fontWeight: "400",
      fontSize: "16px",
    }),
    menu: () => ({
      position: "absolute",
      width: "100%",
      zIndex: "100",
      backgroundColor: "#fff",
      top: "50px",
      overflow: 'hidden !important',
      padding: "0",
      border: `1px solid ${props.color}`,
      borderRadius: "30px",
      
    }),
    menuList: () => ({
      padding: "0",
      // overflow: "hidden",
      maxHeight: '30vh',
      overflowY: 'auto'
    }),
    multiValue: () => ({
      display: "flex",
      backgroundColor: `${props.color}2c`,
      marginRight: "5px",
      borderRadius: "10px",
      fontSize: "14px",
      paddingRight: "5px",
    }),
  };
  const [float, setFloat] = useState(false);
  const [focused, setFocused] = useState(false);
  const inputRef = useRef();
  const [tooltip, setTooltip] = useState();
  useEffect(() => {
    setFloat(props.selectValue.length === 0 && !focused ? false : true);
  }, [props.selectValue]);
  return (
    <div className="FloatingSelect">
      <label
        // className={float ? (focused ? "float focused" : "float") : null}
        onClick={() => {
          inputRef.current.focus();
          setFocused(true);
          return props.selectValue.length === 0 ? setFloat(true) : null;
        }}
        className={float ? (focused ? `float ${props.color === "#93c840" ? "orange" : null} focused` : `float`) : null}
      >
        {props.label}
        {float ? (focused ? " (Multi Select)" : null) : null}
      </label>
      <Select
        isMulti
        closeMenuOnSelect={false}
        {...props}
        ref={inputRef}
        styles={customStyles}
        value={props.options.filter((item) => props.selectValue.includes(item.value))}
        onFocus={() => {
          setFloat(true);
          setFocused(true);
        }}
        onBlur={(e) => {
          setFloat(props.selectValue.length === 0 ? false : true);
          setFocused(false);
          setTooltip(
            props.validation(
              props.name,
              props.validationType,
              props.options.filter((item) => props.selectValue.includes(item.value)),
              props.validationRoles,
              props.formStep
            )
          );
        }}
        name={props.name}
      />
      {tooltip?.valid === false ? <div className={"tooltip " + props.direction}>{tooltip?.msg}</div> : null}
    </div>
  );
};

export default FloatingSelectMulti;
