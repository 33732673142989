import "./UserForm.scss";
import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import { UsersContext } from "../../context/UsersContext";
import { SnackBarsContext } from "../../context/SnackBarsContext";
import FloatingInput from "../inputs/FloatingInput";
import FloatingSelect from "../inputs/FloatingSelect";
import Loader from "../Loader";
import * as api from "../../shared/api";
import * as priorityApi from "../../shared/priorityApi";
import * as validate from "../../utils/validation";
import { AuthContext } from "../../context/AuthContext";
import * as priorityServices from "../../utils/priorityServices";

const UserForm = () => {
  const { dispatchSnackBar } = useContext(SnackBarsContext);
  const { usersContextData, usersContextDispatch } = useContext(UsersContext);
  const [user, setUser] = useState({});
  const refUser = useRef({});
  const [loading, setLoding] = useState(false);

  useEffect(() => {
    if (usersContextData.user.edit) {
      setUser(usersContextData.user.userData);
      refUser.current = usersContextData.user.userData;
    } else {
      setUser({
        id: "",
        type: "",
        name: "",
        email: "",
        password: "",
        company: "",
        role: "",
        phone: "",
        provider: "",
        company_phone: "",
        company_fax: "",
        company_addres: "",
        company_email: "",
        company_zipcode: "",
        company_pcn: "",
        company_logo: "",
      });
      refUser.current = {
        id: "",
        type: "",
        name: "",
        email: "",
        password: "",
        company: "",
        role: "",
        phone: "",
        provider: "",
        company_phone: "",
        company_fax: "",
        company_addres: "",
        company_email: "",
        company_zipcode: "",
        company_pcn: "",
        company_logo: "",
      };
    }
  }, [usersContextData]);

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
    refUser.current[e.target.name] = e.target.value;

  };

  const handleChangeSelect = (e) => {
    setUser({
      ...user,
      type: e.value,
    });
    refUser.current.type = e.value;
  };

  const blurValidation = (name, type, value, roles) => {
    return validate[type](value, roles);
  };

  const registerUser = useCallback(async () => {
    setLoding(true);
    // if (refUser.current.type === 2 && refUser.current.provider.length === 0) {
    //   const provider = await getProvider();
    //   if (provider) {
    //     setUser({ ...user, provider: provider });
    //     refUser.current.provider = provider;
    //   } else {
    //    setLoding(false);
    //     dispatchSnackBar({
    //       type: "SET_ERROR",
    //       payload: "Something went wrong please contact support",
    //     });
    //     return;
    //   }
    // }

    try {
      const res = await api.registerUser(refUser.current);
      if (res.data.success) {
        //new
        //
        // const new_priority_agent = await priorityApi.createAgent(priorityServices.createPriorityAgent(refUser));
        // console.log('new_priority_agent', new_priority_agent);
        //endnew
        refUser.current.id = res.data.insertId;
        usersContextDispatch({ type: "CREATE_USER", payload: refUser.current });
        dispatchSnackBar({
          type: "SET_SUCCESS",
          payload: "User Created",
        });
        usersContextDispatch({ type: "DISCARD_FORM" });

      }
      if (!res.data.success) {
        switch (res.data.code) {
          case 0:
            //logout
            break;
          case 1:
            setLoding(false);
            dispatchSnackBar({
              type: "SET_ERROR",
              payload: "Something went wrong please contact support",
            });
            break;
          case 2:
            setLoding(false);
            dispatchSnackBar({
              type: "SET_ERROR",
              payload: res.data.msg,
            });
            break;
          default:
          // code block
        }
      }
    } catch (err) {
      setLoding(false);
      dispatchSnackBar({
        type: "SET_ERROR",
        payload: "Something went wrong please contact support",
      });
    }
  });

  const getProvider = useCallback(async () => {
    if (refUser.current.name.length === 0) {
      return null;
    }
    try {
      const res = await priorityApi.createUser(
        { TYPE: "S", TEXT1: refUser.current.name, TEXT23: refUser.current.phone, TEXT20: refUser.current.email },
      );
      if (res.data.TEXT3) {
        return res.data.TEXT3;
      }
    } catch (err) {
      return null;
    }
  });

  const editUser = useCallback(async () => {
    setLoding(true);
    try {
      const res = await api.editUser(user, user.id);
      if (res.data.success) {
        usersContextDispatch({
          type: "SAVE_USER",
          payload: { id: user.id, data: user },
        });
        dispatchSnackBar({
          type: "SET_SUCCESS",
          payload: "User Updated",
        });
        usersContextDispatch({ type: "DISCARD_FORM" });
      }
      if (!res.data.success) {
        switch (res.data.code) {
          case 0:
            //logout
            break;
          case 1:
            setLoding(false);
            dispatchSnackBar({
              type: "SET_ERROR",
              payload: "Something went wrong please contact support",
            });
            break;
          case 2:
            setLoding(false);
            dispatchSnackBar({
              type: "SET_ERROR",
              payload: res.data.msg,
            });
            break;
          default:
          // code block
        }
      }
    } catch (err) {
      setLoding(false);
      dispatchSnackBar({
        type: "SET_ERROR",
        payload: "Something went wrong please contact support",
      });
    }
  });
  

  return (
    <div className="UserForm">
      <div className="container">
        <div className="content">
          {usersContextData.user.edit ? <h1 className="title">Edit User</h1> : <h1 className="title">Create User</h1>}
          <form>
            <div className="form-section">
              <h2 className="sub-title">User Details</h2>
              <div className="col-full">
                <FloatingSelect
                  color="#1a569d"
                  onChange={handleChangeSelect}
                  label="User Type"
                  placeholder=""
                  selectValue={user.type}
                  options={[
                    { value: 1, label: "Agent" },
                    { value: 2, label: "Tourism site manager" },
                    { value: 3, label: "System admin" },
                  ]}
                  validation={blurValidation}
                  validationType="multi"
                  validationRoles={{ name: "User Type", require: true, min: 1 }}
                />
              </div>
              <div className="col">
                <FloatingInput
                  label="Email"
                  color="blue"
                  value={user.email}
                  onChange={handleChange}
                  name="email"
                  validation={blurValidation}
                  validationType="email"
                  validationRoles={{ name: "Email", require: true }}
                />
                <FloatingInput
                  label="Company"
                  color="blue"
                  value={user.company}
                  onChange={handleChange}
                  name="company"
                  validation={blurValidation}
                  validationType="text"
                  validationRoles={{ name: "Company", require: true, min: 2, max: 255 }}
                />
                <FloatingInput
                  label="Phone"
                  color="blue"
                  value={user.phone}
                  onChange={handleChange}
                  name="phone"
                  validation={blurValidation}
                  validationType="phone"
                  validationRoles={{ name: "Phone", require: true }}
                />
              </div>
              <div className="col">
                <FloatingInput
                  label="Name"
                  color="blue"
                  value={user.name}
                  onChange={handleChange}
                  name="name"
                  validation={blurValidation}
                  validationType="text"
                  validationRoles={{ name: "Name", require: true, min: 2, max: 255 }}
                />
                <FloatingInput
                  label="Password"
                  color="blue"
                  value={user.password}
                  onChange={handleChange}
                  name="password"
                  validation={blurValidation}
                  validationType="text"
                  validationRoles={{ name: "Password", require: true, min: 6, max: 255 }}
                />
                <FloatingInput
                  label="Role"
                  color="blue"
                  value={user.role}
                  onChange={handleChange}
                  name="role"
                  validation={blurValidation}
                  validationType="text"
                  validationRoles={{ name: "Role", require: true, min: 2, max: 255 }}
                />
              </div>
            </div>
            {user.type === 1 ? (
              <div className="form-section">
                <h2 className="sub-title">Company Details</h2>
                <div className="col">
                  <FloatingInput
                    label="Phone"
                    color="blue"
                    value={user.company_phone}
                    onChange={handleChange}
                    name="company_phone"
                    validation={blurValidation}
                    validationType="phone"
                    validationRoles={{ name: "Phone", require: true }}
                  />
                  <FloatingInput
                    label="Addres"
                    color="blue"
                    value={user.company_addres}
                    onChange={handleChange}
                    name="company_addres"
                    validation={blurValidation}
                    validationType="text"
                    validationRoles={{ name: "Addres", require: true, min: 2, max: 255 }}
                  />
                  <FloatingInput
                    label="Zipcode"
                    color="blue"
                    value={user.company_zipcode}
                    onChange={handleChange}
                    name="company_zipcode"
                    validation={blurValidation}
                    validationType="text"
                    validationRoles={{ name: "Zipcode", require: true, min: 2, max: 255 }}
                  />
                </div>
                <div className="col">
                  <FloatingInput
                    label="Fax"
                    color="blue"
                    value={user.company_fax}
                    onChange={handleChange}
                    name="company_fax"
                    direction="left"
                    validation={blurValidation}
                    validationType="phone"
                    validationRoles={{ name: "Fax", require: true }}
                  />
                  <FloatingInput
                    label="Email"
                    color="blue"
                    value={user.company_email}
                    onChange={handleChange}
                    name="company_email"
                    direction="left"
                    validation={blurValidation}
                    validationType="email"
                    validationRoles={{ name: "Email", require: true }}
                  />
                  <FloatingInput
                    label="Company Number"
                    color="blue"
                    value={user.company_pcn}
                    onChange={handleChange}
                    name="company_pcn"
                    direction="left"
                    validation={blurValidation}
                    validationType="text"
                    validationRoles={{ name: "Company Number", require: true, min: 2, max: 255 }}
                  />
                </div>
                <div className="col-full">
                  <FloatingInput
                    label="Logo"
                    color="blue"
                    value={user.company_logo}
                    onChange={handleChange}
                    name="company_logo"
                    direction="left"
                    validation={blurValidation}
                    validationType="text"
                    validationRoles={{ name: "Logo", require: true, min: 2, max: 255 }}
                  />
                </div>
              </div>
            ) : null}
            {loading ? (
              <div className="btn-wrapper">
                <Loader />
              </div>
            ) : (
              <div className="btn-wrapper">
                <div
                  className="btn red"
                  onClick={() => {
                    usersContextDispatch({ type: "DISCARD_FORM" });
                  }}
                >
                  Discard
                </div>
                {usersContextData.user.edit ? (
                  <div className="btn blue" onClick={editUser}>
                    Save
                  </div>
                ) : (
                  <div className="btn blue" onClick={registerUser}>
                    Create
                  </div>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
export default UserForm;
