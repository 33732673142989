import "./MapPopup.scss";
import React, { useEffect, useCallback, useState } from "react";
import { Popup } from "react-map-gl";
import * as api from "../shared/api";
import Rating from "@material-ui/lab/Rating";
import { BrowserRouter as Router, Switch, Route, Link, useParams, useHistory } from "react-router-dom";

const MapPopup = (props) => {
  // console.log(props);
  const [rating, setRating] = useState(0);
  const [comments, setComments] = useState(0);
  const history = useHistory();
  let img = require("../assets/images/img-ph.png").default;
  if (props.data.ZITA_WEBATTREXTFILE_SUBFORM && props.data.ZITA_WEBATTREXTFILE_SUBFORM.length !== 0) {
    for (let i = 0; i < props.data.ZITA_WEBATTREXTFILE_SUBFORM.length; i++) {
      if (props.data.ZITA_WEBATTREXTFILE_SUBFORM[i].PICTURE == "Y") {
        img = props.data?.ZITA_WEBATTREXTFILE_SUBFORM[i].EXTFILENAME;
        break;
      }
    }
  }
  const goToSite = () => {
    history.push(`../site/${props.data.ATTRCODE}`);
  };

  return (
    <Popup
      {...props}
      latitude={1}
      longitude={1}
      tipSize={0}
      onClose={props.close}
      captureScroll={true}
      captureDrag={true}
      captureClick={false}
      captureDoubleClick={true}
      closeOnClick={false}
    >
      <div className="content">
        <div className="site-data">
          <div className="image">
            <img src={img} />
          </div>
          <div className="discription">
            <h1 className="title">{props.data?.ATTRDES}</h1>
            <h3 className="address">Address: {props.data?.ADDRESS && props.data?.ADDRESS}, {props.data?.MAPXPOINT} {props.data?.MAPYPOINT}</h3>
            <p
              className="text"
              dangerouslySetInnerHTML={{
                __html: props.data.ZITA_WATTRACTIONSTXT_SUBFORM?.TEXT.split("<br>").slice(0, 2).join(" "),
              }}
            ></p>
          </div>
          <div className="social">
            <div className="rating">
              <Rating name="read-only" value={props.data.rating} readOnly />
            </div>
            <div className="comments">
              {props.data.comments}
              <img src={require("../assets/images/comments.png").default} />
            </div>
          </div>
        </div>
        {props.data.ZITA_WEBACT_SUBFORM.length > 0 ? (
          <div className="activity-data">
            <div className="activity">
              <div className="name">{props.data.ZITA_WEBACT_SUBFORM[0].ACTDES}</div>
              <div className="more-data">
                <span className="data">
                  <img src={require("../assets/images/duration.png").default} />
                  {props.data.ZITA_WEBACT_SUBFORM[0].DURATION}
                </span>
                <span className="data">
                  <img src={require("../assets/images/days.png").default} />
                  {props.data.ZITA_WEBACT_SUBFORM[0].DAYS.split(";").join(", ")}
                </span>
                <span className="data">
                  <img src={require("../assets/images/group.png").default} />
                  {`${props.data.ZITA_WEBACT_SUBFORM[0].MINNUM} - ${props.data.ZITA_WEBACT_SUBFORM[0].MAXNUM}`}
                </span>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        <a
          onClick={() => {
            goToSite();
          }}
        >
          More Data and Activities
        </a>
      </div>
    </Popup>
  );
};

export default MapPopup;
