import "./TariffForm.scss";
import React, { useState, useCallback, useContext } from "react";
import FloatingInput from "../inputs/FloatingInput";
import FloatingSelect from "../inputs/FloatingSelect";
import Loader from "../Loader";
import { AuthContext } from "../../context/AuthContext";
import { SiteMngtContext } from "../../context/SiteMngtContext";
import { SnackBarsContext } from "../../context/SnackBarsContext";
import * as priorityServices from "../../utils/priorityServices";
import * as priorityApi from "../../shared/priorityApi";
import * as validate from "../../utils/validation";

const TariffForm = (props) => {
  const { authContextData } = useContext(AuthContext);
  const { SiteMngtContextData, SiteMngtContextDispatch } = useContext(SiteMngtContext);
  const { dispatchSnackBar } = useContext(SnackBarsContext);

  const [loading, setLoding] = useState(false);
  const [tariff, setTariff] = useState({
    site_id: SiteMngtContextData.site.id,
    activity_id: SiteMngtContextData.activity.id,
    tarif_person: "",
    tariff_description: "",
    tariff_price_agent: "",
    tariff_price_group: "",
  });
  const [validation, setValidation] = useState({
    tarif_person: "",
    tariff_price_agent: "",
    tariff_price_group: "",
  });
  const handleChange = (e) => {
    setTariff({
      ...tariff,
      [e.target.name]: e.target.value,
    });
  };
  const handleChangeSelect = (value, name) => {
    setTariff({
      ...tariff,
      [name]: value ? value.value : null,
    });
  };
  const blurValidation = (name, type, value, roles) => {
    if (validate[type](value, roles).valid) {
      setValidation({ ...validation, [name]: true });
    } else {
      setValidation({ ...validation, [name]: false });
    }
    return validate[type](value, roles);
  };

  const createTariff = useCallback(async () => {
    for (var name in validation) {
      if (!validation[name]) {
        return dispatchSnackBar({
          type: "SET_ERROR",
          payload: "Please complate all fields.",
        });
      }
    }
    setLoding(true);
    const agentRes = await priorityApi.createTariff(
      priorityServices.createTariffObj(SiteMngtContextData.site.id, SiteMngtContextData.activity.id, {
        tarif_type: tariff.tarif_person,
        agent_group: "Agent",
        tariff_description: tariff.tariff_description,
        tariff_price: tariff.tariff_price_agent,
      })
    );
    const groupRes = await priorityApi.createTariff(
      priorityServices.createTariffObj(SiteMngtContextData.site.id, SiteMngtContextData.activity.id, {
        tarif_type: tariff.tarif_person,
        agent_group: "Group",
        tariff_description: tariff.tariff_description,
        tariff_price: tariff.tariff_price_group,
      })
    );
    if (agentRes.data && groupRes.data) {
      dispatchSnackBar({
        type: "SET_SUCCESS",
        payload: "Price List Created Successfully!",
      });
      setLoding(false);

      props.addNewData([
        {
          TICKETDES: agentRes.data.TEXT11,
          PAR1DES: agentRes.data.TEXT1,
          PAR2DES: agentRes.data.TEXT2,
          PRICE: agentRes.data.REAL1,
        },
        {
          TICKETDES: groupRes.data.TEXT11,
          PAR1DES: groupRes.data.TEXT1,
          PAR2DES: groupRes.data.TEXT2,
          PRICE: groupRes.data.REAL1,
        },
      ]);
      props.discard();
    }
  });

  return (
    <div className='TariffForm'>
      <div className='container'>
        <div className='content'>
          <h1 className='title'>Price List</h1>
          <p className=''>*All data must be entered in english.</p>
          <form className='step-1'>
            <div className='col'>
              <FloatingSelect
                color='#93c840'
                onChange={(value) => {
                  handleChangeSelect(value, "tarif_person");
                }}
                label='Person (Retiree, Student, Child ...) *'
                placeholder=''
                name='tarif_person'
                options={[
                  { value: "Adult", label: "Adult" },
                  { value: "Child", label: "Child" },
                  { value: "Retiree", label: "Retiree" },
                  { value: "Student", label: "Student" },
                  { value: "Retiree/Child", label: "Retiree/Child" },
                  { value: "People with disabilities", label: "People with disabilities" },
                  { value: "Soldier", label: "Soldier" },
                ]}
                selectValue={tariff.tarif_person}
                validation={blurValidation}
                validationType='yesno'
                validationRoles={{ name: "Person", require: true }}
              />
              <FloatingInput
                color='orange'
                label='Description'
                value={tariff.tariff_description}
                onChange={handleChange}
                name='tariff_description'
                validation={blurValidation}
                validationType='text'
                validationRoles={{ name: "Description", require: false }}
              />
              <FloatingInput
                color='orange'
                label='Price for Agent *'
                value={tariff.tariff_price_agent}
                onChange={handleChange}
                name='tariff_price_agent'
                validation={blurValidation}
                validationType='text'
                validationRoles={{ name: "Price for Agent", require: true }}
              />
              <FloatingInput
                color='orange'
                label='Price for Group *'
                value={tariff.tariff_price_group}
                onChange={handleChange}
                name='tariff_price_group'
                validation={blurValidation}
                validationType='text'
                validationRoles={{ name: "Price for Group", require: true }}
              />
            </div>
            {loading ? (
              <div className='btns'>
                <Loader />
              </div>
            ) : (
              <div className='btns'>
                <div className='btn red' onClick={props.discard}>
                  Discard
                </div>
                <div
                  className='btn green'
                  onClick={(e) => {
                    e.preventDefault();
                    createTariff();
                  }}
                >
                  Create
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default TariffForm;
