const hebrewPattern = new RegExp(/[\u0590-\u05FF]/);
const emailPattern = new RegExp(
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);
const phonePattern = new RegExp(/^0(?:[234689]|5[0-689]|7[2346789])(?![01])-(\d{7})$/);
const webPattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/);
const timePattern = new RegExp(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/);
const passwordReg = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/);

export const checkPassword = ({ password }) => {
  //console.log(password);
  if (password.length === 0) {
    return { valid: false, msg: "Password cannot be empty" };
  }
  if (!passwordReg.test(password) && password.length !== 0) {
    return { valid: false, msg: "Please enter valid password." };
  }
  return { valid: true };
};

export const email = (value, roles) => {
  if (value.length === 0 && roles.require) {
    return { valid: false, msg: "Email cannot be empty" };
  }
  if (!emailPattern.test(value) && value.length !== 0) {
    return { valid: false, msg: "Please enter valid email address." };
  }
  return { valid: true };
};

export const text = (value, roles) => {
  if (value.length === 0 && roles.require) {
    return { valid: false, msg: roles.name + " cannot be empty." };
  }
  if (hebrewPattern.test(value)) {
    return { valid: false, msg: "Please enter only english characters." };
  }
  return { valid: true };
};

export const multi = (value, roles) => {
  if (value.length === 0 && roles.require) {
    return { valid: false, msg: roles.name + " cannot be empty." };
  }
  return { valid: true };
};

export const yesno = (value, roles) => {
  if (value.length === 0 && roles.require) {
    return { valid: false, msg: roles.name + " cannot be empty." };
  }
  return { valid: true };
};

export const phone = (value, roles) => {
  if (value.length === 0 && roles.require) {
    return { valid: false, msg: roles.name + " cannot be empty." };
  } else {
    if (!phonePattern.test(value) && value.length !== 0) {
      return { valid: false, msg: "Please enter valid phone number. (03-12345678 or 054-12345678)" };
    }
  }
  return { valid: true };
};

export const website = (value, roles) => {
  if (value.length === 0 && roles.require) {
    return { valid: false, msg: roles.name + " cannot be empty." };
  } else {
    if (!webPattern.test(value) && value.length !== 0) {
      return { valid: false, msg: "Please enter valid web site url." };
    }
  }
  return { valid: true };
};

export const date = (value, roles) => {
  if ((value?.length === 0 || !value) && roles.require) {
    return { valid: false, msg: roles.name + " cannot be empty." };
  }
  return { valid: true };
};

export const time = (value, roles) => {
  if (value.length === 0 && roles.require) {
    return { valid: false, msg: roles.name + " cannot be empty." };
  } else {
    if (!timePattern.test(value) && value.length !== 0) {
      return { valid: false, msg: "Please enter valid time format (HH:MM)." };
    }
  }
  return { valid: true };
};

export const any = (value, roles) => {
  return { valid: true };
};
