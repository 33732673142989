export const siteMngtReducer = (state, action) => {
  switch (action.type) {
    case "SET_SITE":
      return {
        ...state,
        currentDataType: action.payload.currentDataType,
        site: action.payload.site,
      };
    case "DISCARD_SITE":
      return {
        ...state,
        currentDataType: "sites",
        site: null,
        activity: null,
      };
    case "SET_ACTIVITY":
      return {
        ...state,
        currentDataType: action.payload.currentDataType,
        activity: action.payload.activity,
      };
    case "DISCARD_ACTIVITY":
      return {
        ...state,
        currentDataType: "activities",
        activity: null,
      };
    default:
      return state;
  }
};
