import React, { createContext, useReducer } from "react";
import { usersReducer } from "./UsersReducer";

export const UsersContext = createContext();

const UsersContextProvider = (props) => {
  const [usersContextData, usersContextDispatch] = useReducer(usersReducer, {
    usersList: [],
    user: null,
  });
  return (
    <UsersContext.Provider value={{ usersContextData, usersContextDispatch }}>{props.children}</UsersContext.Provider>
  );
};

export default UsersContextProvider;
