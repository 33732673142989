import "./FullSite.scss";
import React, { useEffect, useCallback, useState } from "react";
import * as api from "../shared/api";
import Rating from "@material-ui/lab/Rating";

const FullSite = (props) => {
  let img = require("../assets/images/img-ph.png").default;
  if (props.data.ZITA_WEBATTREXTFILE_SUBFORM && props.data.ZITA_WEBATTREXTFILE_SUBFORM.length !== 0) {
    for (let i = 0; i < props.data.ZITA_WEBATTREXTFILE_SUBFORM.length; i++) {
      if (props.data.ZITA_WEBATTREXTFILE_SUBFORM[i].PICTURE == "Y") {
        img = props.data?.ZITA_WEBATTREXTFILE_SUBFORM[i].EXTFILENAME;
        break;
      }
    }
  }

  return (
    <div
      className="FullSite"
      onClick={() => {
        props.onClick(props.data);
      }}
    >
      <div className="content">
        <div className="social">
          <img src={img} alt="tourism sites" />
          <div className="rating">
            <Rating name="read-only" value={props.data.rating} readOnly />
          </div>
          <div className="comments">
            {props.data.comments}
            <img src={require("../assets/images/comments.png").default} />
          </div>
        </div>
        <div className="details">
          <div className="title">{props.data.ATTRDES}</div>
          <p
            className="description"
            dangerouslySetInnerHTML={{ __html: props.data.ZITA_WATTRACTIONSTXT_SUBFORM?.TEXT.slice(0, 100) + "..." }}
          ></p>
          <div className="more">
            {props.data.ZITA_WEBACT_SUBFORM.length > 0 ? props.data.ZITA_WEBACT_SUBFORM[0].ACTDES : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullSite;
