import "./Loader.scss";
import React from "react";

const Loader = () => {
  return (
    <div className='Loader'>
      <div className='circle'></div>
    </div>
  );
};

export default Loader;
