import "./SnackBars.scss";
import React, { useContext, useState, useEffect, useRef } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { SnackBarsContext } from "../context/SnackBarsContext";

const SnackBars = () => {
  const didMountRef = useRef(false);
  const { snackBar } = useContext(SnackBarsContext);
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (didMountRef.current) {
      setOpen(true);
    } else {
      didMountRef.current = true;
    }
  }, [snackBar]);

  return (
    <Snackbar
      className={snackBar.class}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      message={snackBar.msg}
    />
  );
};

export default SnackBars;
