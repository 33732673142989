import "./FormWithXl.scss";
import React, { useState, useContext, useCallback, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { SnackBarsContext } from "../../context/SnackBarsContext";
import * as validate from "../../utils/validation";
import xl_blank from '../../assets/files/attraction_base_file.xlsx';
//Inputs
import FloatingSelect from "../inputs/FloatingSelect";
import FloatingInput from "../inputs/FloatingInput";
import FloatingSelectMulti from "../inputs/FloatingSelectMulti";
import FloatingDateInput from "../inputs/FloatingDateInput";
import FloatingTextarea from "../inputs/FloatingTextarea";
import PdfInput from "../inputs/PdfInput";
import InteractiveMap, { Marker, WebMercatorViewport, NavigationControl } from "react-map-gl";

import * as api from "../../shared/api";
import * as priorityApi from "../../shared/priorityApi";
import * as priorityServices from "../../utils/priorityServices";

import Loader from "../Loader";
import { Link } from "react-router-dom";
import XLInput from "../inputs/XLInput";
import MainImageInput from "../inputs/MainImageInput";
import ImageInput from "../inputs/ImageInput";

const FormWithXl = (props) => {

    let inputs = {
        site_lat: false,
        site_lon: false,
    };
    const [msg, setMsg] = useState("");
    const [selectedFile, setSelectedFile] = useState();
    const [isSelected, setIsSelected] = useState(false);
    const { dispatchSnackBar } = useContext(SnackBarsContext);
    const { authContextData } = useContext(AuthContext);

    const [site, setSite] = useState({
      site_name: "",
      site_company_id: "",
      site_description: "New Attraction Creation",
      site_files: [],
      site_lat: "",
      site_lon: "",
      site_excel_name: "",
      site_excel: "",
      site_provider: authContextData.logedUser.provider,
    });
    const [loading, setLoding] = useState(false);
    const [noProvider, setNoProvider] = useState({
        popup: false,
    });
    const [discard, setDiscard] = useState(false);
    const [viewport, setViewport] = useState({
        width: "100%",
        height: "100%",
        latitude: 32.109333,
        longitude: 34.855499,
        zoom: 7,
        padding: 20,
        offset: [0, -100],
        mapboxApiAccessToken: process.env.REACT_APP_MAPBOX_TOKEN,
    });
    const [addressMarkers, setAddressMarkers] = useState([]);
    const [location, setLocation] = useState(false);
    const [addressSearch, setAddressSearch] = useState("");

    const changeHandler = async (event) => {
      await setSelectedFile(event.target.files[0]);
      await setIsSelected(true);
      await setSite({
        ...site,
        site_excel_name: event.target.files[0].name
      });
    };

    const blurValidation = (name, type, value, roles, formStep) => {
        if (validate[type](value, roles).valid) {
          inputs[name] = true;
        } else {
          inputs[name] = false;
        }
    
        return validate[type](value, roles);
    };

    const getBase64 = (file, cb) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
          cb(reader.result)
      };
      reader.onerror = function (error) {
          console.log('Error: ', error);
      };
    }

    const handleChange = (e) => {
      setSite({
        ...site,
        [e.target.name]: e.target.value,
      });
    };

    const handleChangeAddressSearch = (e) => {
        setAddressSearch(e.target.value);
    };

    const handleClick = ({ lngLat: [longitude, latitude] }) => {
        setAddressMarkers([[latitude, longitude]]);
    };

    const createSite = useCallback(async (noProvider) => {
      // if (site.site_provider === "" && authContextData.logedUser.type === 3 && !noProvider) {
      //   return setNoProvider({ ...noProvider, popup: true });
      // }
      if (!selectedFile) return setMsg("Please attach excel file to upload");
      if (!site.site_name) return setMsg("Please enter attraction name as in Excel");
      if (!site.site_company_id) return setMsg("Please enter valid company id");
      if (!site.site_lat || !site.site_lon) return setMsg("Please enter attraction address");

      setLoding(true);
      getBase64(selectedFile, async (result) => {  
        const newSiteState = {
          ...site,
            site_excel: result,
        }
        setSite(newSiteState); 

        const res = await priorityApi.createSiteWithExcel(priorityServices.createSiteExcel(newSiteState));
        if (res.data) {
            let addr_data = {
              site_name: res.data.TEXT,
              site_company_id: res.data.TEXT12,
              site_lat: site.site_lat,
              site_lon: site.site_lon, 
            }

            const site_addr = await priorityApi.createSiteLngLatExcel(priorityServices.createSiteLngLat(addr_data));

            site.site_files.map(async (file) => {
              const fileRes = await priorityApi.addFileToSiteExcel(
                priorityServices.createFileObjExcel(res.data.TEXT12, res.data.TEXT, file), //text12 - het pei, text - name
              );
            });

            dispatchSnackBar({
              type: "SET_SUCCESS",
              payload: "Tourism Site Created Successfully!",
            });
      
            try {
              await api.createLog(site);
            } catch (err) {
              console.log(err);
            }
            setLoding(false);

            // setTimeout(() => {
            //   window.location.reload(false);
            // }, 3000);
        } else {
          dispatchSnackBar({
            type: "SET_ERROR",
            payload: "Something went wrong, please contact support",
          });
          setLoding(false);
        }
      });
    });

    const handleChangeSelectFiles = (data) => {
        setSite({ ...site, site_files: site.site_files.concat(data) });
    };

    const handleChangeMainIMage = (data) => {
      // filter existing main image
      const site_files = site.site_files.filter(file => file.moreData.main !== true);

      //add new image file to site_field array
      setSite({ ...site, site_files: site_files.concat(data) });
    };
    
    const handleChangeRemoveFiles = (data) => {
        setSite({
            ...site,
            site_files: site.site_files.filter((file) => {
            return file.src !== data;
            }),
        });
    };

    const searchAddress = useCallback(async (address) => {
        const res = await api.getGeo(addressSearch);
        if (res.data.length > 0) {
          setAddressMarkers(res.data.map((marker) => [parseFloat(marker.lat), parseFloat(marker.lon)]));
          let latArry = res.data.map((marker) => parseFloat(marker.lat));
          let lonArry = res.data.map((marker) => parseFloat(marker.lon));
          const map = new WebMercatorViewport(viewport).fitBounds(
            [
              [Math.min(...lonArry), Math.min(...latArry)],
              [Math.max(...lonArry), Math.max(...latArry)],
            ],
            {
              padding: 20,
              offset: [0, -100],
            }
          );
          setViewport(map);
        }
        setLoding(false);
    });

    return (
        <div className="FormWithXl">
          {discard ? (
            <div className="popup">
              <div className="content">
                <h2>Are you sure?</h2>
                <p>The information entered will not be saved!</p>
                <div
                  className="btn red"
                  onClick={() => {
                    setDiscard(false);
                  }}
                >
                  No
                </div>
                <div className="btn green" onClick={props.discard}>
                  Yes
                </div>
              </div>
            </div>
          ) : null}
            <form className="step-5">
                <div className="col">
                  <FloatingInput
                    color="orange"
                    label="Tourism Site Name *"
                    value={site.site_name}
                    onChange={handleChange}
                    name="site_name"
                    validation={blurValidation}
                    validationType="text"
                    validationRoles={{ name: "Tourism Site Name", require: false }}
                    formStep="1"
                  />
                </div>
                <div className="col">
                  <FloatingInput
                    color="orange"
                    label="Company ID *"
                    value={site.site_company_id}
                    onChange={handleChange}
                    name="site_company_id"
                    validation={blurValidation}
                    validationType="text"
                    validationRoles={{ name: "Company ID", require: false }}
                    formStep="1"
                  />
                </div>
                <div className="col-3">
                    <h2 className="sub-title">Tourism Site Main Image</h2>
                    <MainImageInput add={handleChangeMainIMage} remove={handleChangeRemoveFiles} value={site.site_files} />
                </div>
                <div className="col-5">
                    <h2 className="sub-title">Tourism Site Images</h2>
                    <ImageInput add={handleChangeSelectFiles} remove={handleChangeRemoveFiles} value={site.site_files} />
                </div>
                <div className="col-5">
                    <h2 className="sub-title">Tourism Site Brochures</h2>
                    <PdfInput add={handleChangeSelectFiles} remove={handleChangeRemoveFiles} value={site.site_files} />
                </div>
                <div className="map">
                    {loading ? (
                        <div className="loader-wrapper">
                        <Loader />
                        </div>
                    ) : null}
                    <InteractiveMap
                        {...viewport}
                        mapStyle="mapbox://styles/theguy/ckev6139w0wz21aofyw2gcsa7"
                        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                        onClick={handleClick}
                        onViewportChange={(nextViewport) => setViewport(nextViewport)}
                    >
                        <NavigationControl />
                        {addressMarkers.map((marker) => {
                        return (
                            <>
                            <Marker latitude={marker[0]} longitude={marker[1]}>
                                <div
                                onClick={() => {
                                    setSite({
                                    ...site,
                                    site_lat: marker[0],
                                    site_lon: marker[1],
                                    });
                                    inputs["site_lat"] = true;
                                    inputs["site_lon"] = true;
                                    setLocation(true);
                                }}
                                >
                                <img src={require("../../assets/images/pin.png").default} />
                                </div>
                            </Marker>
                            </>
                        );
                        })}
                    </InteractiveMap>
                </div>
                <div className="col">
                    <p>
                    If you have the address of the site, please enter it and click Search. After the pins appears on the
                    map, click on the relevant pin, If you do not find the address or do not have an exact address, click
                    on the map on the desired location and then on the pin that appears. (Please be as precise as
                    possible). After clicking the desired pin a green label will appear to confirm your selection.
                    </p>
                    <FloatingInput
                    color="orange"
                    label="Search Address..."
                    onChange={handleChangeAddressSearch}
                    value={addressSearch}
                    name="addressSearch"
                    validation={blurValidation}
                    validationType="any"
                    validationRoles={{ name: "Search", require: false }}
                    formStep="2"
                    />
                    <div
                    className="btn-addresshearch"
                    onClick={(e) => {
                        e.preventDefault();
                        setLoding(true);
                        searchAddress();
                    }}
                    >
                    Search
                    </div>
                    {location ? <div className="location-popup">Tourism Site Location Selected.</div> : null}
                </div>
                <div className="xl-files">
                    {/* <Link class="download" to={xl_blank} target="_blank" download>
                        <p>Please, download file and fill all required fields</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width='64'  viewBox="0 0 512 512">
                          <path d="M480 352h-133.5l-45.25 45.25C289.2 409.3 273.1 416 256 416s-33.16-6.656-45.25-18.75L165.5 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96C512 366.3 497.7 352 480 352zM432 456c-13.2 0-24-10.8-24-24c0-13.2 10.8-24 24-24s24 10.8 24 24C456 445.2 445.2 456 432 456zM233.4 374.6C239.6 380.9 247.8 384 256 384s16.38-3.125 22.62-9.375l128-128c12.49-12.5 12.49-32.75 0-45.25c-12.5-12.5-32.76-12.5-45.25 0L288 274.8V32c0-17.67-14.33-32-32-32C238.3 0 224 14.33 224 32v242.8L150.6 201.4c-12.49-12.5-32.75-12.5-45.25 0c-12.49 12.5-12.49 32.75 0 45.25L233.4 374.6z"/>
                          </svg>
                    </Link> */}
                    <a class="download" href={xl_blank} download="attraction_base_file.xlsx">
                      <p>Please, download file and fill all required fields</p>
                      <svg xmlns="http://www.w3.org/2000/svg" width='64'  viewBox="0 0 512 512">
                        {/* <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --> */}
                        <path d="M480 352h-133.5l-45.25 45.25C289.2 409.3 273.1 416 256 416s-33.16-6.656-45.25-18.75L165.5 352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96C512 366.3 497.7 352 480 352zM432 456c-13.2 0-24-10.8-24-24c0-13.2 10.8-24 24-24s24 10.8 24 24C456 445.2 445.2 456 432 456zM233.4 374.6C239.6 380.9 247.8 384 256 384s16.38-3.125 22.62-9.375l128-128c12.49-12.5 12.49-32.75 0-45.25c-12.5-12.5-32.76-12.5-45.25 0L288 274.8V32c0-17.67-14.33-32-32-32C238.3 0 224 14.33 224 32v242.8L150.6 201.4c-12.49-12.5-32.75-12.5-45.25 0c-12.49 12.5-12.49 32.75 0 45.25L233.4 374.6z"/>
                      </svg>
                    </a>
                    <div className="upload">
                      <label>
                      {!isSelected &&
                          <span>Please, upload filled file here</span> 
                      }
                      {isSelected && 
                        <div className="filename">
                          <p>Filename: {selectedFile.name} <svg xmlns="http://www.w3.org/2000/svg" width="25" viewBox="0 0 512 512">{/* <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --> */}<path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"/></svg> </p>
                        </div>
                      }
                        <svg xmlns="http://www.w3.org/2000/svg" width='64' viewBox="0 0 512 512">{/* <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --> */}<path d="M105.4 182.6c12.5 12.49 32.76 12.5 45.25 .001L224 109.3V352c0 17.67 14.33 32 32 32c17.67 0 32-14.33 32-32V109.3l73.38 73.38c12.49 12.49 32.75 12.49 45.25-.001c12.49-12.49 12.49-32.75 0-45.25l-128-128C272.4 3.125 264.2 0 256 0S239.6 3.125 233.4 9.375L105.4 137.4C92.88 149.9 92.88 170.1 105.4 182.6zM480 352h-160c0 35.35-28.65 64-64 64s-64-28.65-64-64H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h448c17.67 0 32-14.33 32-32v-96C512 366.3 497.7 352 480 352zM432 456c-13.2 0-24-10.8-24-24c0-13.2 10.8-24 24-24s24 10.8 24 24C456 445.2 445.2 456 432 456z"/></svg>
                        <input type="file" name="file" onChange={changeHandler} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
                      </label>
                    </div>
                </div>
              {loading ? (
                <div className="btns">
                  <Loader />
                </div>
              ) : (
                <div className="btns">
                  <div
                    className="btn red"
                    onClick={() => {
                      setDiscard(true);
                    }}
                  >
                    Discard
                  </div>
                  <div
                    className="btn green"
                    onClick={(e) => {
                      e.preventDefault();
                      setNoProvider({ ...noProvider, popup: false });
                      createSite();
                    }}
                  >
                    Create
                  </div>
                </div>
              )}
            </form>
            {msg && <div className="msg" style={{ 
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                color: "#EE4B2B",
                background: "#fff",
                padding: "50px",
                borderRadius: "30px",
              }}>
                  {msg}
            </div>}
        </div>
    );

};
  
 export default FormWithXl;