import "./FloatingDateInput.scss";
import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const FloatingDateInput = (props) => {
  const [float, setFloat] = useState(false);
  const [focused, setFocused] = useState(false);
  const [tooltip, setTooltip] = useState();
  const inputRef = useRef();

  const location = useLocation();

  useEffect(() => {
    setFloat(props.value === "" && !focused ? false : true);
  }, [props.value]);

  return (
    <div className="FloatingDateInput">
      {location.pathname === "/my-orders" ? (
        <></>
      ) : (
        <label
          className={float ? (focused ? `float ${props.color} focused` : `float`) : null}
          onClick={() => {
            setFocused(true);
            return props.value === "" ? setFloat(true) : null;
          }}
        >
          {props.label}
        </label>
      )}
      <DatePicker
        className={props.color}
        selected={props.value}
        onChange={props.onChange}
        name={props.name}
        autoComplete="off"
        ref={inputRef}
        dateFormat="MMMM d, yyyy"
        onFocus={() => {
          setFloat(true);
          setFocused(true);
        }}
        popperPlacement={props.popperPlacement}
        popperModifiers={props.popperModifiers}
        placeholderText={location.pathname === "/my-orders" ? props.label : ""}
        onBlur={(e) => {
          setFloat(props.value == "" ? false : true);
          setFocused(false);
        }}
        onCalendarClose={() => {
          if (props.validation) {
            setTooltip(
              props.validation(props.name, props.validationType, props.value, props.validationRoles, props.formStep)
            );
          }
        }}
      />
      {tooltip?.valid === false ? <div className={"tooltip " + props.direction}>{tooltip?.msg}</div> : null}
    </div>
  );
};

export default FloatingDateInput;
