export const authReducer = (state, action) => {
  switch (action.type) {
    case "DO_LOGIN":
      return action.payload;
    case "DO_LOGOUT":
      return action.payload;
    default:
      return state;
  }
};
