import React, { createContext, useReducer } from "react";
import { authReducer } from "./AuthReducer";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [authContextData, authContextDispatch] = useReducer(authReducer, null);
  return <AuthContext.Provider value={{ authContextData, authContextDispatch }}>{props.children}</AuthContext.Provider>;
};

export default AuthContextProvider;
