import "./FloatingInput.scss";
import React, { useState, useRef, useEffect } from "react";

const FloatingInput = (props) => {
  const [float, setFloat] = useState(false);
  const [focused, setFocused] = useState(false);
  const inputRef = useRef();
  const [tooltip, setTooltip] = useState();
  useEffect(() => {
    setFloat(props.value === "" && !focused ? false : true);
  }, [props.value]);
  return (
    <div className='FloatingInput'>
      <label
        className={float ? (focused ? `float ${props.color} focused` : `float`) : null}
        onClick={() => {
          inputRef.current.focus();
          setFocused(true);
          return props.value === "" ? setFloat(true) : null;
        }}
      >
        {props.label}
      </label>
      <input
        className={props.color}
        ref={inputRef}
        autoComplete='off'
        onChange={props.onChange}
        name={props.name}
        value={props.value}
        placeholder={focused ? props.placeholder : null}
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
        onFocus={() => {
          setFloat(true);
          setFocused(true);
        }}
        onBlur={(e) => {
          setFloat(props.value == "" ? false : true);
          setFocused(false);
          setTooltip(
            props.validation(props.name, props.validationType, props.value, props.validationRoles, props.formStep)
          );
        }}
        disabled={props.disabled}
        type={props.name==='time' ? props.name : 'text'}
      />
      {tooltip?.valid === false ? <div className={"tooltip " + props.direction}>{tooltip?.msg}</div> : null}
    </div>
  );
};

export default FloatingInput;
