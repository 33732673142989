import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { AuthContext } from "../context/AuthContext";

const PrivateRoute = ({ component: Component, provider: Provider, permissions: Permissions, ...otherProps }) => {
  const { authContextData } = useContext(AuthContext);

  return (
    <Route
      {...otherProps}
      render={(props) =>
        authContextData.isAuth ? (
          Permissions.includes(authContextData.logedUser.type) ? (
            Provider ? (
              <Provider>
                <Component {...props} />
              </Provider>
            ) : (
              <Component {...props} />
            )
          ) : (
            <Redirect to="/" />
          )
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PrivateRoute;
