import "./Site.scss";
import React, { useContext } from "react";

import { SiteMngtContext } from "../context/SiteMngtContext";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import * as priorityApi from "../shared/priorityApi";

const Site = (props) => {

  const { SiteMngtContextData, SiteMngtContextDispatch } = useContext(SiteMngtContext);
  const { authContextData } = useContext(AuthContext);
  
  const getImg = () => {
    let img = require("../assets/images/img-ph.png").default;

    if (props.data.ZITA_WEBATTREXTFILE_SUBFORM && props.data.ZITA_WEBATTREXTFILE_SUBFORM.length > 0) {
      const files = props?.data?.ZITA_WEBATTREXTFILE_SUBFORM;
      // console.log(files);
      const fileMailImgs = files?.filter(file=>file?.MAIN === 1);
      // console.log(fileMailImgs);
      if(fileMailImgs.length >= 1) {
        img = fileMailImgs[fileMailImgs.length-1].EXTFILENAME;
      } else {
        img = files[0]?.EXTFILENAME;
        // const file_position = files.findIndex(file => file.MAIN === 1);
        // if(file_position > -1){
        //   img = files[file_position].EXTFILENAME;
        // }else{
        //   img = files[0].EXTFILENAME;
        // }
      }
    }

    return img
  }

  const setActivity = () => {
    SiteMngtContextDispatch({
      type: "SET_SITE",
      payload: { site: { id: props.data.ATTRCODE, name: props.data.ATTRDES }, currentDataType: "activities" },
    });
  };

  const setUpdate = () =>{
    const files = props.data.ZITA_WEBATTREXTFILE_SUBFORM.map(file => ({
      src : file?.EXTFILENAME,
      fileId: file?.EXTFILENUM,
      uploading: false,
      moreData: {
        type: file?.PICTURE === "Y" ? 'img' : 'pdf', 
        main: (file?.MAIN === 1),
        exist: true
      },
    }))

    SiteMngtContextDispatch({
      type: "SET_SITE",
      payload: { 
        currentDataType: "update" ,
        site:{
          id: props.data.ATTRCODE,
          name: props.data.ATTRDES, 
          files
        }
      }
    });
  }

  const getActivityExcel = async () => {
    let data = {

    }
    const res = await priorityApi.getSiteExcel(); 
    // console.log(res);
  }

  // if(props.data?.ATTRDES === "rdsgtr") console.log(props.data)

  return (
    <div className="Site">
      <div className="image">
        <img src={getImg()} alt="tourism sites" />
      </div>
      <div className="details">
        <h2 className="name">{props.data?.ATTRDES}</h2>
        <p
          className="description"
          dangerouslySetInnerHTML={{ __html: props.data.ZITA_WATTRACTIONSTXT_SUBFORM ? props.data.ZITA_WATTRACTIONSTXT_SUBFORM?.TEXT.slice(0, 220)  + "..." : '' }}
        ></p>
      </div>
      <div className="btns">
        <div className="btn" onClick={setActivity}>
          Activities
        </div>
        <div className="btn link">
          <Link to={`/site/${props.data.ATTRCODE}`}>
            View
          </Link>
        </div>
        <div className="btn link" onClick={setUpdate}>
          Update Media
        </div>
      </div>
    </div>
  );
};

export default Site;
