import "./FloatingTextarea.scss";
import React, { useState, useEffect, useRef } from "react";

const FloatingTextarea = (props) => {
  const [float, setFloat] = useState(false);
  const [focused, setFocused] = useState(false);
  const inputRef = useRef();
  const [tooltip, setTooltip] = useState();
  useEffect(() => {
    setFloat(props.value === "" && !focused ? false : true);
  }, [props.value]);
  return (
    <div className="FloatingTextarea">
      <label
        className={float ? (focused ? `float ${props.color} focused` : `float`) : null}
        onClick={() => {
          inputRef.current.focus();
          setFocused(true);
          return props.value === "" ? setFloat(true) : null;
        }}
      >
        {props.label}
      </label>
      <textarea
        className={props.color}
        type="text"
        value={props.value}
        onChange={props.onChange}
        name={props.name}
        onFocus={() => {
          setFloat(true);
          setFocused(true);
        }}
        onBlur={(e) => {
          setFloat(props.value == "" ? false : true);
          setFocused(false);
          setTooltip(
            props.validation(props.name, props.validationType, props.value, props.validationRoles, props.formStep)
          );
        }}
        autoComplete="off"
        ref={inputRef}
      ></textarea>
      {tooltip?.valid === false ? <div className={"tooltip " + props.direction}>{tooltip?.msg}</div> : null}
    </div>
  );
};

export default FloatingTextarea;
