import "./ActivityForm.scss";
import React, { useState, useContext, useCallback, useEffect } from "react";
import Loader from "../Loader";
import FloatingInput from "../inputs/FloatingInput";
import FloatingTextarea from "../inputs/FloatingTextarea";
import FloatingSelectMulti from "../inputs/FloatingSelectMulti";
import BulletInput from "../inputs/BulletInput";
import { AuthContext } from "../../context/AuthContext";
import { SiteMngtContext } from "../../context/SiteMngtContext";
import { SnackBarsContext } from "../../context/SnackBarsContext";
import * as priorityServices from "../../utils/priorityServices";
import * as priorityApi from "../../shared/priorityApi";
import * as validate from "../../utils/validation";
import * as api from "../../shared/api";
import FloatingSelect from "../inputs/FloatingSelect";

const ActivityForm = (props) => {
  const {duplicatedActivity} = props;
  const { authContextData } = useContext(AuthContext);
  const { SiteMngtContextData, SiteMngtContextDispatch } = useContext(SiteMngtContext);
  const { dispatchSnackBar } = useContext(SnackBarsContext);
  const [loading, setLoding] = useState(false);
  const [activity, setActivity] = useState({
    site_id: SiteMngtContextData.site.id,
    activity_name: duplicatedActivity?.ACTDES ? `${duplicatedActivity?.ACTDES} - Duplicated` : "",
    activity_duration: duplicatedActivity?.DURATION || "",
    activity_days: duplicatedActivity?.DAYS?.split(';') || "",
    activity_description: duplicatedActivity?.ZITA_WEBACTTEXT_SUBFORM?.TEXT.replace(/<style[\s\S]*?<\/style>|<.*?>/g, '') || "",
    activity_addition: duplicatedActivity?.ZITA_WEBACTADD_SUBFORM?.map(item=> {return item?.ACTADDDES}) || [],
    activity_cancellation: duplicatedActivity?.ZITA_WEBACTTEXT2_SUBFORM?.TEXT.replace(/<style[\s\S]*?<\/style>|<.*?>/g, '') ||  "",
    // activity_maxparticipate: "",
    // activity_minparticipate: "",
    activity_participates: duplicatedActivity?.MAXNUM?.toString() || "",
    activity_sunday_opening: duplicatedActivity?.SUNSTIME || "",
    activity_sunday_closing: duplicatedActivity?.SUNETIME || "",
    activity_monday_opening: duplicatedActivity?.MONSTIME || "",
    activity_monday_closing: duplicatedActivity?.MONETIME || "",
    activity_tuesday_opening: duplicatedActivity?.TUESTIME || "",
    activity_tuesday_closing: duplicatedActivity?.TUEETIME || "",
    activity_wednesday_opening: duplicatedActivity?.WEDSTIME || "",
    activity_wednesday_closing: duplicatedActivity?.WEDETIME || "",
    activity_thursday_opening: duplicatedActivity?.THUSTIME || "",
    activity_thursday_closing: duplicatedActivity?.THUETIME || "",
    activity_friday_opening: duplicatedActivity?.FRISTIME || "",
    activity_friday_closing: duplicatedActivity?.FRIETIME || "",
    activity_saturday_opening: duplicatedActivity?.SATSTIME || "",
    activity_saturday_closing: duplicatedActivity?.SATETIME || "",
    is_free_code: duplicatedActivity?.F_CODE || null,
  });
  const [stepper, setStepper] = useState({
    current: 1,
    steps: [
      {
        num: 1,
        status: "uncompleted",
        title: "Details",
        inputs: {
          activity_name: "",
          activity_duration: "",
          activity_days: "",
          activity_description: "",
          activity_addition: true,
          activity_cancellation: "",
          activity_participates: "",
          is_free_code: null,
        },
      },
      {
        num: 2,
        status: "uncompleted",
        title: "Working Hours",
        inputs: {
          activity_sunday_opening: "",
          activity_sunday_closing: "",
          activity_monday_opening: "",
          activity_monday_closing: "",
          activity_tuesday_opening: "",
          activity_tuesday_closing: "",
          activity_wednesday_opening: "",
          activity_wednesday_closing: "",
          activity_thursday_opening: "",
          activity_thursday_closing: "",
          activity_friday_opening: "",
          activity_friday_closing: "",
          activity_saturday_opening: "",
          activity_saturday_closing: "",
        },
      },
    ],
  });

  const [validation, setValidation] = useState({
    activity_name: false,
    activity_duration: false,
    activity_days: false,
    activity_description: false,
    activity_addition: true,
    activity_cancellation: false,
    activity_maxparticipate: false,
    activity_minparticipate: false,
    activity_sunday_opening: false,
    activity_sunday_closing: false,
    activity_monday_opening: false,
    activity_monday_closing: false,
    activity_tuesday_opening: false,
    activity_tuesday_closing: false,
    activity_wednesday_opening: false,
    activity_wednesday_closing: false,
    activity_thursday_opening: false,
    activity_thursday_closing: false,
    activity_friday_opening: false,
    activity_friday_closing: false,
    activity_saturday_opening: false,
    activity_saturday_closing: false,
  });

  const [closedDays, setClosedDays] = useState([]);

  const handleChange = (e) => {
    // console.log('e', e);
    setActivity({
      ...activity,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeCustom = (key, value) => {
    let prev_activity = {...activity};
    prev_activity[`${key}`] = value;
    setActivity(prev_activity);
  }

  const handleChangeUl = (item) => {
    let newData = [...activity.activity_addition];
    newData.push(item);
    setActivity({
      ...activity,
      activity_addition: newData,
    });
    setValidation({ ...validation, activity_addition: true });
    let inputs = { ...stepper.steps[0].inputs };
    inputs.activity_addition = true;
    let newStemps = [...stepper.steps];
    newStemps[0].inputs = inputs;
    setStepper({ ...stepper, steps: newStemps });
  };

  const removeUl = (index) => {
    let newData = [...activity.activity_addition];
    newData.splice(index, 1);
    setActivity({
      ...activity,
      activity_addition: newData,
    });
    if (newData.length === 0) {
      setValidation({ ...validation, activity_addition: false });
      let inputs = { ...stepper.steps[0].inputs };
      inputs.activity_addition = "";
      let newStemps = [...stepper.steps];
      newStemps[0].inputs = inputs;
      setStepper({ ...stepper, steps: newStemps });
    }
  };

  const handleChangeSelectMulti = (value, name) => {
    if(name === 'activity_days'){
      const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
      const open_days = value.map((x) => x.label.toLowerCase()); 
      const closed_days = days.filter(day=>!open_days.includes(day));

      const activityToUpdate = {...activity};
      closed_days.forEach(day=>{
        activityToUpdate[`activity_${day}_opening`] = '00:00';
        activityToUpdate[`activity_${day}_closing`] = '00:00';
      })
      open_days.forEach(day=>{
        activityToUpdate[`activity_${day}_opening`] = '';
        activityToUpdate[`activity_${day}_closing`] = '';
      })
      setActivity({
        ...activityToUpdate,
        [name]: value ? value.map((x) => x.value) : [],
      });
      setClosedDays(closed_days);

      const prevStepper = {...stepper};
      const newInputsObj = {};
      closed_days.forEach(day=>{
        newInputsObj[`activity_${day}_opening`] = true;
        newInputsObj[`activity_${day}_closing`] = true;
      })
      prevStepper.steps[1] = {... prevStepper.steps[1], inputs: newInputsObj}
      setStepper(prevStepper);
      return
    }
    setActivity({
      ...activity,
      [name]: value ? value.map((x) => x.value) : [],
    });
  };

  const blurValidation = (name, type, value, roles, formStep) => {
    let inputs = { ...stepper.steps[formStep - 1].inputs };
    if (validate[type](value, roles).valid) {
      inputs[name] = true;
      setValidation({ ...validation, [name]: true });
    } else {
      inputs[name] = false;
      setValidation({ ...validation, [name]: false });
    }
    let newStemps = [...stepper.steps];
    newStemps[formStep - 1].inputs = inputs;
    setStepper({ ...stepper, steps: newStemps });
    return validate[type](value, roles);
  };
  
  const nextStep = (formnum) => {
    let inputs = { ...stepper.steps[formnum - 1].inputs };
    for (const name in inputs) {
      if (!inputs[name]) {
        return dispatchSnackBar({
          type: "SET_ERROR",
          payload: "Please complate all fields.",
        });
      }
    }

    let steps = [...stepper.steps];
    steps[formnum - 1].status = "completed";
    setStepper({ ...stepper, current: formnum + 1, steps: steps });
  };

  const prevStep = (formnum) => {
    let steps = [...stepper.steps];
    steps[formnum - 1].status = "uncompleted";
    steps[formnum - 2].status = "uncompleted";
    setStepper({ ...stepper, current: formnum - 1, steps: steps });
  };

  const createActivty = useCallback(async () => {
    let inputs = { ...stepper.steps[1].inputs };

    for (var name in inputs) {
      if (!inputs[name]) {
        return dispatchSnackBar({
          type: "SET_ERROR",
          payload: "Please complate all fields.",
        });
      }
    }
    setLoding(true); 
    const res = await priorityApi.createActivity(priorityServices.createAactivityObj(activity));

    if (res.data) {
      const cancellation_res = await priorityApi.addAactivityCancellation(
        priorityServices.createAactivityCancellationObj(
          SiteMngtContextData.site.id,
          res.data.INT3,
          activity.activity_cancellation
        )
      );

      const addition_res = await Promise.all(activity.activity_addition.map(async (line) => {
        return priorityApi.addAactivityAddition(
          priorityServices.createAactivityAdditionObj(SiteMngtContextData.site.id, res.data.INT3, line),
        );
      }));

      await api.createLog(activity);

      dispatchSnackBar({
        type: "SET_SUCCESS",
        payload: "Activity Created Successfully!",
      });
      setLoding(false);

      props.addNewData({
        ACT: res.data.INT1,
        ACTDES: res.data.TEXT1,
        DAYS: res.data.TEXT11,
        DURATION: res.data.TEXT12,
        //DAYS START/END
        SUNSTIME: res.data.TEXT13,
        SUNETIME: res.data.TEXT14,
        MONSTIME: res.data.TEXT15,
        MONETIME: res.data.TEXT16,
        TUESTIME: res.data.TEXT17,
        TUEETIME: res.data.TEXT18,
        WEDSTIME: res.data.TEXT19,
        WEDETIME: res.data.TEXT20,
        THUSTIME: res.data.TEXT21,
        THUETIME: res.data.TEXT22,
        FRISTIME: res.data.TEXT23,
        FRIETIME: res.data.TEXT24,
        SATSTIME: res.data.TEXT25,
        SATETIME: res.data.TEXT26,
        FULLDES: `${res.data.TEXT1} ${SiteMngtContextData.site.NAME}`,
        KLINE: res.data.INT3,
        MAXNUM: res.data.INT11,
        MINNUM: res.data.INT10,
        NOTACTIVE: null,
        ZITA_WEBACTADD_SUBFORM: addition_res.map((item, key)=> {return {KLINE: key+1, ACTADDDES: item.data.TEXT}}),
        ZITA_WEBACTTEXT_SUBFORM: priorityServices.createAactivityObj(activity).ZCOR_GENLOADLOGTEXT_SUBFORM,
        ZITA_WEBACTTEXT2_SUBFORM: {...cancellation_res.data.ZCOR_GENLOADLOGTEXT_SUBFORM},
        ZITA_WEBACTPLS_SUBFORM: [],
        F_CODE: res.data.TEXT33,
      });
      props.discard();
    }
  });


  useEffect(() => {
    duplicatedActivity && setStepper({
      current: 1,
      steps: [
        {
          num: 1,
          status: "completed",
          title: "Details",
          inputs: {
            activity_name: duplicatedActivity?.ACTDES ? true : "",
            activity_duration: duplicatedActivity?.DURATION ? true : "",
            activity_days: duplicatedActivity?.DAYS ? true : "",
            activity_description: duplicatedActivity?.ZITA_WEBACTTEXT_SUBFORM?.TEXT.replace(/<.*?>/g, '') ? true : "",
            activity_addition: duplicatedActivity?.ZITA_WEBACTADD_SUBFORM?.length > 0 ? true : "",
            activity_cancellation: duplicatedActivity?.ZITA_WEBACTTEXT2_SUBFORM?.TEXT.replace(/<.*?>/g, '') ? true : "",
            activity_participates: duplicatedActivity?.MAXNUM ? true : "",
            is_free_code: duplicatedActivity?.F_CODE ? duplicatedActivity?.F_CODE : null
          },
        },
        {
          num: 2,
          status: "completed",
          title: "Working Hours",
          inputs: {
            activity_sunday_opening: duplicatedActivity?.SUNSTIME ? true : "",
            activity_sunday_closing: duplicatedActivity?.SUNETIME ? true : "",
            activity_monday_opening: duplicatedActivity?.MONSTIME ? true : "",
            activity_monday_closing: duplicatedActivity?.MONETIME ? true : "",
            activity_tuesday_opening: duplicatedActivity?.TUESTIME ? true : "",
            activity_tuesday_closing: duplicatedActivity?.TUEETIME ? true : "",
            activity_wednesday_opening: duplicatedActivity?.WEDSTIME ? true : "",
            activity_wednesday_closing: duplicatedActivity?.WEDETIME ? true : "",
            activity_thursday_opening: duplicatedActivity?.THUSTIME ? true : "",
            activity_thursday_closing: duplicatedActivity?.THUETIME ? true : "",
            activity_friday_opening: duplicatedActivity?.FRISTIME ? true : "",
            activity_friday_closing: duplicatedActivity?.FRIETIME ? true : "",
            activity_saturday_opening: duplicatedActivity?.SATSTIME ? true : "",
            activity_saturday_closing: duplicatedActivity?.SATETIME ? true : "",

          },
        },
      ],
    });
  }, [duplicatedActivity])

  // console.log('activity', activity);
  

  return (
    <div className="ActivityForm">
      <div className="container">
        <div className="content">
          <h1 className="title">New Activity</h1>
          <div className="stepper">
            {stepper?.steps.map((step) => {
              return (
                <div className="step">
                  <div
                    className={
                      stepper.current === step.num
                        ? "num current"
                        : step.status === "completed"
                        ? "num completed"
                        : "num"
                    }
                  >
                    {step.status === "completed" ? "✔" : step.num}
                  </div>
                  <div className="text">{step.title}</div>
                </div>
              );
            })}
          </div>
          <p className="">
            *All data must be entered in english. If the activity is closed on a particular day it must be entered on
            that day as 00:00.
          </p>
          {stepper?.current === 1 ? (
            <form className="step-1">
              <div className="col">
                <FloatingInput
                  color="orange"
                  label="Activity Name *"
                  value={activity.activity_name}
                  onChange={handleChange}
                  name="activity_name"
                  validation={blurValidation}
                  validationType="text"
                  validationRoles={{ name: "Activity Name", require: true }}
                  formStep="1"
                />
                <FloatingTextarea
                  color="orange"
                  label="Description *"
                  value={activity.activity_description}
                  placeholder="00:00"
                  onChange={handleChange}
                  name="activity_description"
                  validation={blurValidation}
                  validationType="text"
                  validationRoles={{ name: "Description", require: true }}
                  formStep="1"
                />
                <BulletInput
                  value={activity.activity_addition}
                  addItem={(item) => {
                    handleChangeUl(item);
                  }}
                  removeItem={(index) => {
                    removeUl(index);
                  }}
                />
              </div>
              <div className="col">
                <FloatingInput
                  color="orange"
                  label="Duration *"
                  placeholder="00:00"
                  value={activity.activity_duration}
                  onChange={handleChange}
                  name="activity_duration"
                  validation={blurValidation}
                  validationType="time"
                  validationRoles={{ name: "Duration", require: true }}
                  formStep="1"
                  direction="left"
                />
                <FloatingSelectMulti
                  color="#93c840"
                  label="Activity Days *"
                  selectValue={activity.activity_days}
                  onChange={(value) => {
                    handleChangeSelectMulti(value, "activity_days");
                  }}
                  placeholder=""
                  name="activity_days"
                  options={[
                    { value: "Sun", label: "Sunday" },
                    { value: "Mon", label: "Monday" },
                    { value: "Tue", label: "Tuesday" },
                    { value: "Wed", label: "Wednesday" },
                    { value: "Thu", label: "Thursday" },
                    { value: "Fri", label: "Friday" },
                    { value: "Sat", label: "Saturday" },
                  ]}
                  validation={blurValidation}
                  validationType="multi"
                  validationRoles={{ name: "Activity Days", require: true, min: 1 }}
                  formStep="1"
                  direction="left"
                />
                <FloatingSelect
                  color="#93c840"
                  label="Participants *"
                  selectValue={activity.activity_participates}
                  onChange={(e)=>{handleChangeCustom('activity_participates', e.value)}}
                  placeholder=""
                  name="activity_participates"
                  options={[
                    { value: '10', label: "Up to 10 person" },
                    { value: '50', label: "Up to 50 person" },
                    { value: '150', label: "51 - 150 person" },
                    { value: '500', label: "More than 150 person" },
                  ]}
                  validation={blurValidation}
                  validationType="text"
                  validationRoles={{ name: "Participants", require: true, min: 1 }}
                  formStep="1"
                  direction="left"
                />
                <FloatingSelect
                  color="#93c840"
                  label="Activity Payment Details *"
                  selectValue={activity.is_free_code}
                  onChange={(e)=>{handleChangeCustom('is_free_code', e.value)}}
                  placeholder=""
                  name="is_free_code"
                  options={[
                    { value: null, label: "Paid" },
                    { value: '01', label: "Free (No reservation)" },
                    { value: '02', label: "Free (With reservation)" },
                  ]}
                  validation={blurValidation}
                  validationType="text"
                  validationRoles={{ name: "Participants", require: true, min: 1 }}
                  formStep="1"
                  direction="left"
                />

                <FloatingTextarea
                  color="orange"
                  label="Cancellation and Booking Conditions *"
                  value={activity.activity_cancellation}
                  onChange={handleChange}
                  name="activity_cancellation"
                  validation={blurValidation}
                  validationType="text"
                  validationRoles={{ name: "Cancellation and Booking Conditions", require: true, min: 10, max: 255 }}
                  direction="left"
                  formStep="1"
                />
              </div>
              <div className="btns">
                <div className="btn red" onClick={props.discard}>
                  Discard
                </div>
                <div
                  className="btn gray"
                  onClick={(e) => {
                    e.preventDefault();
                    nextStep(1);
                  }}
                >
                  Next
                </div>
              </div>
            </form>
          ) : null}
          {stepper?.current === 2 ? (
            <form className="step-2">
              <div className="col">
                <label>Sunday</label>
                <label>Monday</label>
                <label>Tuesday</label>
                <label>Wednesday</label>
                <label>Thursday</label>
                <label>Friday</label>
                <label>Saturday</label>
              </div>
              <div className="col">
                <FloatingInput
                  color="orange"
                  label="Opening *"
                  placeholder="00:00"
                  value={activity.activity_sunday_opening}
                  onChange={handleChange}
                  name="activity_sunday_opening"
                  validation={blurValidation}
                  validationType="time"
                  validationRoles={{ name: "Opening", require: true }}
                  formStep="2"
                  disabled={closedDays.includes('sunday')}
                />
                <FloatingInput
                  color="orange"
                  label="Opening *"
                  placeholder="00:00"
                  value={activity.activity_monday_opening}
                  onChange={handleChange}
                  name="activity_monday_opening"
                  validation={blurValidation}
                  validationType="time"
                  validationRoles={{ name: "Opening", require: true }}
                  formStep="2"
                  disabled={closedDays.includes('monday')}
                />
                <FloatingInput
                  color="orange"
                  label="Opening *"
                  placeholder="00:00"
                  value={activity.activity_tuesday_opening}
                  onChange={handleChange}
                  name="activity_tuesday_opening"
                  validation={blurValidation}
                  validationType="time"
                  validationRoles={{ name: "Opening", require: true }}
                  formStep="2"
                  disabled={closedDays.includes('tuesday')}
                />
                <FloatingInput
                  color="orange"
                  label="Opening *"
                  placeholder="00:00"
                  value={activity.activity_wednesday_opening}
                  onChange={handleChange}
                  name="activity_wednesday_opening"
                  validation={blurValidation}
                  validationType="time"
                  validationRoles={{ name: "Opening", require: true }}
                  formStep="2"
                  disabled={closedDays.includes('wednesday')}
                />
                <FloatingInput
                  color="orange"
                  label="Opening *"
                  placeholder="00:00"
                  value={activity.activity_thursday_opening}
                  onChange={handleChange}
                  name="activity_thursday_opening"
                  validation={blurValidation}
                  validationType="time"
                  validationRoles={{ name: "Opening", require: true }}
                  formStep="2"
                  disabled={closedDays.includes('thursday')}
                />
                <FloatingInput
                  color="orange"
                  label="Opening *"
                  placeholder="00:00"
                  value={activity.activity_friday_opening}
                  onChange={handleChange}
                  name="activity_friday_opening"
                  validation={blurValidation}
                  validationType="time"
                  validationRoles={{ name: "Opening", require: true }}
                  formStep="2"
                  disabled={closedDays.includes('friday')}
                />
                <FloatingInput
                  color="orange"
                  label="Opening *"
                  placeholder="00:00"
                  value={activity.activity_saturday_opening}
                  onChange={handleChange}
                  name="activity_saturday_opening"
                  validation={blurValidation}
                  validationType="time"
                  validationRoles={{ name: "Opening", require: true }}
                  formStep="2"
                  disabled={closedDays.includes('saturday')}
                />
              </div>
              <div className="col">
                <FloatingInput
                  color="orange"
                  label="Closing *"
                  placeholder="00:00"
                  value={activity.activity_sunday_closing}
                  onChange={handleChange}
                  name="activity_sunday_closing"
                  validation={blurValidation}
                  validationType="time"
                  validationRoles={{ name: "Closing", require: true }}
                  formStep="2"
                  direction="left"
                  disabled={closedDays.includes('sunday')}
                />
                <FloatingInput
                  color="orange"
                  label="Closing *"
                  placeholder="00:00"
                  value={activity.activity_monday_closing}
                  onChange={handleChange}
                  name="activity_monday_closing"
                  validation={blurValidation}
                  validationType="time"
                  validationRoles={{ name: "Closing", require: true }}
                  formStep="2"
                  direction="left"
                  disabled={closedDays.includes('monday')}
                />
                <FloatingInput
                  color="orange"
                  label="Closing *"
                  placeholder="00:00"
                  value={activity.activity_tuesday_closing}
                  onChange={handleChange}
                  name="activity_tuesday_closing"
                  validation={blurValidation}
                  validationType="time"
                  validationRoles={{ name: "Closing", require: true }}
                  formStep="2"
                  direction="left"
                  disabled={closedDays.includes('tuesday')}
                />
                <FloatingInput
                  color="orange"
                  label="Closing *"
                  placeholder="00:00"
                  value={activity.activity_wednesday_closing}
                  onChange={handleChange}
                  name="activity_wednesday_closing"
                  validation={blurValidation}
                  validationType="time"
                  validationRoles={{ name: "Closing", require: true }}
                  formStep="2"
                  direction="left"
                  disabled={closedDays.includes('wednesday')}
                />
                <FloatingInput
                  color="orange"
                  label="Closing *"
                  placeholder="00:00"
                  value={activity.activity_thursday_closing}
                  onChange={handleChange}
                  name="activity_thursday_closing"
                  validation={blurValidation}
                  validationType="time"
                  validationRoles={{ name: "Closing", require: true }}
                  formStep="2"
                  direction="left"
                  disabled={closedDays.includes('thursday')}
                />
                <FloatingInput
                  color="orange"
                  label="Closing *"
                  placeholder="00:00"
                  value={activity.activity_friday_closing}
                  onChange={handleChange}
                  name="activity_friday_closing"
                  validation={blurValidation}
                  validationType="time"
                  validationRoles={{ name: "Closing", require: true }}
                  formStep="2"
                  direction="left"
                  disabled={closedDays.includes('friday')}
                />
                <FloatingInput
                  color="orange"
                  label="Closing *"
                  placeholder="00:00"
                  value={activity.activity_saturday_closing}
                  onChange={handleChange}
                  name="activity_saturday_closing"
                  validation={blurValidation}
                  validationType="time"
                  validationRoles={{ name: "Closing", require: true }}
                  formStep="2"
                  direction="left"
                  disabled={closedDays.includes('saturday')}
                />
              </div>
              {loading ? (
                <div className="btns">
                  <Loader />
                </div>
              ) : (
                <div className="btns">
                  <div className="btn red" onClick={props.discard}>
                    Discard
                  </div>
                  <div
                    className="btn gray"
                    onClick={(e) => {
                      e.preventDefault();
                      prevStep(2);
                    }}
                  >
                    Back
                  </div>
                  <div
                    className="btn green"
                    onClick={(e) => {
                      e.preventDefault();
                      createActivty();
                    }}
                  >
                    Create
                  </div>
                </div>
              )}
            </form>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ActivityForm;
