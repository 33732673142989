import React, { useContext, useCallback, useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import * as api from "../shared/api";
import * as priorityApi from "../shared/priorityApi";
import { AuthContext } from "../context/AuthContext";
import { SnackBarsContext } from "../context/SnackBarsContext";
import Loader from "../components/Loader";
import "./MyOrders.scss";
import FloatingDateInput from "../components/inputs/FloatingDateInput";
import OrderItem from "../components/OrderItem";
import OrderFullInfo from "../components/OrderFullInfo";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import moment from "moment";

const MyOrders = () => {
  const history = useHistory();
  const { dispatchSnackBar } = useContext(SnackBarsContext);
  const { authContextData } = useContext(AuthContext);
  const [report, setReport] = useState({
    display: false,
    name: authContextData?.logedUser.name,
    email: authContextData?.logedUser.email,
    message: "",
  });
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [currentOrderData, setCurrentOrderData] = useState({});
  const [isLoader, setLoader] = useState(true);

  //print
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = useRef(null);

  //  === SEND A REPORT === //
  const sendReport = useCallback(async () => {
    setLoader(true);
    const res = await api.report(report);
    if (res.data.success) {
      setReport({ ...report, display: false, content: "" });
      dispatchSnackBar({
        type: "SET_SUCCESS",
        payload: "Report Send Successfully!",
      });
      setLoader(false);
    } else {
      setReport({ ...report, display: false, content: "" });
      setLoader(false);
      return dispatchSnackBar({
        type: "SET_ERROR",
        payload: "Something went wrong please contact support",
      });
    }
  });

  const handleGetOrders = async () => {
    const res = await priorityApi.getUserOrders(authContextData.priorityUserData.CUSTNAME, authContextData.priorityUserData.USERNAME);
    if(res && res.data){
      const tmpData = res.data.value.filter(order => order?.ZITA_USERNAME === authContextData?.logedUser.email)?.sort((a, b) => {
        const dateA = moment(a.STATUSDATE);
        const dateB = moment(b.STATUSDATE);
      
        // Compare the dates in descending order
        return dateB - dateA;
      });

      setOrders(tmpData);
      setFilteredOrders(tmpData);
      setLoader(false);
    }
  }

  const handleOrderClick = (order) => {
    setCurrentOrderData(order);
  }

  const handleFilterOrders = () => {
    setLoader(true);
    if (fromDate && toDate) {
      setFilteredOrders(orders.filter(order => new Date(order.STATUSDATE) >= fromDate && new Date(order.STATUSDATE) <= toDate));
    } else if (toDate) {
      setFilteredOrders(orders.filter(order => new Date(order.STATUSDATE) <= toDate));
    } else {
      dispatchSnackBar({
        type: "SET_ERROR",
        payload: "You haven't chosen any date criteria for filtering.",
      });
    }
    setCurrentOrderData({})
    setLoader(false);
  };

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handleOnBeforeGetContent = useCallback(() => {
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        resolve();
      }, 200);
    });
  }, [setLoader]);

  const handleAfterPrint = React.useCallback(() => {
    // console.log("`onAfterPrint` called"); // tslint:disable-line no-console
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    // console.log("`onBeforePrint` called"); // tslint:disable-line no-console
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: "AwesomeFileName",
    onBeforeGetContent: handleOnBeforeGetContent,
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
    copyStyles: true
  });

  useEffect(() => {
    authContextData?.priorityUserData?.CUSTNAME && handleGetOrders();
  }, [authContextData])

  
  return (
    <>
      <section className="MyOrders">
        {report.display ? (
          <div className="report-popup">
            <div className="popup-container">
              <form>
                <h2 className="title">Report</h2>
                <textarea
                  value={report.message}
                  name="report"
                  onChange={(e) => {
                    setReport({ ...report, message: e.target.value });
                  }}
                ></textarea>
                {isLoader ? (
                  <div className="btns">
                    <Loader />
                  </div>
                ) : (
                  <div className="btns">
                    <div className="btn orange" onClick={sendReport}>
                      Report
                    </div>
                    <div
                      className="btn red"
                      onClick={() => {
                        setReport({ ...report, display: false, content: "" });
                      }}
                    >
                      Discard
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        ) : null}

        <div className="container">
          <div className="top-bar">
            <div className='btns-wrapper'>
              <div
                className="btn btn-blue"
                onClick={() => {
                  history.push("../map");
                }}
              >
                <img src={require("../assets/images/back-arrow-icon.png").default} alt="" />
                Back
              </div>
              {/* <div
                className="btn btn-yellow"
                onClick={() => {
                  setReport({ ...report, display: true });
                }}
              >
                <img src={require("../assets/images/report.png").default} alt="" />
                Report
              </div> */}
            </div>
            <div className="search-filters">
                <FloatingDateInput
                  color="orange"
                  label="From Date"
                  value={fromDate}
                  name="from_date"
                  validationType="date"
                  validationRoles={{ name: "Date", require: false }}
                  direction="left"
                  onChange={(e)=> setFromDate(e)}
                />
                <FloatingDateInput
                  color="orange"
                  label="To Date"
                  value={toDate}
                  name="to_date"
                  validationType="date"
                  validationRoles={{ name: "Date", require: false }}
                  direction="left"
                  onChange={(e)=>setToDate(e)}
                />
                <div className="btn btn-orange" onClick={handleFilterOrders}>
                  <img src={require("../assets/images/search.svg").default} alt="" />
                  Search
                </div>

                <div className="btn btn-blue" onClick={()=>{setFilteredOrders(orders); setCurrentOrderData({})}}>
                  {/* <img src={require("../assets/images/back-arrow-icon.png").default} alt="" /> */}
                  Show All
                </div>
            </div>
          </div>
          <div className="main-area">
            <div className="orders-list">
                {
                  isLoader && 
                  <div className='abs-loader-div'>
                    <Loader/>
                  </div>
                }
                {
                  filteredOrders.length === 0 && 
                  <div className='abs-loader-div'>
                    No orders found
                  </div>
                }
                <div className='orders-wrapper'>
                  {
                    filteredOrders.length > 0 && filteredOrders.map((order, key)=>(
                      <OrderItem
                        order_data = {order}
                        id={key}
                        key={order.ORD}
                        handlerOnClick={handleOrderClick}
                      />
                    ))
                  }
                </div>
                
            </div>
            <div className="orders-details">
                {
                  Object.keys(currentOrderData).length > 0 ? 
                  <>
                    <OrderFullInfo order_data={currentOrderData} ref={componentRef} /> 
                    <button className='abs print-order btn btn-blue' onClick={handlePrint}>Print this out!</button>
                  </>
                  :
                  <img className="placeholder-img" src={require("../assets/images/footer-top.png").default} alt="Infocenter Israel Travel" />
                }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyOrders;
