import "./Input.scss";
import React, { useState } from "react";

const Input = (props) => {
  const [tooltip, setTooltip] = useState();
  return (
    <div className="Input">
      {props.validationType ? (
        <input
          type={props.type}
          placeholder={props.placeholder}
          name={props.name}
          className={props.color}
          value={props.value}
          onChange={props.onChange}
          onBlur={(e) => {
            setTooltip(props.onBlur(e, props.validationType));
          }}
        />
      ) : (
        <input
          type={props.type}
          placeholder={props.placeholder}
          name={props.name}
          className={props.color}
          value={props.value}
          onChange={props.onChange}
        />
      )}
      {tooltip?.valid === false ? <div className="tooltip">{tooltip?.msg}</div> : null}
    </div>
  );
};

export default Input;
