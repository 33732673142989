import React, { createContext, useReducer } from "react";
import { SnackBarReducer } from "./SnackBarsReducer";

export const SnackBarsContext = createContext();

const SnackBarsContextProvider = (props) => {
  const [snackBar, dispatchSnackBar] = useReducer(SnackBarReducer, {
    class: "SnackBars",
    msg: "",
  });
  return <SnackBarsContext.Provider value={{ snackBar, dispatchSnackBar }}>{props.children}</SnackBarsContext.Provider>;
};

export default SnackBarsContextProvider;
