import "./BulletInput.scss";
import React, { useState } from "react";

const BulletInput = (props) => {
  const [text, setText] = useState("");
  const handleChange = (e) => {
    setText(e.target.value);
  };
  return (
    <div className="BulletInput">
      <div className="bullets">
        <ul>
          {props.value.length !== 0 ? (
            props.value.map((line, index) => {
              return (
                <li key={index}>
                  {line}
                  <div
                    className="delete"
                    onClick={() => {
                      props.removeItem(index);
                    }}
                  >
                    X
                  </div>
                </li>
              );
            })
          ) : (
            <div className="placeholder">
              No Extras, Please Add Below! <br />
              <strong>Up To 120 Chars Per line.</strong>
            </div>
          )}
        </ul>
      </div>
      <div className="input-wrapper">
        <input type="text" placeholder="Free Text" onChange={handleChange} value={text} />
        <div
          className="btn"
          onClick={() => {
            if (text.length === 0) {
            } else {
              props.addItem(text);
              setText("");
            }
          }}
        >
          Add
        </div>
      </div>
    </div>
  );
};

export default BulletInput;
