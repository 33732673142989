import React, { useState, useContext, useEffect, useRef } from 'react'
import { AuthContext } from '../../context/AuthContext';
import { SiteMngtContext } from '../../context/SiteMngtContext';
import {ReactComponent as RemoveIcon} from '../../assets/images/close.svg'; 
import * as priorityApi from "../../shared/priorityApi";
import * as priorityServices from "../../utils/priorityServices";
import { SnackBarsContext } from '../../context/SnackBarsContext';
import Loader from '../Loader';
import * as api from "../../shared/api";
import FloatingSelect from '../inputs/FloatingSelect';
import * as validate from "../../utils/validation";

//who the hell dit this?? but i've copied 😂
const url = "https://infocenter.israeltravel.co.il/uploads/";
// const url = "http://localhost:3009/uploads/";


const containerStyle = {
  display: 'grid', 
  minHeight:'50%', 
  gridTemplateColumns: 'repeat(2, 1fr)', 
  gridTemplateRows: 'repeat(2, 1fr)', 
  gap: '2%', 
  padding: '12px', 
  placeItems:'center',
}

const buttonsStyle = {
  textAalign: 'center',
  padding: '10px 40px',
  border: 'none',
  borderRadius: '30px',
  color:'#fff',
  display: 'inline-block',
  cursor:'pointer',
}

function FormUpdate({filteredData, setFilteredData}) {
  const { dispatchSnackBar } = useContext(SnackBarsContext);
  const { SiteMngtContextData, SiteMngtContextDispatch } = useContext(SiteMngtContext);

  const site_id = useRef(0);
  const [files, setFiles] = useState(SiteMngtContextData.site.files); 
  const [newlyUploadedImgs, setNewlyUploadedImgs] = useState([]); 
  const [newlyUploadedPdfs, setNewlyUploadedPdfs] = useState([]); 
  const [fileIdsToDelete, setFileIdsToDelete] = useState([]); 
  const [mainImg, setMainImg] = useState(SiteMngtContextData.site.files.find(file=>file.moreData.main));
  const [morePopup, setMorePopup] = useState({
    isShow: false,
    pdf: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  // console.log(SiteMngtContextData.site)
  
  const handleUpdateFiles = async () => {
    setIsLoading(true);
    try {
      const uploadedFiles = [];

      if(fileIdsToDelete.length){
        for (const id of fileIdsToDelete) {
          await priorityApi.deletingExistingFile(id, site_id.current);
        } 
      }

      //for Main Image
      if(mainImg?.isNew){
        const mainImgUploaded = await api.uploadFile(mainImg.file);
        if (!mainImgUploaded.data.success) return dispatchSnackBar({
          type: "SET_ERROR",
          payload: "Main image upload error, please contact support"
        });
        const updatedMainImg = await priorityApi.addFileToSite(
          priorityServices.createFileObj(site_id.current, {
            src : url + mainImgUploaded.data.file,
            moreData: {
              type: "img",
              main: true,
              for: '',
              language: ''
            } 
          })
        );
        if(updatedMainImg?.data) uploadedFiles.push({...updatedMainImg.data, isMain: true, isPdf: true});
      }

      // rest of imgs
      if(newlyUploadedImgs.length){
        for (const img of newlyUploadedImgs) {
          const imgUploaded = await api.uploadFile(img.file);
          if (!imgUploaded.data.success) return dispatchSnackBar({
            type: "SET_ERROR",
            payload: "Image upload error, please contact support"
          });

          const res = await priorityApi.addFileToSite(
            priorityServices.createFileObj(site_id.current, {
              src : url + imgUploaded.data.file,
              moreData: {
                type: "img",
                main: false,
                for: '',
                language: ''
              }
            })
          );

          if(res?.data) uploadedFiles.push({...res.data, isMain: false, isPdf: false});
        }
      }
      // pdfs
      if(newlyUploadedPdfs.length){
        for (const pdf of newlyUploadedPdfs) {
          const pdfUploaded = await api.uploadFile(pdf.file);
          if (!pdfUploaded.data.success) return dispatchSnackBar({
            type: "SET_ERROR",
            payload: "Image upload error, please contact support"
          });

          const res = await priorityApi.addFileToSite(
            priorityServices.createFileObj(site_id.current, {
              src : url + pdfUploaded.data.file,
              moreData: {
                type: "pdf",
                main: false,
                for: pdf.for,
                language: pdf.language
              }
            })
          );

          if(res?.data) uploadedFiles.push({...res.data, isMain: false, isPdf: true});
        }
      }

      //Show updated
      const prevData = [...filteredData];
      const foundUpdatedSite = prevData.find(item=> +item?.ATTRCODE === +site_id.current);
      if(!foundUpdatedSite) return;
      
      //delete files that were deleted from attraction data, 
      if (fileIdsToDelete.length) {
        foundUpdatedSite.ZITA_WEBATTREXTFILE_SUBFORM = foundUpdatedSite.ZITA_WEBATTREXTFILE_SUBFORM.filter(item => !fileIdsToDelete.includes(item?.EXTFILENUM));
      }

      // add what was uploaded
      if(uploadedFiles.length){
        uploadedFiles.map(file => {
          foundUpdatedSite.ZITA_WEBATTREXTFILE_SUBFORM.push({
            BROCHURE: file?.isPdf ? "Y" : null,
            CURDATE: file?.INDATE,
            EXCEL: null,
            EXTFILEDES: file?.TEXT7,
            EXTFILENAME: file?.EXTFILENAME,
            EXTFILENUM: file?.INT1,
            MAIN: file?.isMain ? 1 : null,
            PICTURE: !file?.isPdf ? "Y" : null,
            TYPE: {}
          })
        })
      }

      setFilteredData(prevData);

      dispatchSnackBar({
        type: "SET_SUCCESS",
        payload: "Site successfully updated",
      });

      SiteMngtContextDispatch({
        type: "SET_SITE",
        payload: { 
          currentDataType: "sites",
        }
      })
    } catch (error) {
      // Handle errors here
    } finally {
      setIsLoading(false);
    }
  };

  const checkFilesSize = (files) => {
        //taken from yosef or remus or whatever
        let is_large_file = {
          name: '',
          status: false
        };
    
        if(files.length === 0) return;
    
        for (let i = 0; i < files.length; i++) {
          let file = files.item(i);
          if (file.size / 1024 / 1024 > 5){
            is_large_file.name = file.name;
            is_large_file.status = true;
          } 
        }
    
        if(is_large_file.status) return dispatchSnackBar({
                            type: "SET_ERROR",
                            payload: `File ${is_large_file.name} is too big, Upload up to 5 MB`,
                          });
        //end taken
        return true
  }

  const handleOnChangeMainImg = (e) => {
    const files = e.target.files;
    if(checkFilesSize(files) !== true) return;

    if(mainImg && mainImg?.src) setFileIdsToDelete([...fileIdsToDelete, mainImg.fileId]);
    
    //setMainImg as base64 for preview
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      setMainImg({src: base64String, main: true, isNew: true, file: files[0]});
      // setMainImg({src: base64String, main: true, isNew: true, file: renameFile(files[0], `${new Date().getTime()}.${files[0].name.slice(((files[0].name.lastIndexOf(".") - 1) >>> 0) + 2)}`)});
    };
    reader.readAsDataURL(files[0]);
  }

  const handleChangeSiteImages = (e) => {
    const files = e.target.files;
    if(checkFilesSize(files) !== true) return;

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
  
      reader.onloadend = () => {
        const base64String = reader.result;
        setNewlyUploadedImgs(prevFiles => {
          return [
            ...prevFiles,
            { src: base64String, main: false, file: files[i]}

            // { src: base64String, main: false, file: renameFile(files[i], `${new Date().getTime()}.${files[i].name.slice(((files[i].name.lastIndexOf(".") - 1) >>> 0) + 2)}`)}
          ];
        });
      };
      reader.readAsDataURL(files[i]);
    }
  }

  const handleChangeSitePdfs = (e) => {
    const files = e.target.files;
    if(checkFilesSize(files) !== true) return;

    for (let i = 0; i < files.length; i++) {
      setNewlyUploadedPdfs(prevFiles => {
        return [
          ...prevFiles,
          {src: files[i].name, index: i, main: false, type: "pdf", for: "", language: "", file: files[i]}
        ];
      });

      setMorePopup({isShow:true, pdf: {...files[i], index: i, main: false, type: "pdf", for: "", language: ""}});
    }
  }

  const discardUpdate = () => {
    SiteMngtContextDispatch({
      type: "SET_SITE",
      payload: { 
        currentDataType: "sites",
      }
    })
  }

  const handleChangePdfData = (value, key) => {
    const tmpPdfs = [...newlyUploadedPdfs];
    const foundPdfIndex = tmpPdfs.findIndex(file=>file?.index === morePopup.pdf.index);
    if(foundPdfIndex === -1) return;
    tmpPdfs[foundPdfIndex] = {...tmpPdfs[foundPdfIndex], [`${key}`]: value.value};
    setNewlyUploadedPdfs(tmpPdfs);
    setMorePopup({isShow: true, pdf: {...morePopup.pdf, [`${key}`]: value.value}});
  }

  const handleCheckData = () => {
    //check that pdf has keys for and lang that not empty
    if(morePopup.pdf.for && morePopup.pdf.language) setMorePopup({isShow: false, pdf: null});
    else{
      dispatchSnackBar({
        type: "SET_ERROR",
        payload: "Please fill in all fields",
      });
    }
  }

  const blurValidation = (name, type, value, roles, formStep) => {
    return validate[type](value, roles);
  };

  const handleDiscardPdfUploading = ()=>{
    //remove file from array of uploaded pdfs by index
    const tmpPdfs = [...newlyUploadedPdfs];
    const foundPdfIndex = tmpPdfs.findIndex(file=>file?.index === morePopup.pdf.index);
    if(foundPdfIndex === -1) return;
    tmpPdfs.splice(foundPdfIndex, 1);
    setNewlyUploadedPdfs(tmpPdfs);

    setMorePopup({isShow: false, pdf: null});
  }

  const handleDeleteNewlyAddedFile = (state, stateSetter, index) => {
    const newState = [...state];
    newState.splice(index, 1);
    stateSetter(newState);
  };

  const handleDeleteUploadedFile = (fileId) => {
    const prevFiles = [...files];
    const foundFileIndex = files.findIndex(file=> +file?.fileId === +fileId);
    if(foundFileIndex === -1) return;
    prevFiles.splice(foundFileIndex, 1);
    setFiles(prevFiles);
    setFileIdsToDelete([...fileIdsToDelete, fileId])
  }
  
  const handleCheckMainImgDuplicate = async() => {
    //check if its more that 1 main img => delete all except last. 
    const mainImgsArr = files.filter(file=>file.moreData.type === "img" && file.moreData.main);
    //set last as main then remove it from arr.
    setMainImg(mainImgsArr[mainImgsArr.length-1]);
    
    if(mainImgsArr.length <= 1) return;
    mainImgsArr.pop();
    for (const img of mainImgsArr) {
      await priorityApi.deletingExistingFile(img.fileId, site_id.current);
    }
  }

  useEffect(()=>{
    if(site_id.current === SiteMngtContextData.site.id) return;

    site_id.current = SiteMngtContextData.site.id;
    if(SiteMngtContextData.site.files){
      setFiles(SiteMngtContextData.site.files);
    }
  },[SiteMngtContextData]);

  useEffect(() => {
    handleCheckMainImgDuplicate();
  }, [files])
  

  return (
    <>
    <div style={{display:'flex', justifyContent:'center', gap: 30}}>
      <h1>Update media files : {SiteMngtContextData.site.name}</h1>
    </div>
    <div style={containerStyle}>
      <div style={{width: '50%', gridArea: '1 / 1 / 2 / 3', overflow: 'hidden'}}>
          <h2 className="sub-title">Tourism Site Main Image</h2>
          <div className="ImageInput">
            <div className="preview-wrapper">
              {mainImg &&
                <div className={"preview-image loaded"} style={{cursor: 'default'}}>
                  <span 
                    onClick={() => {mainImg.isNew ? setMainImg(null) : handleDeleteUploadedFile(mainImg.fileId)}}
                    style={{position: 'absolute', top: '8px', right: '8px', cursor: 'pointer'}}
                  >
                    <RemoveIcon/>
                  </span>
                  <img
                    src={mainImg.src}
                  />
                </div>
              }
            </div>
            <div className="input-wrapper">
              <label htmlFor="file-input2">Upload Main Image</label>
              <input 
                id="file-input2"
                type="file"
                accept={".jpg, .jpeg, .bmp, .gif, .png"} 
                onChange={handleOnChangeMainImg}
              />
            </div>
          </div>
      </div>
      <div style={{width: '100%', gridArea: '2 / 1 / 3 / 2', overflow: 'hidden'}}>
          <h2 className="sub-title">Tourism Site Images</h2>
          <div className="ImageInput">
            <div className="preview-wrapper">
            {files?.length > 0 && files.filter(file => file.moreData?.type === "img" && !file.moreData.main).map((img) => {
              return  <div className={"preview-image loaded"} style={{cursor: 'default'}}>
                        <span 
                          onClick = {() => handleDeleteUploadedFile(img.fileId)}
                          style={{position: 'absolute', top: '8px', right: '8px', cursor: 'pointer'}}
                        >
                          <RemoveIcon/>
                        </span>
                        <img
                          src={img.src}
                        />
                      </div>
            })}
            {newlyUploadedImgs.length > 0 && newlyUploadedImgs.map((img, key) => {
              return  <div key={key} className={"preview-image loaded"} style={{cursor: 'default'}}>
                        <span 
                        onClick={()=>handleDeleteNewlyAddedFile(newlyUploadedImgs, setNewlyUploadedImgs, key)}
                          style={{position: 'absolute', top: '8px', right: '8px', cursor: 'pointer'}}
                        >
                          <RemoveIcon/>
                        </span>
                        <img
                          src={img.src}
                        />
                      </div>
            })}
            </div>
            <div className="input-wrapper">
              <label htmlFor="file-input">Upload Site Images</label>
              <input 
                id="file-input"
                type="file"
                accept={".jpg, .jpeg, .bmp, .gif, .png"} 
                multiple="true"
                onChange={handleChangeSiteImages}
              />
            </div>
          </div>
      </div>
      <div style={{width: '100%', gridArea: '2 / 2 / 3 / 3', overflow: 'hidden'}}>
          <h2 className="sub-title">Tourism Site Brochures</h2>
            <div className="PdfInput">
              {morePopup.isShow && 
                <div className="more-data">
                  <div className="container">
                    <div className="title">Brochures Details</div>
                    <form>
                      <FloatingSelect
                        color="#93c840"
                        onChange={(value) => {
                          handleChangePdfData(value, "for");
                        }}
                        label="Type *"
                        placeholder=""
                        options={[
                          { value: "Tourist", label: "Tourist" },
                          { value: "Agent", label: "Agent" },
                        ]}
                        selectValue={morePopup?.pdf?.for}
                        validation={blurValidation}
                        validationType="yesno"
                        validationRoles={{ name: "Type", require: true }}
                      />
                      <FloatingSelect
                        color="#93c840"
                        onChange={(value) => {
                          handleChangePdfData(value, "language");
                        }}
                        label="Language *"
                        placeholder=""
                        options={[
                          { value: "Hebrew", label: "Hebrew" },
                          { value: "English", label: "English" },
                          { value: "French", label: "French" },
                          { value: "Spanish", label: "Spanish" },
                          { value: "German", label: "German" },
                          { value: "Polish", label: "Polish" },
                          { value: "Italian", label: "Italian" },
                          { value: "Russian", label: "Russian" },
                          { value: "Chinese", label: "Chinese" },
                          { value: "Arabic", label: "Arabic" },
                          { value: "Portuguese", label: "Portuguese" },
                          { value: "Japanese", label: "Japanese" },
                        ]}
                        selectValue={morePopup?.pdf?.language}
                        validation={blurValidation}
                        validationType="yesno"
                        validationRoles={{ name: "Language", require: true }}
                      />
                      <div className="btn-wrapper">
                        <div
                          className="btn red"
                          onClick={handleDiscardPdfUploading}
                        >
                          Discard
                        </div>
                        
                        <div className="btn green" onClick={handleCheckData}>
                          Save
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              }
              <div className="preview-wrapper">
                {files?.length > 0 && files.filter(file => file.moreData?.type === "pdf").map((pdf) => {
                  return (
                    <div className={"preview-image loaded"} style={{cursor: 'default'}}>
                      <span 
                        onClick = {() => handleDeleteUploadedFile(pdf.fileId)}
                        style={{position: 'absolute', top: '8px', right: '8px', cursor: 'pointer'}}
                      >
                        <RemoveIcon/>
                      </span>
                      <div className="pdf">
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="36" viewBox="0 0 27 36" fill="red">
                          <path
                            id="Icon_awesome-file-pdf"
                            data-name="Icon awesome-file-pdf"
                            d="M12.79,18.007c-.352-1.125-.345-3.3-.141-3.3C13.24,14.709,13.184,17.3,12.79,18.007Zm-.12,3.319a32.444,32.444,0,0,1-2,4.409,25.891,25.891,0,0,1,4.423-1.54A9.108,9.108,0,0,1,12.67,21.326ZM6.054,30.1c0,.056.928-.38,2.454-2.827A9.718,9.718,0,0,0,6.054,30.1ZM17.438,11.25H27V34.313A1.683,1.683,0,0,1,25.313,36H1.688A1.683,1.683,0,0,1,0,34.313V1.688A1.683,1.683,0,0,1,1.688,0H15.75V9.563A1.692,1.692,0,0,0,17.438,11.25Zm-.562,12.08a7.057,7.057,0,0,1-3-3.783c.316-1.3.816-3.277.436-4.514a1.761,1.761,0,0,0-3.361-.478c-.352,1.287-.028,3.1.57,5.414A66.025,66.025,0,0,1,8.648,26c-.007,0-.007.007-.014.007-1.905.977-5.175,3.129-3.832,4.781a2.185,2.185,0,0,0,1.512.7c1.259,0,2.51-1.266,4.3-4.345a40.079,40.079,0,0,1,5.555-1.631,10.656,10.656,0,0,0,4.5,1.371,1.82,1.82,0,0,0,1.385-3.052c-.977-.956-3.818-.682-5.175-.506ZM26.508,7.383,19.617.492A1.686,1.686,0,0,0,18.422,0H18V9h9V8.571A1.682,1.682,0,0,0,26.508,7.383ZM21.3,25.334c.288-.19-.176-.837-3.009-.633C20.9,25.812,21.3,25.334,21.3,25.334Z"
                          />
                        </svg>
                        <br />
                        {/* cutting domain and timestamp from string */}
                        <label style={{wordBreak: 'break-word', fontSize: '11px'}}>{pdf?.src?.split('-').slice(1).join('-')}</label>
                      </div>
                    </div>
                  );
                })}
                {newlyUploadedPdfs?.length > 0 && newlyUploadedPdfs.map((pdf) => {
                  return (
                    <div className={"preview-image loaded"} style={{cursor: 'default'}}>
                      <span style={{position: 'absolute', top: '8px', right: '8px', cursor: 'pointer'}}><RemoveIcon/></span>
                      <div className="pdf">
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="36" viewBox="0 0 27 36" fill="red">
                          <path
                            id="Icon_awesome-file-pdf"
                            data-name="Icon awesome-file-pdf"
                            d="M12.79,18.007c-.352-1.125-.345-3.3-.141-3.3C13.24,14.709,13.184,17.3,12.79,18.007Zm-.12,3.319a32.444,32.444,0,0,1-2,4.409,25.891,25.891,0,0,1,4.423-1.54A9.108,9.108,0,0,1,12.67,21.326ZM6.054,30.1c0,.056.928-.38,2.454-2.827A9.718,9.718,0,0,0,6.054,30.1ZM17.438,11.25H27V34.313A1.683,1.683,0,0,1,25.313,36H1.688A1.683,1.683,0,0,1,0,34.313V1.688A1.683,1.683,0,0,1,1.688,0H15.75V9.563A1.692,1.692,0,0,0,17.438,11.25Zm-.562,12.08a7.057,7.057,0,0,1-3-3.783c.316-1.3.816-3.277.436-4.514a1.761,1.761,0,0,0-3.361-.478c-.352,1.287-.028,3.1.57,5.414A66.025,66.025,0,0,1,8.648,26c-.007,0-.007.007-.014.007-1.905.977-5.175,3.129-3.832,4.781a2.185,2.185,0,0,0,1.512.7c1.259,0,2.51-1.266,4.3-4.345a40.079,40.079,0,0,1,5.555-1.631,10.656,10.656,0,0,0,4.5,1.371,1.82,1.82,0,0,0,1.385-3.052c-.977-.956-3.818-.682-5.175-.506ZM26.508,7.383,19.617.492A1.686,1.686,0,0,0,18.422,0H18V9h9V8.571A1.682,1.682,0,0,0,26.508,7.383ZM21.3,25.334c.288-.19-.176-.837-3.009-.633C20.9,25.812,21.3,25.334,21.3,25.334Z"
                          />
                        </svg>
                        <br />
                        <label style={{wordBreak: 'break-word', fontSize: '11px'}}>{pdf?.src}</label>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="input-wrapper">
                <label htmlFor="pdf-input">Upload Brochures</label>
                <input id="pdf-input" type="file" accept={".pdf, .PDF"} onChange={handleChangeSitePdfs}/>
              </div>
            </div>
      </div>
    </div>
    <div style={{display:'flex', justifyContent:'center', gap: 30, marginTop: '30px'}}>
      {isLoading ? 
        <div className='abs-loader-div'>
          <Loader/>
        </div>
        :
        <>
          <button
            style={{...buttonsStyle, backgroundColor: '#e10000'}}
            className="btn red"
            onClick={discardUpdate}
          >
            Discard
          </button>
          <button
            style={{...buttonsStyle, backgroundColor: 'green'}}
            className="btn green"
            onClick={(e) => {
              handleUpdateFiles();
            }}
          >
            Update
          </button>
        </>
      }
    </div>
    </>
  )

  
}

export default FormUpdate