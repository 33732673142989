import "./App.scss";
import React, { useCallback, useContext, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
//ComponentS & Pages
import Preloader from "./components/Preloader";
import Header from "./components/Header";
import Main from "./pages/Main";
import Login from "./pages/Login";
import Register from "./pages/Register";
import UsersMngt from "./pages/UsersMngt";
import SiteMngt from "./pages/SiteMngt";
import Map from "./pages/Map";
import SitePage from "./pages/SitePage";
import MyOrders from "./pages/MyOrders";
import Footer from "./components/Footer";
import SnackBars from "./components/SnackBars";
import NotFound from "./pages/NotFound";
import Password from "./pages/Password";
import PasswordReset from "./pages/PasswordReset";

//Context
import { AuthContext } from "./context/AuthContext";
import LoginTypeContextProvider from "./context/LoginTypeContext";
import SiteMngtContextProvider from "./context/SiteMngtContext";
import UsersContextProvider from "./context/UsersContext";

//api
import * as api from "./shared/api";
import * as priorityApi from "./shared/priorityApi";
import * as priorityServices from "./utils/priorityServices";


function App() {
  const { authContextData, authContextDispatch } = useContext(AuthContext);
  const doVerify = useCallback(async () => {
    // const test = await priorityApi.ClearEntityMetadata(); //clear metadata of priority
    // console.log('test', test)
    const token = localStorage.getItem("ittt");
    if (token) {
      try {
        const res = await api.verify(token);
        if (res.data.success) {                
          authContextDispatch({ type: "DO_LOGIN", payload: { isAuth: true, logedUser: res.data.data } });
          const resPriority = await priorityApi.verifyPriorityUser(priorityServices.verifyUserPriority(res.data.data));
          if(resPriority) {
            authContextDispatch({ type: "DO_LOGIN", payload: { isAuth: true, logedUser: res.data.data, priorityUserData: resPriority.data }})
          }
        } else {

          localStorage.removeItem("ittt");
          authContextDispatch({ type: "DO_LOGIN", payload: { isAuth: false, logedUser: null } });
        }
      } catch (err) {
        localStorage.removeItem("ittt");
        authContextDispatch({ type: "DO_LOGIN", payload: { isAuth: false, data: null } });
      }
    } else {
      authContextDispatch({ type: "DO_LOGIN", payload: { isAuth: false, logedUser: null } });
    }
  }, [authContextDispatch]);

  useEffect(() => {
    doVerify();
  }, [doVerify]);
  
  return authContextData ? (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          <Route exact path="/">
            <LoginTypeContextProvider>
              <Main />
            </LoginTypeContextProvider>
          </Route>
          <Route exact path="/login">
            <LoginTypeContextProvider>
              <Login />
            </LoginTypeContextProvider>
          </Route>
          <Route exact path="/register">
            <Register />
          </Route>

          <PrivateRoute path="/sitemngt" provider={SiteMngtContextProvider} permissions={[2, 3]} component={SiteMngt} />
          <PrivateRoute path="/usermngt" provider={UsersContextProvider} permissions={[3]} component={UsersMngt} />
          <PrivateRoute path="/map" provider={null} permissions={[1, 3]} component={Map} />
          <PrivateRoute path="/my-orders" provider={null} permissions={[1, 3]} component={MyOrders} />
          <PrivateRoute path="/site/:id" provider={null} permissions={[1,2,3]} component={SitePage} />

          <Route path="/password" provider={null} component={Password} />
          <Route path="/password-reset/:token" provider={null} component={PasswordReset} />

          <Route component={NotFound} />
        </Switch>
        {/* <Footer /> */}
        <SnackBars />
      </Router>
    </div>
  ) : (
    <Preloader />
  );
}

export default App;
