import "./FloatingSelect.scss";
import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";

const FloatingSelect = (props) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: " 100%",
      fontFamily: "Heebo",
      fontWeight: "400",
      fontSize: "16px",
      padding: "4px 15px",
      borderRadius: "30px",
      border: state.isFocused ? `1px solid ${props.color} ` : "1px solid #909090",
      boxShadow: state.isFocused ? "none" : "none",
      boxShadow: state.isSelected ? "none" : "none",
      "&:hover": {
        borderColor: state.isFocused ? props.color : "#909090",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? props.color : state.isFocused ? `${props.color}10` : "#fff",
      padding: "10px 15px",
      fontFamily: "Heebo",
      fontWeight: "400",
      fontSize: "16px",
    }),
    menu: () => ({
      position: "absolute",
      width: "100%",
      zIndex: "100",
      backgroundColor: "#fff",
      top: "50px",
    }),
    menuList: () => ({
      padding: "0",
      border: `1px solid ${props.color}`,
      borderRadius: "30px",
      overflow: "hidden",
    }),
  };
  const [float, setFloat] = useState(false);
  const [focused, setFocused] = useState(false);
  const inputRef = useRef();
  const [tooltip, setTooltip] = useState();
  useEffect(() => {
    setFloat(props.selectValue === "" && !focused ? false : true);
  }, [props.selectValue]);

  return (
    <div className="FloatingSelect">
      <label
        className={float ? (focused ? `float ${props.color === "#93c840" ? "orange" : null} focused` : `float `) : null}
        onClick={() => {
          inputRef.current.focus();
          setFocused(true);
          return props.selectValue === "" ? setFloat(true) : null;
        }}
      >
        {props.label}
      </label>
      <Select
        {...props}
        ref={inputRef}
        styles={customStyles}
        value={ props.options.filter((obj) => obj.value === props.selectValue)}
        onFocus={() => {
          setFloat(true);
          setFocused(true);
        }}
        onBlur={() => {
          setFloat(props.selectValue == "" ? false : true);
          setFocused(false);
          setTooltip(
            props.validation(
              props.name,
              props.validationType,
              props.options.filter((obj) => obj.value === props.selectValue),
              props.validationRoles,
              props.formStep
            )
          );
        }}
        name={props.name}
      />
      {tooltip?.valid === false ? <div className={"tooltip " + props.direction}>{tooltip?.msg}</div> : null}
    </div>
  );
};

export default FloatingSelect;
