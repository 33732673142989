import React, { useState } from "react";
import Select from "react-select";

const SelectInput = (props) => {
  const areaStyles = {
    control: (provided, state) => ({
      ...provided,
      fontSize: "18px",
      "@media (max-width: 1300px)": {
        fontSize: "14px",
        width: "200px",
      },
      "@media (max-width: 1300px)": {
        width: "180px",
        margin: "auto",
      },
      "@media (max-width: 768px)": {
        width: "80%",
      },
      "@media (max-width: 500px)": {
        width: "100%",
      },
      padding: "1px 15px",
      borderRadius: "10px",
      background: "transparent",
      border: "none",
      width: "250px",
      color: "#fff",
      border: state.isFocused ? `1px solid #93c840 ` : "1px solid transparent",
      boxShadow: state.isFocused ? "none" : "none",
      boxShadow: state.isSelected ? "none" : "none",
      "&:hover": {
        borderColor: "#93c840",
      },
    }),
    placeholder: (provided, state) => ({
      color: "#fff",
    }),
    dropdownIndicator: () => ({
      display: " flex",
      padding: "8px",
      color: "inherit",
    }),
    singleValue: () => ({
      color: "#fff",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#fff",
      padding: "10px 15px",
      fontWeight: "400",
      fontSize: "16px",
      "&:hover": {
        background: "#93c840",
        color: "#fff",
      },
      background: state.isSelected ? "#93c840" : "#fff",
    }),
    menu: () => ({
      position: "absolute",
      width: "100%",
      zIndex: "100",
      backgroundColor: "#fff",
      top: "50px",
      borderRadius: "10px",
      overflow: "hidden",
    }),
    menuList: () => ({
      padding: "0",
      borderRadius: "10px",
      overflow: "hidden",
    }),
  };
  const languageStyles = {
    container: (provided, state) => ({
      ...provided,
      padding: "10px",
    }),
    control: (provided, state) => ({
      ...provided,
      fontSize: "16px",
      borderRadius: "10px",
      background: "transparent",
      border: "none",
      width: "100%",
      color: "#000",
      border: "1px solid #dedede ",
      boxShadow: state.isFocused ? "none" : "none",
      boxShadow: state.isSelected ? "none" : "none",
      "&:hover": {
        borderColor: "#93c840",
      },
    }),
    placeholder: (provided, state) => ({
      color: "#000",
    }),
    dropdownIndicator: () => ({
      display: " flex",
      padding: "8px",
      color: "inherit",
    }),
    singleValue: () => ({
      color: "#000",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "#fff",
      padding: "10px 15px",
      fontWeight: "400",
      fontSize: "14px",
      "&:hover": {
        background: "#93c840",
        color: "#fff",
      },
      background: state.isSelected ? "#93c840" : "#fff",
    }),
    menu: (state) => ({
      position: "absolute",
      width: "90%",
      zIndex: "100",
      backgroundColor: "#fff",
      top: "50px",
      borderRadius: "10px",
      overflow: "hidden",
      height: "200px",
      overflowY: "scroll",
      boxShadow: "0px 5px 10px 0px rgba(0,0,0,0.2)",
      "&::-webkit-scrollbar": {
        width: "10px",
      },
      "&::-webkit-scrollbar-track ": {
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#93c840",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "$orange-hover",
      },
    }),
    menuList: () => ({
      padding: "0",
      borderRadius: "10px",
      overflow: "hidden",
    }),
  };
  const [focused, setFocused] = useState(false);
  return (
    <div className='SelectInput'>
      <Select
        {...props}
        value={props.options.filter((obj) => obj.value === props.selectValue)}
        styles={props.styles === "areaStyles" ? areaStyles : languageStyles}
        onFocus={() => {
          setFocused(true);
        }}
        name={props.name}
        placeholder={props.placeholder}
      />
    </div>
  );
};

export default SelectInput;
