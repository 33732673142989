import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { checkPassword } from "../utils/validation";
import * as api from "../shared/api";

import Input from "../components/inputs/Input";

import "./PasswordReset.scss";

const PasswordReset = (props) => {
  const [password, setPassword] = useState();
  const [isSent, setIsSent] = useState(false);
  const [token, setToken] = useState();
  const [msg, setMsg] = useState("");

  const getToken = () => {
    let userToken = props.location.pathname;
    userToken = userToken.replace("/password-reset/", "");
    setToken(userToken);
  };

  const handleChange = (e) => {
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    getToken();
  }, []);

  const resetPassword = async () => {
    let data = {
      token,
      newPass: password,
    };

    if (!token) {
      setMsg("Something went wrong. contact the admin for more information.");
    } else {
      if (!password) {
        setMsg("Password field can't be empty.");
      } else {
        let check = checkPassword(password);

        if (!check.valid) {
          setMsg(check.msg);
        } else {
          let res = await api.resetPassword(data);
          let success = res.data.success;
          if (success === "1") {
            setIsSent(true);
          } else if (success === "0") {
            setIsSent(false);
            setMsg("Token is expired or doesn't match to user token");
          }
        }
      }
    }
  };

  return (
    <div className="Password-Reset">
      <div className="container">
        <div className="content-wrapper">
          <div className="content">
            <h1 className="title">Password Reset</h1>
            {!isSent ? <h2 className="sub-title">Insert your new password below</h2> : <></>}
            {!isSent ? (
              <h3>
                Password must contain <strong>minimum</strong> eight characters and one letter{" "}
              </h3>
            ) : (
              <></>
            )}

            {!isSent ? (
              <form>
                <Input type="text" placeholder="Password" name="password" onChange={handleChange} onBlur={""} min="8" />

                <button
                  className={"btn resetPass"}
                  onClick={(e) => {
                    e.preventDefault();
                    resetPassword();
                  }}
                >
                  Reset your password
                </button>

                <div className="msg" style={{ marginTop: "10px", color: "#EE4B2B	" }}>
                  {msg}
                </div>
              </form>
            ) : (
              <div className="afterSent">
                You have reset your password successfully!
                <br />
                Please go back and login to your account.
                <br />
                <Link className="login-btn" to="/">
                  Login
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className="image"></div>
      </div>
    </div>
  );
};

export default PasswordReset;
