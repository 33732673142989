import "./NotFound.scss";
import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="NotFound">
      <div class="content">
        <h1>404</h1>
        <h2>Oops!</h2>
        <p>It seems you have reached a page that does not exist.</p>
        <Link to="/">Home Page</Link>
      </div>
    </div>
  );
};

export default NotFound;
