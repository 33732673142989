import React, { createContext, useReducer } from "react";
import { siteMngtReducer } from "./SiteMngtReducer";

export const SiteMngtContext = createContext();

const SiteMngtContextProvider = (props) => {
  const [SiteMngtContextData, SiteMngtContextDispatch] = useReducer(siteMngtReducer, {
    currentDataType: "sites",
    site: null,
    activity: null,
  });

  return (
    <SiteMngtContext.Provider value={{ SiteMngtContextData, SiteMngtContextDispatch }}>
      {props.children}
    </SiteMngtContext.Provider>
  );
};
export default SiteMngtContextProvider;
