import "./CheckboxInput.scss";
import React from "react";

const CheckboxInput = (props) => {
  // console.log(props);
  return (
    <div className="CheckboxInput">
      <label className="checkbox bounce">
        <input
          type="checkbox"
          className="checkbox"
          onChange={(e) => {
            props.onChange(props.group, props.value, e);
          }}
          name={props.name}
          checked={props.defaultChecked}
        />
        <svg viewBox="0 0 21 21">
          <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
        </svg>
        <span>{props.placeholder}</span>
      </label>
    </div>
  );
};

export default CheckboxInput;
