import "./Header.scss";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const Header = () => {
  const history = useHistory();
  const { authContextData, authContextDispatch } = useContext(AuthContext);
  const [dropDown, setDropDown] = useState(false);
  const dropDownRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (e.target !== dropDownRef.current) {
        setDropDown(false);
      }
    });
  }, []);

  return (
    <div className="Header">
      <div className="content">
        <div className="logo">
          <img
            src={require("../assets/images/logo.png").default}
            alt="Logo"
            onClick={() => {
              history.push("/");
            }}
          />
        </div>
        <div className="title">
          <h2>Information Center</h2>
        </div>
        {authContextData.logedUser ? (
          <div className="user">
            <img src={require("../assets/images/user-icon.png").default} className="profile-image" alt="profile" />
            <h3 className="user-name">{authContextData.logedUser.name}</h3>
            <div
              className="drop-down-btn"
              ref={dropDownRef}
              onClick={(e) => {
                setDropDown(!dropDown);
              }}
            ></div>
            <ul className="drop-down" style={dropDown ? { display: "block" } : null}>
              <li className="user">
                <img src={require("../assets/images/user-icon.png").default} className="profile-image" alt="profile" />
                <h3 className="user-name">{authContextData.logedUser.name}</h3>
              </li>
              <li
                onClick={() => {
                  history.push("/my-orders");
                }}
              >
                My Orders
              </li>
              <li
                onClick={() => {
                  localStorage.removeItem("ittt");
                  authContextDispatch({ type: "DO_LOGOUT", payload: { isAuth: false, data: null } });
                }}
              >
                Log Out
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
