import "./Activity.scss";
import React, { useContext } from "react";
import { SiteMngtContext } from "../context/SiteMngtContext";

const Activity = (props) => {

  const { SiteMngtContextDispatch } = useContext(SiteMngtContext);

  const setTariff = () => {
    SiteMngtContextDispatch({
      type: "SET_ACTIVITY",
      payload: { activity: { id: props.data.KLINE, name: props.data.ACTDES }, currentDataType: "tariffs" },
    });
  };

  return (
    <div className="Activity">
      <div className="details">
        <h2 className="name">{props.data.ACTDES}</h2>
        <p className="description" dangerouslySetInnerHTML={{ __html: props.data.ZITA_WEBACTTEXT_SUBFORM?.TEXT }}></p>
      </div>
      <div className="btns">
        <div className="btn" onClick={setTariff}>
          Price List
        </div>
        <div className="btn blue" onClick={()=>props.handleDuplicateActivity(props.data.ACT)}>
          Duplicate
        </div>
      </div>
    </div>
  );
};

export default Activity;
