import React from "react";
import Slider from "@material-ui/core/Slider";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
const SliderInput = (props) => {
  const CustomSlider = createMuiTheme({
    overrides: {
      MuiSlider: {
        root: {
          height: 8,
          width: "300px",
          padding: "13px 0px !important",
          "@media (max-width: 1300px)": {
            width: "190px",
          },
          "@media (max-width: 992px)": {
            width: "150px",
          },
          "@media (max-width: 768px)": {
            width: "80%",
            margin: "auto",
            marginBottom: "10px"
          },
          "@media (max-width: 500px)": {
            width: "100%",
          },
        },
        thumb: {
          height: 24,
          width: 24,
          backgroundColor: "#93c840",
          border: "7px solid #fff",
          marginTop: -8,
          marginLeft: -12,
          "&:focus, &:hover, &$active": {
            boxShadow: "inherit",
          },
        },
        track: {
          height: 8,
          borderRadius: 4,
          backgroundColor: "#93c840",
        },
        rail: {
          height: 8,
          borderRadius: 4,
          backgroundColor: "#e2e2e2",
        },
      },
    },
  });
  return (
    <ThemeProvider theme={CustomSlider}>
      <Slider value={props.value} onChange={props.onChange} aria-labelledby="continuous-slider" />
    </ThemeProvider>
  );
};

export default SliderInput;
