import "./SiteMngt.scss";
import React, { useCallback, useContext, useEffect, useState } from "react";

//Data components
import Site from "../components/Site";
import Activity from "../components/Activity";
import Tariff from "../components/Tariff";
//Form
import FormWithXl from "../components/forms/FormWithXl";
import SiteForm from "../components/forms/SiteForm";
import ActivityForm from "../components/forms/ActivityForm";
import TariffForm from "../components/forms/TariffForm";

import Loader from "../components/Loader";

import { SiteMngtContext } from "../context/SiteMngtContext";
import * as priorityApi from "../shared/priorityApi";
import { AuthContext } from "../context/AuthContext";
import FormUpdate from "../components/forms/FormUpdate";
import {ReactComponent as PrevIcon} from '../assets/images/arrow_back.svg'; 
import {ReactComponent as NextIcon} from '../assets/images/arrow_forward.svg'; 

const SiteMngt = () => {
  const { authContextData } = useContext(AuthContext);
  
  
  const { SiteMngtContextData, SiteMngtContextDispatch } = useContext(SiteMngtContext);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoding] = useState(false);
  const [search, setSearch] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [showFormWithXl, setShowFormWithXl] = useState(false);
  const [showBeforeForm, setShowBeforeForm] = useState(false);
  const [page, setPage] = useState(0);
  const [duplicatedActivity, setDuplicatedActivity] = useState(null);
  const step = 100;

  const getSites = useCallback(async (newPage) => {

    if(!newPage) newPage = page;
    if (authContextData.logedUser.type === 2) {
      setLoding(true);
      setSearch("");
      const res = await priorityApi.getSitesByProvider(authContextData.logedUser.provider, step, newPage);
      setData(res.data.value);
      setPage(newPage);
      setLoding(false);
    }
    if (authContextData.logedUser.type === 3) {
      setLoding(true);
      setSearch("");
      const res = await priorityApi.getSitesByProvider("*", step, newPage);
      setData(res.data.value);
      setPage(newPage);
      setLoding(false);
    }
  });

  const getActivities = useCallback(async () => {
    setLoding(true);
    setSearch("");
    const res = await priorityApi.getActivity(SiteMngtContextData.site.id);
    setData(res.data.value[0]?.ZITA_WEBACT_SUBFORM);
    setLoding(false);
  });

  const getTariffs = useCallback(async () => {
    setLoding(true);
    setSearch("");
    const res = await priorityApi.getTariff(SiteMngtContextData.site.id, SiteMngtContextData.activity.id);
    if (res.data.value[0].ZITA_WEBACT_SUBFORM.length === 0) {
      setData([]);
    } else {
      setData(res.data.value[0].ZITA_WEBACT_SUBFORM[0]?.ZITA_WEBACTPLS_SUBFORM);
    }
    setLoding(false);
  });

  const addNewData = (newData) => {
    setData(data?.concat(newData));
  };

  const getFilteredSites = useCallback(async () => {
    setLoding(true);
    const res = await priorityApi.getSitesByStr(search);
    setFilteredData(res.data.value);
    setLoding(false);
  });

  useEffect(() => {
    discardForm();
    switch (SiteMngtContextData.currentDataType) {
      case "sites":
        return getSites();
      case "activities":
        return getActivities();
      case "tariffs":
        return getTariffs();
    }
  }, [SiteMngtContextData.currentDataType]);

  useEffect(() => {
    switch (SiteMngtContextData.currentDataType) {
      case "sites":
        if (search.length === 0) {
          return setFilteredData(data);
        } else if (search.length >= 4) {
          getFilteredSites();
        }
      case "activities":
        if (search === "") {
          return setFilteredData(data);
        } else {
          return setFilteredData(
            data?.filter((item) => {
              return item.ACTDES?.toLowerCase().includes(search.toLowerCase());
            })
          );
        }

      case "tariffs":
        if (search === "") {
          return setFilteredData(data);
        } else {
          return setFilteredData(
            data?.filter((item) => {
              return item.TICKETDES?.toLowerCase().includes(search.toLowerCase());
            })
          );
        }
      default:
        return //setFilteredData(data);
    }
  }, [data, SiteMngtContextData.currentDataType, search]);

  const discardForm = () => {
    setShowForm(false);
  };

  const discardFormExcel = () => {
    setShowFormWithXl(false);
  };

  const renderScreen = () => {
    setShowBeforeForm(true);
    setShowForm(false);
    setShowFormWithXl(false);
  };

  const handleDuplicateActivity = (activityId) => {
    if(!activityId) return
    setShowForm(true);
    setDuplicatedActivity(data?.find(act=>+act.ACT === +activityId));
  }

  return (
    <div className="SiteMngt">
      <div className="container">
        <div className="content">
          <div className="data">
            <div className="breadcrumbs">
              <ul>
                <li
                  className={SiteMngtContextData.currentDataType === "sites" ? "active" : null}
                  onClick={() => {
                    SiteMngtContextDispatch({ type: "DISCARD_SITE" });
                  }}
                >
                  Tourism Sites
                </li>
                {SiteMngtContextData.currentDataType === "activities" ||
                SiteMngtContextData.currentDataType === "tariffs" ? (
                  <li
                    className={SiteMngtContextData.currentDataType === "activities" ? "active" : null}
                    onClick={() => {
                      SiteMngtContextDispatch({ type: "DISCARD_ACTIVITY" });
                    }}
                  >
                    Activities
                  </li>
                ) : null}
                {SiteMngtContextData.currentDataType === "tariffs" ? <li className="active">Price List</li> : null}
              </ul>
            </div>
            <div className="search-wrapper">
              <input
                type="text"
                placeholder="Search..."
                name="search"
                autoComplete="off"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            <div className="data-title">
              {SiteMngtContextData.currentDataType === "activities" ? SiteMngtContextData.site.name : null}
              {SiteMngtContextData.currentDataType === "tariffs" ? SiteMngtContextData.activity.name : null}
            </div>
            <div className={loading || filteredData?.length === 0 ? "data-list loading" : "data-list"}>
              {loading ? (
                <Loader />
              ) : (SiteMngtContextData.currentDataType === "sites" || SiteMngtContextData.currentDataType === 'update') ? (
                filteredData?.length > 0 ? (
                  filteredData?.map((item) => {
                    return <Site data={item} />;
                  })
                ) : (
                  <div className="no-results">No results</div>
                )
              ) : SiteMngtContextData.currentDataType === "activities" ? (
                filteredData?.length > 0 ? (
                  filteredData?.map((item) => {
                    return <Activity data={item} handleDuplicateActivity={handleDuplicateActivity}/>;
                  })
                ) : (
                  <div className="no-results">No results</div>
                )
              ) : SiteMngtContextData.currentDataType === "tariffs" ? (
                filteredData?.length > 0 ? (
                  filteredData?.map((item) => {
                    return <Tariff data={item} />;
                  })
                ) : (
                  <div className="no-results">No results</div>
                )
              ) : null}
            </div>
            {SiteMngtContextData.currentDataType === "sites" ? (
              <div className='bottom-btns'>
                <div className="pagination">
                  <button className='btn prev' disabled={page===0} onClick={() => getSites(page-1)}>
                    <PrevIcon/>
                    <span>Previous Page</span>
                  </button>
                  <button className='btn next' disabled={filteredData?.length < 100} onClick={() => getSites(page+1)}>
                    <span>Next Page</span>
                    <NextIcon/>
                  </button>
                </div>
                <div
                  className="add-new"
                  onClick={() => {
                    renderScreen();
                  }}
                >
                  <img src={require("../assets/images/plus.png").default} />
                  <h2 className="text">Create Tourism Site</h2>
                </div>
              </div>
            ) : null}
            {SiteMngtContextData.currentDataType === "activities" ? (
              <div
                className="add-new"
                onClick={() => {
                  setShowForm(true);
                }}
              >
                <img src={require("../assets/images/plus.png").default} />
                <h2 className="text">Create Activity</h2>
              </div>
            ) : null}
            {SiteMngtContextData.currentDataType === "tariffs" ? (
              <div
                className="add-new"
                onClick={() => {
                  setShowForm(true);
                }}
              >
                <img src={require("../assets/images/plus.png").default} />
                <h2 className="text">Create Price List</h2>
              </div>
            ) : null}
          </div>
          <div className="form">
            {SiteMngtContextData.currentDataType === "sites" && showForm ? (
              <SiteForm
                discard={() => {
                  discardForm();
                }}
                addNewData={addNewData}
              />
            ) : null}
            {SiteMngtContextData.currentDataType === "sites" && showFormWithXl ? (
              <FormWithXl
                discard={() => {
                  discardFormExcel();
                }}
                addNewData={addNewData}
              />
            ) : null}
            {SiteMngtContextData.currentDataType === "sites" && showBeforeForm ? (
              <div className="action-btns-container">
                <button
                className=""
                onClick={() => {
                  setShowForm(true);
                  setShowBeforeForm(false);
                }}
                > Create with form </button>
                <button
                className=""
                onClick={() => {
                  setShowFormWithXl(true);
                  setShowBeforeForm(false);
                }}
                > Create with Excel </button>
              </div>
        
            ) : null}


            
            {SiteMngtContextData.currentDataType === "activities" && showForm ? (
              <ActivityForm
                discard={() => {
                  discardForm();
                }}
                addNewData={addNewData}
                duplicatedActivity={duplicatedActivity}
              />
            ) : null}

            {SiteMngtContextData.currentDataType === "tariffs" && showForm ? (
              <TariffForm
                discard={() => {
                  discardForm();
                }}
                addNewData={addNewData}
              />
            ) : null}

            {(SiteMngtContextData.currentDataType === "update") && 
              <FormUpdate filteredData={filteredData} setFilteredData={setFilteredData}/>
            }

            {!showForm && !showBeforeForm && !showFormWithXl && SiteMngtContextData.currentDataType !== "update" ? (
              <h2 className="instruction">In this window you can create Tourist Sites, Activities and Price List</h2>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteMngt;
