import "./FullActivity.scss";
import React, { useEffect, useRef, useState, useCallback, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import FloatingDateInput from "../components/inputs/FloatingDateInput";
import FloatingInput from "../components/inputs/FloatingInput";
import FloatingTextarea from "../components/inputs/FloatingTextarea";
import Loader from "../components/Loader";
import { SnackBarsContext } from "../context/SnackBarsContext";
import * as validate from "../utils/validation";
import * as priorityServices from "../utils/priorityServices";
import * as priorityApi from "../shared/priorityApi";
import * as api from "../shared/api";
import moment from "moment";

const FullActivity = (props) => {
  const { authContextData } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [priceList, setPriceList] = useState([]);
  const [order, setOrder] = useState({ date: "", time: "", notes: "" });

  const contentRef = useRef(null);
  const createPriceListState = (arr) => {
    let priceListArr = [];
    for (let i = 0; i < arr.length; i++) {
      let include = priceListArr.filter((item) => {
        return item.type === arr[i].TICKETDES + (arr[i].PAR2DES ? " " + arr[i].PAR2DES : "");
      });
      if (include.length === 0) {
        let prices = arr.filter((item) => {
          return (
            item.TICKETDES + (item.PAR2DES ? " " + item.PAR2DES : "") ===
            arr[i].TICKETDES + (arr[i].PAR2DES ? " " + arr[i].PAR2DES : "")
          );
        });

        // console.log(prices)
        priceListArr.push({
          type: prices[0].TICKETDES + (prices[0].PAR2DES ? " " + prices[0].PAR2DES : ""),
          agent: prices[0]?.PAR1DES === "Agent" ? prices[0]?.PRICE : prices[1]?.PRICE,
          group: prices[0]?.PAR1DES === "Group" ? prices[0]?.PRICE : prices[1]?.PRICE,
          amount: 0,
          sku_agent: prices[0]?.PAR1DES === "Agent" ? prices[0]?.PARTNAME : prices[1]?.PARTNAME,
          sku_group: prices[0]?.PAR1DES === "Group" ? prices[0]?.PARTNAME : prices[1]?.PARTNAME,
        });
      }
    }
    setPriceList(priceListArr);
  };
  const setAmount = (index, amount, isReset) => {
    let prevArr = [...priceList];
    if (amount === -1 && prevArr[index].amount === 0) {
      return null;
    } else {
      if(isReset){
        prevArr[index].amount = amount >= 0 ? amount : 0;
      } else {
        prevArr[index].amount = prevArr[index].amount + amount;
      }
    }
    setPriceList(prevArr);
  };

  // //new katy. atem dibilim 
  // const newsetAmount = (value) => {
  //   const prevArr = [...priceList];
  //   if (value < 0) return;
  // }

  const getTotalAmount = () => {
    let total = 0;
    priceList.map((item) => {
      total = total + item.amount;
    });
    return total;
  };
  const getTotalPriceAgent = () => {
    let total = 0;
    priceList.map((item) => {
      total = total + item.amount * item.agent;
    });
    return total;
  };
  const getTotalPriceGroup = () => {
    let total = 0;
    priceList.map((item) => {
      total = total + item.amount * item.group;
    });
    return total;
  };
  const handleChange = (e) => {
    // console.log(e)
    setOrder({
      ...order,
      [e.target.name]: e.target.value,
    });
  };

  const blurValidation = (name, type, value, roles) => {
    if (validate[type](value, roles).valid) {
      setValidation({ ...validation, [name]: true });
    } else {
      setValidation({ ...validation, [name]: false });
    }
    return validate[type](value, roles);
  };
  
  const handleChangeTime = (name, e) => {
    setOrder({
      ...order,
      [name]: e,
    });
  };

  const [validation, setValidation] = useState({
    date: false,
    time: false,
    notes: false,
  });

  const { dispatchSnackBar } = useContext(SnackBarsContext);

  const sendOrder = useCallback(async () => {

    setLoading(true);
    let data = {
      id: authContextData?.priorityUserData.ID,
      owner: props.siteName,
      ownerEmail: props.owner,
      name: authContextData?.priorityUserData.CUSTNAME,
      phone: Number(authContextData?.logedUser.phone.replace(/-/g, "")),
      email: authContextData?.logedUser.email,
      date: moment(order.date).format("YYYY-MM-DD"),
      time: order.time,
      notes: order.notes,
      tickets: [{...priceList[0]}], //new katy
    };

    try {
      const res = await priorityApi.createOrder(priorityServices.createOrderObj(data));
      if (res.status === 200 || res.status === 201) {
        //new katy
        const mailsToAttractionOwner = [
          {
            email: props?.all_attraction_data?.EMAIL,
            name: `${props?.all_attraction_data?.ATTRDES}'s owner`
          },
          {
            email: props?.all_attraction_data?.PHONEMAIL1,
            name: props?.all_attraction_data?.PHONENAME1
          },
          {
            email: props?.all_attraction_data?.PHONEMAIL2,
            name: props?.all_attraction_data?.PHONENAME2
          },         
        ].filter(mail => typeof mail.email === 'string' && mail.email.trim().length > 0);
        if (mailsToAttractionOwner.length > 0) {
          for (const mailObj of mailsToAttractionOwner) {
            const mail_res = await api.order({
              recipientEmail: mailObj.email,
              owner: mailObj.name,
              name: authContextData?.logedUser?.name,
              phone: authContextData?.logedUser?.phone,
              email: authContextData?.logedUser?.email,
              date: moment(order.date).format("YYYY-MM-DD"),
              time: order.time,
              notes: order.notes,
              tickets: [{...priceList[0]}],
            })
          }
        }

        dispatchSnackBar({
          type: "SET_SUCCESS",
          payload: "Your Order Sent Successfully",
        });
      } else {
        dispatchSnackBar({
          type: "SET_ERROR",
          payload: "Something went wrong please contact support",
        });
      }
    } catch (err) {
      console.log(err)
      dispatchSnackBar({
        type: "SET_ERROR",
        payload: "Something went wrong please contact support",
      });
    }
    setOrder({ date: "", time: "", notes: "" });
    for (let index = 0; index < priceList.length; index++) {
      let prevArr = [...priceList];
      prevArr[index].amount = 0;
      setPriceList(prevArr);
    }
    setLoading(false); 
  });

  useEffect(() => {
    createPriceListState(props.data.ZITA_WEBACTPLS_SUBFORM);
  }, []);

  return (
    <div className="FullActivity">
      <div className="container">
        <div className="top-bar">
          <div
            className="name"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? (
              <img src={require("../assets/images/minus.png").default} />
            ) : (
              <img src={require("../assets/images/plus.png").default} />
            )}
            Activity: {props.data?.ACTDES}
          </div>
          <div className="details">
            <span className="data">
              <img src={require("../assets/images/duration.png").default} />
              {props.data.DURATION}
            </span>
            <span className="data">
              <img src={require("../assets/images/days.png").default} />
              {props.data.DAYS?.split(";").join(", ")}
            </span>
            <span className="data">
              <img src={require("../assets/images/group.png").default} />
              {props.data.MINNUM} - {props.data.MAXNUM}
            </span>
          </div>
        </div>
        <div className="information" style={{ height: open ? `${contentRef?.current.scrollHeight}px` : "0px" }}>
          <div className="content" ref={contentRef}>
            <div className="text">
              <div className="title">About the Activity:</div>
              <p dangerouslySetInnerHTML={{ __html: props.data?.ZITA_WEBACTTEXT_SUBFORM?.TEXT }}></p>
              <div className="title">Extras:</div>
              <ul>
                {props.data?.ZITA_WEBACTADD_SUBFORM.map((line) => (
                  <li>{line.ACTADDDES}</li>
                ))}
              </ul>
              <div className="title">Cancellation and Booking Conditions:</div>
              <p dangerouslySetInnerHTML={{ __html: props.data?.ZITA_WEBACTTEXT2_SUBFORM?.TEXT }}></p>
              <div className="title">Opening Hours:</div>
              <div className="time">
                <div className="day">Sunday:</div>
                <div className="time">
                  {props.data.SUNSTIME !== "00:00" && props.data.SUNETIME !== "00:00"
                    ? " " + props.data.SUNSTIME + " - " + props.data.SUNETIME
                    : "Not Working"}
                </div>
              </div>
              <div className="time">
                <div className="day">Monday:</div>
                <div className="time">
                  {props.data.MONSTIME !== "00:00" && props.data.MONETIME !== "00:00"
                    ? " " + props.data.MONSTIME + " - " + props.data.MONETIME
                    : "Not Working"}
                </div>
              </div>
              <div className="time">
                <div className="day">Tuesday:</div>
                <div className="time">
                  {props.data.TUESTIME !== "00:00" && props.data.TUEETIME !== "00:00"
                    ? " " + props.data.TUESTIME + " - " + props.data.TUEETIME
                    : "Not Working"}
                </div>
              </div>
              <div className="time">
                <div className="day">Wednesday:</div>
                <div className="time">
                  {props.data.WEDSTIME !== "00:00" && props.data.WEDETIME !== "00:00"
                    ? " " + props.data.WEDSTIME + " - " + props.data.WEDETIME
                    : "Not Working"}
                </div>
              </div>
              <div className="time">
                <div className="day">Thursday:</div>
                <div className="time">
                  {props.data.THUSTIME !== "00:00" && props.data.THUETIME !== "00:00"
                    ? " " + props.data.THUSTIME + " - " + props.data.THUETIME
                    : "Not Working"}
                </div>
              </div>
              <div className="time">
                <div className="day">Friday:</div>
                <div className="time">
                  {props.data.FRISTIME !== "00:00" && props.data.FRIETIME !== "00:00"
                    ? " " + props.data.FRISTIME + " - " + props.data.FRIETIME
                    : "Not Working"}
                </div>
              </div>
              <div className="time">
                <div className="day">Saturday:</div>
                <div className="time">
                  {props.data.SATSTIME !== "00:00" && props.data.SATETIME !== "00:00"
                    ? " " + props.data.SATSTIME + " - " + props.data.SATETIME
                    : "Not Working"}
                </div>
              </div>
            </div>
            <div className="price-list">
              <div className="table-wrapper">
                {priceList.length !== 0 ? (
                  <table>
                    <tr>
                      <th></th>
                      <th>Agent</th>
                      <th>Group</th>
                      <th>Order</th>
                    </tr>
                    {priceList.map((item, index) => {
                      return (
                        <tr>
                          <td>
                            {item.type.split(" ")[0]}
                            <br />
                            {item.type.slice(item.type.split(" ")[0].length, item.type.length)}
                          </td>
                          <td>₪ {item.agent}</td>
                          <td>₪ {item.group}</td>
                          <td>
                            <div className="input-wrapper">
                              <img
                                src={require("../assets/images/minus.png").default}
                                onClick={() => {
                                  setAmount(index, -1);
                                }}
                              />
                              <input value={item.amount} onChange={(e)=>setAmount(index, +e.target.value, true)}/>
                              <img
                                src={require("../assets/images/plus.png").default}
                                onClick={() => {
                                  setAmount(index, 1);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td>Total</td>
                      <td>{getTotalPriceAgent()}</td>
                      <td>{getTotalPriceGroup()}</td>
                      <td>{getTotalAmount()}</td>
                    </tr>
                  </table>
                ) : null}
              </div>
              <div className="order-details">
                <FloatingDateInput
                  color="orange"
                  label="Date *"
                  value={order.date}
                  onChange={(e) => {
                    handleChangeTime("date", e);
                  }}
                  name="date"
                  validation={blurValidation}
                  validationType="date"
                  validationRoles={{ name: "Date", require: true }}
                  direction="left"
                  formStep="1"
                  popperPlacement="bottom"
                  popperModifiers={{
                    flip: {
                      behavior: ["bottom"], // don't allow it to flip to be above
                    },
                  }}
                />
                <FloatingInput
                  color="orange"
                  label="Time *"
                  placeholder="00:00"
                  value={order.time}
                  onChange={handleChange}
                  name="time"
                  validation={blurValidation}
                  validationType="time"
                  direction="left"
                  validationRoles={{ name: "Opening", require: true }}
                />
                <FloatingTextarea
                  color="orange"
                  label="Notes *"
                  value={order.notes}
                  placeholder="00:00"
                  onChange={handleChange}
                  name="notes"
                  validation={blurValidation}
                  validationType="text"
                  validationRoles={{ name: "Notes", require: true }}
                  formStep="1"
                  direction="left"
                />
              </div>
              {loading ? (
                <Loader />
              ) : (
                <div className="submit" onClick={sendOrder}>
                  Send Order
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullActivity;
