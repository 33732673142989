import "./Tariff.scss";
import React from "react";

const Tariff = (props) => {
  return (
    <div className="Tariff">
      <div className="details">
        <h2 className="name">{props.data.TICKETDES}</h2>
        <ul className="description">
          {props.data.PAR1DES ? <li>{props.data.PAR1DES}</li> : null}
          {props.data.PAR2DES ? <li>{props.data.PAR2DES}</li> : null}
          {props.data.PAR3DES ? <li>{props.data.PAR3DES}</li> : null}
          {props.data.PAR4DES ? <li>{props.data.PAR4DES}</li> : null}
        </ul>
      </div>
      <div className="price">
        {props.data.PRICE}
        <span>₪</span>
      </div>
    </div>
  );
};

export default Tariff;
