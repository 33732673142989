import "./Login.scss";
import React, { useContext, useState, useEffect, useCallback } from "react";
import Input from "../components/inputs/Input";
import PasswordInput from "../components/inputs/PasswordInput";
import Loader from "../components/Loader";
import { Link, useHistory } from "react-router-dom";
import { LoginTypeContext } from "../context/LoginTypeContext";
import { AuthContext } from "../context/AuthContext";
import { SnackBarsContext } from "../context/SnackBarsContext";
import * as validate from "../utils/validation";
import * as api from "../shared/api";
import * as priorityServices from "../utils/priorityServices";
import * as priorityApi from "../shared/priorityApi";
import News from "../components/news";

const Login = () => {
  const { authContextData, authContextDispatch } = useContext(AuthContext);
  const { loginTypeContextData } = useContext(LoginTypeContext);
  
  const history = useHistory();
  const [user, setUser] = useState({ email: "", password: "" });
  const [loader, setLoader] = useState(false);
  const { dispatchSnackBar } = useContext(SnackBarsContext);

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleBlur = (e, a) => {
    return validate[e.target.name](e.target.value, true);
  };

  //temp data for test 
  let priorityUser = 'A7C54A1D7F2349959C957EDB5D325FEE';
  let priorityUserPass = 'PAT';


  const doLogin = useCallback(async () => {
    setLoader(true);
    try {
      const res = await api.login(user);
      if (res.data.success) {
        if (res.data.data.type === loginTypeContextData.type) {
          authContextDispatch({ type: "DO_LOGIN", payload: { isAuth: true, logedUser: res.data.data } });
          localStorage.setItem("ittt", res.data.data.token);
          const resPriority = await priorityApi.verifyPriorityUser(priorityServices.verifyUserPriority(res.data.data), priorityUser, priorityUserPass);
          if(resPriority) {
            authContextDispatch({ type: "DO_LOGIN", payload: { isAuth: true, logedUser: res.data.data, priorityUserData: resPriority.data } })
          }
        } else {
          switch (loginTypeContextData.type) {
            case 1:
              dispatchSnackBar({
                type: "SET_ERROR",
                payload: "You are not agent.",
              });
              history.push("/");
              break;
            case 2:
              dispatchSnackBar({
                type: "SET_ERROR",
                payload: "You are not tourism site manager.",
              });
              history.push("/");
              break;
            case 3:
              dispatchSnackBar({
                type: "SET_ERROR",
                payload: "You are not system admin.",
              });
              history.push("/");
              break;
          }
        }
      } else {
        switch (res.data.code) {
          case 1:
            dispatchSnackBar({
              type: "SET_ERROR",
              payload: "Something went wrong please contact support",
            });
            setLoader(false);
            break;
          case 2:
            dispatchSnackBar({
              type: "SET_ERROR",
              payload: res.data.msg,
            });
            setLoader(false);
            break;
          default:
          // code block
        }
      }
    } catch (err) {
      setLoader(false);
    }
  });

  useEffect(() => {
    if (!loginTypeContextData) {
      history.push("/");
    }
  }, []);

  return (
    <div className="Login">
      <div className="container">
        <div className="content-wrapper">
          {!authContextData.isAuth ? (
            <div className="content">
              <h1 className="title">{loginTypeContextData?.data.title}</h1>
              <h2 className="sub-title">Israel Incoming Tour Operators Assoc.</h2>
              <form>
                <Input
                  type="text"
                  placeholder="Email"
                  name="email"
                  color={loginTypeContextData?.data.color}
                  value={user.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  validationType="email"
                />
                <PasswordInput
                  placeholder="Password"
                  name="password"
                  color={loginTypeContextData?.data.color}
                  value={user.password}
                  onChange={handleChange}
                />
                {!loader ? (
                  <button
                    className={"btn " + loginTypeContextData?.data.color}
                    onClick={(e) => {
                      e.preventDefault();
                      doLogin();
                    }}
                  >
                    Log In
                  </button>
                ) : (
                  <Loader />
                )}
              </form>
              <span>
                Forgot your password?{" "}
                <Link className="link" to="/password">
                  click here
                </Link>
              </span>
            </div>
          ) : (
            <div className="content">
              <h2 className="sub-title">Hello, {authContextData?.logedUser.name}</h2>
              {[1, 3].includes(authContextData.logedUser.type) ? (
                <Link to="map" className={"btn " + loginTypeContextData?.data.color}>
                  Israel Travel Catalog
                </Link>
              ) : null}
              {[2, 3].includes(authContextData.logedUser.type) ? (
                <Link to="sitemngt" className={"btn " + loginTypeContextData?.data.color}>
                  Tourism Sites Management
                </Link>
              ) : null}
              {[3].includes(authContextData.logedUser.type) ? (
                <Link to="usermngt" className={"btn " + loginTypeContextData?.data.color}>
                  User Management
                </Link>
              ) : null}
            </div>
          )}
          <a className="to-news" href="#news">
            <img src={require("../assets/images/news-icon.png").default} />
            <h3>News</h3>
            <img src={require("../assets/images/arrow-down.png").default} />
          </a>
        </div>
        <div className="image" style={{ backgroundImage: `url(${loginTypeContextData?.data.bg})` }}></div>
      </div>
      <News />
    </div>
  );
};

export default Login;
