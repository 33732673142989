import React, { createContext, useReducer } from "react";
import { loginTypeReducer } from "./LoginTypeReducer";

export const LoginTypeContext = createContext();

const LoginTypeContextProvider = (props) => {
  const [loginTypeContextData, loginTypeContextDispatch] = useReducer(loginTypeReducer, null);
  return (
    <LoginTypeContext.Provider value={{ loginTypeContextData, loginTypeContextDispatch }}>
      {props.children}
    </LoginTypeContext.Provider>
  );
};

export default LoginTypeContextProvider;
