import moment from "moment";

export const createSiteObj = (data) => {
  let newData = {
    TYPE: "A",
    INCLUDESTEXT: "Y",
    TEXT1: data.site_name.toLowerCase(),
    TEXT28: data.site_type.join(";"),
    TEXT29: data.site_holy_place.join(";"),
    CHAR1: data.site_food,
    CHAR2: data.site_alcohol,
    CHAR3: data.site_kids,
    CHAR4: data.site_accessibility,
    CHAR5: data.site_school,
    CHAR9 : data.site_kosher, 
    TEXT11: String(data.site_lat),
    TEXT12: String(data.site_lon),
    TEXT30: data.site_languages.join(";"),
    TEXT31: data.site_holidays.join(";"),
    TEXT32: data.site_area.join(";"),
    TEXT: data.site_street_number,
    TEXT26: data.site_postal_address,
    TEXT5: data.site_city,
    TEXT13: data.site_phone,
    TEXT14: data.site_fax,
    TEXT6: data.site_mail,
    TEXT10: data.site_site,
    TEXT8: data.person_first_role,
    TEXT15: data.person_first_phone,
    TEXT22: data.person_first_name,
    TEXT21: data.person_second_role,
    TEXT16: data.person_second_phone,
    TEXT20: data.person_second_name,
    TEXT23: data.person_first_email,
    TEXT24: data.person_second_email,
    DATE1: data.site_from_date ? moment(data.site_from_date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
    DATE2: data.site_to_date ? moment(data.site_to_date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
    TEXT2: data.site_provider,
    ZCOR_GENLOADLOGTEXT_SUBFORM: createDescriptionObj(data.site_description),
    
  };
  return newData;
};

export const createOrderObj = (data) => {

  let tempObj = {
    TYPE: "O",
    INCLUDESLINES: "Y",
    TEXT14: data.name,  //custname from login to priority
    TEXT15: data.id.toString() + new Date().getTime().toString(), //mispar azmana: user_id+timestamp
    INT2: data.id, //id from login to priority
    // INT2: 865,
    TEXT23: data.time,
    DATE1: moment(data.date).format("YYYY-MM-DD"),
    // DATE1: moment.utc(`${data.date} ${data.time}`, "YYYY-MM-DD HH:mm").toISOString(),
    
    ZCOR_GENERALLOADLOGL_SUBFORM:[] ,
    ZCOR_GENLOADLOGTEXT_SUBFORM: 
      {TEXT: data.notes}
    ,
  };
//data.tickets
  data.tickets.map((ticket)=>{
    tempObj.ZCOR_GENERALLOADLOGL_SUBFORM.push({
        TEXT12: ticket?.sku_agent,
        INT3: ticket?.amount,
        REAL1: ticket?.agent,
        REAL2: ticket?.agent * 1.17,
        DATE2: moment(data.date).format("YYYY-MM-DD"),
        TEXT23: data.time,
        // DATE2: moment.utc(`${data.date} ${data.time}`, "YYYY-MM-DD HH:mm").toISOString(),
      }, 
      // {
      //   TEXT12: ticket?.sku_group,
      //   INT3: ticket?.amount,
      //   REAL1: ticket?.group,
      //   REAL2: ticket?.group * 1.17,
      //   DATE2: moment(data.date).format("YYYY-MM-DD"),
      // }
    )
  })
  // console.log('sending obj', tempObj);
  return tempObj;
};

export const verifyUserPriority = (user) => {
  let newData = {
    "USERNAME": user.email,
    // "WEBPASSWORD": user.password
  };

  // Temp user given by Ishay
  // let newData = {
  //   "USERNAME": 'test@test.co.il',
  //   "WEBPASSWORD": '12345678'
  // };
  return newData;
};

export const createAactivityObj = (data) => {

  let min_participants;

  if(data.activity_participates === '10'){
    min_participants = '0';
  } else if(data.activity_participates === '50'){
    min_participants = '11';
  } else if(data.activity_participates === '150'){
    min_participants = '51';
  } else {
    min_participants = '151';
  }

  let newData = {
    TYPE: "F",
    INCLUDESTEXT: "Y",
    TEXT1: data.activity_name.toLowerCase(),
    INT2: data.site_id,
    TEXT12: data.activity_duration,
    TEXT11: data.activity_days.join(";"),
    TEXT13: data.activity_sunday_opening,
    TEXT14: data.activity_sunday_closing,
    TEXT15: data.activity_monday_opening,
    TEXT16: data.activity_monday_closing,
    TEXT17: data.activity_tuesday_opening,
    TEXT18: data.activity_tuesday_closing,
    TEXT19: data.activity_wednesday_opening,
    TEXT20: data.activity_wednesday_closing,
    TEXT21: data.activity_thursday_opening,
    TEXT22: data.activity_thursday_closing,
    TEXT23: data.activity_friday_opening,
    TEXT24: data.activity_friday_closing,
    TEXT25: data.activity_saturday_opening,
    TEXT26: data.activity_saturday_closing,
    INT10: parseInt(min_participants),
    INT11: parseInt(data.activity_participates),
    TEXT33: data.is_free_code,
    ZCOR_GENLOADLOGTEXT_SUBFORM: createDescriptionObj(data.activity_description),
  };
  
  return newData;
};

export const createFileObj = (id, data) => {
  let newData = {
    TYPE: "X",
    INT2: id,
    TEXT7: "File",
    //TEXT6: data.src,
    EXTFILENAME : data.src,
    CHAR1: data.moreData.type === "img" ? "Y" : null,
    CHAR2: data.moreData.type === "pdf" ? "Y" : null,
    INT6: data.moreData.main ? 1 : 0,
    TEXT4: JSON.stringify({ for: data.moreData.for, language: data.moreData.language }),
  };
  return newData;
};

export const createFileObjExcel = (id, name, data) => {
  let newData = {
    TYPE: "X", 
    TEXT12: id, 
    TEXT: name, 
    // INT3: EXTFILENUM מזהה נספח   >”, 
    TEXT7: "File",
    EXTFILENAME: data.src, 
    DATE1: data.moreData.file?.lastModifiedDate ? data.moreData.file.lastModifiedDate : new Date, 
    CHAR1: data.moreData.type === "img" ? "Y" : null,
    CHAR2: data.moreData.type === "pdf" ? "Y" : null,
    INT6: data.moreData.main ? 1 : 0,
    TEXT4: "File" , 
    
  };
  return newData;
};

export const updateFileObjExcel = (id, name, data) => {
  let newData = {
    TYPE: "X",
    TEXT12: id,
    TEXT: name,
    // INT3: EXTFILENUM מזהה נספח   >”, 
    TEXT7: "File",
    EXTFILENAME: data.src,
    DATE1: data.moreData.file?.lastModifiedDate ? data.moreData.file.lastModifiedDate : new Date, 
    CHAR1: data.moreData.type === "img" ? "Y" : null,
    CHAR2: data.moreData.type === "pdf" ? "Y" : null,
    INT6: data.moreData.main ? 1 : 0,
    TEXT4: "File",
    
  };
  return newData;
};

export const updateFileObjToSite = (id, name, data) => {
  let newData = {
    TYPE: "X",
    INT2: id,
    INT3: 4, //tmp
    TEXT7: "File",
    TEXT6: data.src,
    // EXTFILENAME : data.src,
    DATE1: new Date(),
    CHAR1: data.moreData.type === "img" ? "Y" : null,
    CHAR2: data.moreData.type === "pdf" ? "Y" : null,
    INT6: data.moreData.main ? 1 : 0,
    TEXT4: JSON.stringify({ for: data.moreData.for, language: data.moreData.language }),
    
  };
  return newData;
};

export const createAactivityCancellationObj = (idAttr, idAct, data) => {
  let newData = {
    TYPE: "D",
    INCLUDESTEXT: "Y",
    INT2: idAttr,
    INT3: idAct,
    ZCOR_GENLOADLOGTEXT_SUBFORM: createDescriptionObj(data),
  };
  return newData;
};

export const createAactivityAdditionObj = (idAttr, idAct, data) => {
  let newData = {
    TYPE: "E",
    INT2: idAttr,
    INT3: idAct,
    TEXT: data,
  };
  return newData;
};

export const createTariffObj = (idAttr, idAct, data) => {
  let newData = {
    TYPE: "G",
    INT2: idAttr,
    INT3: idAct,
    TEXT11: data.tarif_type,
    TEXT1: data.agent_group,
    TEXT2: data.tariff_description,
    REAL1: parseInt(data.tariff_price),
  };
  return newData;
};

export const createDescriptionObj = (text) => {
  let textObj = { TEXT: text, APPEND: false, SIGNATURE: false };
  return textObj;
};

export const disassemblyDescription = (text) => {
  let textLines = text.match(/.{1,100}/g);
  let array = [];
  textLines.map((line) => {
    array.push({ TEXT: line });
  });
  return array;
};

export const createSiteExcel = (data) => {
  let newData = {
    TYPE: "H", 
    TEXT12 : data.site_company_id,
    TEXT: data.site_name, 
    TEXT7 : data.site_description, 
    EXTFILENAME :  data.site_excel, 
  }
  
  return newData;
}

export const createSiteLngLat = (data) => {

  let newData = {
    TYPE : "M", 
    TEXT12 : data.site_company_id, 
    TEXT : data.site_name, 
    TEXT13 : data.site_lat + '', 
    TEXT14 : data.site_lon + '', 
  }
  
  return newData;
}

export const createPriorityAgent = (data) => {
  let newData = {
    TYPE: "J", 
    TEXT1 : data.current.name,
    TEXT5 : "",
    TEXT6: data.current.phone, 
    TEXT20 : data.current.email, 
    TEXT22 :  data.current.password, 
    CHAR1 : "Y",
  }
  
  return newData;
}

//new yosef 2023

export const deleteFile = () => {
  // let newData = {
  //   TYPE: "X",
  //   INT2: id,
  //   TEXT7: "File",
  //   //TEXT6: data.src,
  //   EXTFILENAME : data.src,
  //   CHAR1: data.moreData.type === "img" ? "Y" : null,
  //   CHAR2: data.moreData.type === "pdf" ? "Y" : null,
  //   INT6: data.moreData.main ? 1 : 0,
  //   TEXT4: JSON.stringify({ for: data.moreData.for, language: data.moreData.language }),
  // };
  // return newData;
}