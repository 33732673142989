import update from "react-addons-update";
export const usersReducer = (state, action) => {
  switch (action.type) {
    case "SET_USERS":
      return { ...state, usersList: action.payload };
    case "NEW_FORM":
      return { ...state, user: { edit: false, userData: {} } };
    case "EDIT_FORM":
      return {
        ...state,
        user: { edit: true, userData: action.payload },
      };
    case "DISCARD_FORM":
      return { ...state, user: null };
    case "CREATE_USER":
      return { ...state, usersList: [...state.usersList, action.payload] };
    case "UPDATE_USER":
      const index = state.usersList.findIndex((item) => item.id === action.payload.id);
      return update(state, {
        usersList: {
          [index]: {
            $set: action.payload.data,
          },
        },
      });
    case "DELETE_USERS":
      return {
        ...state,
        usersList: [...state.usersList.filter((user) => user.id !== action.payload)],
      };
    default:
      return state;
  }
};
