import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import "./OrderFullInfo.scss";
import * as priorityApi from "../shared/priorityApi";
import { AuthContext } from '../context/AuthContext';
import Loader from './Loader';

const OrderFullInfo = React.forwardRef(({order_data}, ref) => {
  const [orderInfo, setOrderInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);


  const handleGetExpandedOrderData = async() => {
      setIsLoading(true);
      const res = await priorityApi.getOrderData(order_data.FSCN_NUMBER);
      if(res && res?.data?.value?.length){
          setOrderInfo(res.data.value[0]);
      } else {
          setOrderInfo('No order Info');
      }
      setIsLoading(false);
  }

  useEffect(() => {
      handleGetExpandedOrderData();
  }, [order_data])

  return (
    <div className='order-data-container' ref={ref}>
      {
        isLoading &&
        <div className='abs-loader-div'>
          <Loader/>
        </div>
      }
      {
        !isLoading && 
        <>
          {typeof(orderInfo) === 'object' && Object.keys(orderInfo).length > 0 && 
            <>
              <div className='start-content'>
                <div className='order-no'>
                  Order: 
                  <b>#{order_data.FSCN_NUMBER}</b>
                </div>
                <div className='order-date'>
                  <b>{moment(order_data.STATUSDATE).format('MMMM DD, YYYY')}</b>
                </div>
              </div>
              <div className='main-content'>
                <div className='content-wrapper'>
                    <p><b>Attraction Name:</b> {orderInfo.ZITA_ATTRDES}</p>
                    <p><b>Activity Name:</b> {orderInfo.PDES}</p>
                    <p><b>Activity Date:</b> {moment(order_data.CURDATE).format('MMMM DD, YYYY')}</p>
                    {orderInfo.REMARK1 &&
                      <p><b>Activity Time:</b> {orderInfo.REMARK1} </p>
                    }
                    {/* <p><b>Status:</b> {order_data.ORDSTATUSDES}</p> */}
                </div>
              </div>
              <div className='bottom-content'>
                <div className='content-wrapper'>
                  <p><b>Price per unit:</b> {orderInfo.PRICE}₪ </p>
                  <p><b>Quantity:</b> {order_data.TOTQUANT}</p>
                  <p><b>Total before VAT:</b> {order_data.TOTPURCHASEPRICE}₪ </p>
                  <p><b>Total:</b> {order_data.TOTPRICE}₪ </p>
                </div>
              </div>
            </>
          }
          {
            typeof(orderInfo) === 'object' && Object.keys(orderInfo).length === 0 && 
            <div className='abs-loader-div'>
              <Loader/>
            </div>
          }
          {
            typeof(orderInfo) === 'string' && 
            <div className='abs-loader-div'>
              <p>{orderInfo}</p>
            </div>
          }
        </>
      }
    </div>
  )
})


export default OrderFullInfo;