import "./ImageInput.scss";
import React, { useState, useCallback, useContext, useEffect } from "react";
import * as api from "../../shared/api";
import * as priorityApi from "../../shared/priorityApi";
import * as priorityServices from "../../utils/priorityServices";
import { SnackBarsContext } from "../../context/SnackBarsContext";
import { AuthContext } from "../../context/AuthContext";
import { SiteMngtContext } from "../../context/SiteMngtContext";

const url = "https://infocenter.israeltravel.co.il/uploads/";

const ImageInput = (props) => {

  
  const allowed_image_format = ".jpg, .jpeg, .bmp, .gif, .png";

  const { dispatchSnackBar } = useContext(SnackBarsContext);
  const { authContextData } = useContext(AuthContext);
  const { SiteMngtContextData, SiteMngtContextDispatch } = useContext(SiteMngtContext);

  const [images, setImages] = useState([]);

  useEffect(()=>{
    const hasRelaventInfo = props.value.some(file => (file.moreData.type === "img" && !file.moreData.main))

    hasRelaventInfo ?
      setImages(
        props.value.filter((file) => (file.moreData.type === "img" && !file.moreData.main))
      )
    :
      setImages([]);

  },[props.value]);

  const [preview, setPreview] = useState(null);

  const handleChangeImage = (e) => {

    let is_large_file = {
      name: '',
      status: false
    };

    const files = e.target.files;

    if(files.length === 0) return;

    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      if (file.size / 1024 / 1024 > 5){
        is_large_file.name = file.name;
        is_large_file.status = true;
      } 
    }

    if(is_large_file.status) return dispatchSnackBar({
                        type: "SET_ERROR",
                        payload: `File ${is_large_file.name} is too big, Upload up to 5 MB`,
                      });

    // debugger;

    setImages([
      ...images,
      {
        src: "",
        uploading: true,
      },
    ]);

    // console.log(files);
    upload(files);
  };

  const upload = useCallback(async (files) => {

    const uploadFiles = [];

    for (let i = 0; i < files.length; i++) {
      let file = files.item(i)

      const res = await api.uploadFile(file);

      if (!res.data.success) continue;

      // console.log(res.data.file);
      uploadFiles.push({
        src : url + res.data.file,
        uploading: false,
        moreData: { type: "img", main: false },
      });

    }

    // if(uploadFiles.length === 0) return console.log("empty");

    setImages([
      ...images,
      ...uploadFiles,
    ]);

    props.add(uploadFiles);
  });

  const deleteFile = useCallback(async (src, fileId) => {

    let name = src.split("/");
    // const res = await api.deleteFile(name[name.length - 1]);
    // priorityApi.getUserOrders(authContextData.priorityUserData.CUSTNAME);
    const priorityRes = await priorityApi.deletingExistingFile(fileId, SiteMngtContextData.site.id);
    // if (res.data.success) {
    //   handleRemoveItem(src);
    // }
  });

  const handleRemoveItem = (file) => {
    setImages(
      images.filter((image) => {
        return image.src !== file;
      })
    );
    props.remove(file);
  };


  return (
    <div className="ImageInput">
      {preview ? (
        <div className="preview-popup">
          <div className="preview-content">
            <div
              className="close"
              onClick={() => {
                setPreview(null);
              }}
            >
              X
            </div>
            <img src={preview} alt="" />
          </div>
        </div>
      ) : null}

      <div className="preview-wrapper">
        {images.map((image, index) => {
          return (
            <div key={index} className={image.uploading ? "preview-image" : "preview-image loaded"}>
              <div className="options">
                <img
                  onClick={() => {
                    deleteFile(image.src, image.fileId);
                  }}
                  src={require("../../assets/images/white-delete.png").default}
                  alt=""
                />
                <img
                  src={require("../../assets/images/white-eye.png").default}
                  alt=""
                  onClick={() => {
                    setPreview(image.src);
                  }}
                />
              </div>
              <img src={image.uploading ? require("../../assets/images/loading.gif").default : image.src} alt="" />
            </div>
          );
        })}
      </div>
      <div className="input-wrapper">
        <label htmlFor="file-input">Upload Image</label>
        <input 
          id="file-input"
          type="file"
          accept={allowed_image_format}
          multiple 
          onChange={handleChangeImage}
        />
      </div>
    </div>
  );
};

export default ImageInput;
