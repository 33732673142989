import "./ImageInput.scss";
import React, { useState, useCallback, useContext, useEffect } from "react";
import * as api from "../../shared/api";
import { SnackBarsContext } from "../../context/SnackBarsContext";

const url = "https://infocenter.israeltravel.co.il/uploads/";

const MainImageInput = (props) => {

  const allowed_image_format = ".jpg, .jpeg, .bmp, .gif, .png";

  const { dispatchSnackBar } = useContext(SnackBarsContext);
  const [images, setImages] = useState([]);
  
  const [preview, setPreview] = useState(null);

  useEffect(()=>{

    const hasRelaventInfo = props.value.some(file => (file.moreData.type === "img" && file.moreData.main))

    hasRelaventInfo ?
      setImages(
        props.value.filter((file) => (file.moreData.type === "img" && file.moreData.main))
      )
    :
      setImages([]);

  },[props.value])

  const handleChangeImage = (e) => {

    let is_large_file = {
      name: '',
      status: false
    };

    const files = e.target.files;

    if(files.length === 0) return;

    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      if (file.size / 1024 / 1024 > 5){
        is_large_file.name = file.name;
        is_large_file.status = true;
      } 
    }

    if(is_large_file.status) return dispatchSnackBar({
                        type: "SET_ERROR",
                        payload: `File ${is_large_file.name} is too big, Upload up to 5 MB`,
                      });
                      
    setImages([{
      src: "",
      uploading: true,
    }]);

    upload(files);
  };

  const upload = useCallback(async (files) => {

    const uploadFiles = [];

    for (let i = 0; i < files.length; i++) {
      let file = files.item(i)

      const res = await api.uploadFile(file);
      if (!res.data.success) continue;

      uploadFiles.push({
        src : url + res.data.file,
        uploading: false,
        moreData: { type: "img", main: true },
      });

    }

    setImages([
      ...uploadFiles
    ]);

    props.add(uploadFiles);
  });

  const deleteFile = useCallback(async (file) => {
    let name = file.split("/");
    const res = await api.deleteFile(name[name.length - 1]);
    if (res.data.success) {
      setImages([]);
      props.add([]);
    }
  });

  return (
    <div className="ImageInput">
      {preview ? (
        <div className="preview-popup">
          <div className="preview-content">
            <div
              className="close"
              onClick={() => {
                setPreview(null);
              }}
            >
              X
            </div>
            <img src={preview} alt="" />
          </div>
        </div>
      ) : null}

      <div className="preview-wrapper">
        {images.map((image, index) => {
          return (
            <div key={index} className={image.uploading ? "preview-image" : "preview-image loaded"}>
              <div className="options">
                {/* <img
                  onClick={() => {
                    deleteFile(image.src);
                  }}
                  src={require("../../assets/images/white-delete.png").default}
                  alt=""
                /> */}
                <img
                  src={require("../../assets/images/white-eye.png").default}
                  alt=""
                  onClick={() => {
                    setPreview(image.src);
                  }}
                />
              </div>
              <img src={image.uploading ? require("../../assets/images/loading.gif").default : image.src} alt="" />
            </div>
          );
        })}
      </div>
      <div className="input-wrapper">
        <label htmlFor="file-input2">Upload Main Image</label>
        <input 
          id="file-input2"
          type="file"
          accept={allowed_image_format} 
          onChange={handleChangeImage}
        />
      </div>
    </div>
  );
};

export default MainImageInput;
