import "./PasswordInput.scss";
import React, { useState } from "react";

const PasswordInput = (props) => {
  const [tooltip, setTooltip] = useState();
  const [showPass, setshopPass] = useState(false);
  return (
    <div className="PasswordInput">
      <input
        type={showPass ? "text" : "password"}
        placeholder={props.placeholder}
        name={props.name}
        className={props.color}
        value={props.value}
        onChange={props.onChange}
        onBlur={() => {
          setTooltip(props.validation);
        }}
      />
      <div
        className={showPass ? "icon hide" : "icon show"}
        onClick={() => {
          setshopPass(!showPass);
        }}
      >
        <img src={require("../../assets/images/eye.png").default} alt="" />
      </div>
      {tooltip?.valid === false ? <div className="tooltip">{tooltip?.msg}</div> : null}
    </div>
  );
};

export default PasswordInput;
