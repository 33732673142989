import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { ReactComponent as OrderIcon } from '../assets/images/order-1-svgrepo-com.svg';


function OrderItem({order_data, handlerOnClick}) {
    return (
        <div className='order-item' onClick = {()=>handlerOnClick(order_data)}>
            <div className='order-start-content'>
                <div className='icon'>
                    <OrderIcon/>
                </div>
                <div className='order-num'>
                    Order: 
                    <b>#{order_data.FSCN_NUMBER}</b>
                </div>
            </div>
            <div className='order-end-content'>
                <div className='order-date'>
                    {moment(order_data.STATUSDATE).format('MMM DD, YYYY')}
                </div>
                <div className='cust-total'>
                    <b> ₪{order_data.TOTPRICE} </b>
                </div>
            </div>
        </div>
    )
}

export default OrderItem