export const loginTypeReducer = (state, action) => {
  switch (action.type) {
    case "SYSTEM":
      return {
        type: 1,
        data: {
          bg: require("../assets/images/agent-login-bg.jpg").default,
          title: "Agents login",
          color: "blue",
        },
      };
    case "SITE":
      return {
        type: 2,
        data: {
          bg: require("../assets/images/site-login-bg.jpg").default,
          title: "Tourism site manager login",
          color: "orange",
        },
      };
    case "USER":
      return {
        type: 3,
        data: {
          bg: require("../assets/images/admin-login-bg.jpg").default,
          title: "System admin login",
          color: "gray",
        },
      };
    default:
      return state;
  }
};
