import React, { useState } from "react";

import Input from "../components/inputs/Input";

import * as validate from "../utils/validation";
import * as api from "../shared/api";

import "./Password.scss";

const PasswordForgot = () => {
  const [email, setEmail] = useState();
  const [isSent, setIsSent] = useState(false);
  const [msg, setMsg] = useState("");

  const handleChange = (e) => {
    setEmail({
      ...email,
      [e.target.name]: e.target.value,
    });
  };

  const handleBlur = (e, a) => {
    return validate[e.target.name](e.target.value, true);
  };

  const forgotPassword = async () => {
    if (email) {
      let res = await api.forgotPassword(email);
      let success = res.data.success;
      //console.log(success);
      if (success === "1") {
        setIsSent(true);
      } else if (success === "0") {
        setIsSent(false);
        setMsg("Email doesn't exist");
      }
    } else if (!email) {
      setMsg("Please insert your email to proceed");
    }
  };

  return (
    <div className="Password">
      <div className="container">
        <div className="content-wrapper">
          <div className="content">
            <h1 className="title">Password Reset</h1>
            {!isSent ? <h2 className="sub-title">Insert your email to reset your password</h2> : <></>}
            {!isSent ? (
              <form>
                <Input
                  type="text"
                  placeholder="Email"
                  name="email"
                  value={email?.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  validationType="email"
                />
                <button
                  className={"btn resetPass"}
                  onClick={(e) => {
                    e.preventDefault();
                    forgotPassword();
                  }}
                >
                  Send Email
                </button>
                <div className="msg" style={{ marginTop: "10px", color: "#EE4B2B	" }}>
                  {msg}
                </div>
              </form>
            ) : (
              <div className="afterSent">
                An email for resetting your password has been sent to the address you have entered.
                <br />
                please check your email address and follow the instructions to reset your password.
              </div>
            )}
          </div>
        </div>
        <div className="image"></div>
      </div>
    </div>
  );
};

export default PasswordForgot;
