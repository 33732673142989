import axios from "axios";
const url = `${window.location.protocol}//${window.location.host}/`;
// const url = "http://localhost:3009/"; 

export const verify = async () => {
  axios.defaults.headers.common["Authorization"] = localStorage.getItem("ittt");
  return axios.get(url + "api/users/verify");
};

export const login = async (data) => {
  return axios.post(url + "api/users/login", { user: data });
};

export const getUsers = async () => {
  axios.defaults.headers.common["Authorization"] = localStorage.getItem("ittt");
  return axios.get(url + "api/users/");
};

export const deleteUser = async (id) => {
  axios.defaults.headers.common["Authorization"] = localStorage.getItem("ittt");
  return axios.post(url + `api/users/delete/${id}`);
};

export const registerUser = async (data, token) => {
  if (localStorage.getItem("ittt")) {
    axios.defaults.headers.common["Authorization"] = localStorage.getItem("ittt");
  } else {
    axios.defaults.headers.common["Authorization"] = token;
  }
  return axios.post(url + `api/users/register`, { data: data });
};

export const editUser = async (data, id) => {
  axios.defaults.headers.common["Authorization"] = localStorage.getItem("ittt");
  return axios.post(url + `api/users/update/${id}`, { data: data });
};

export const getGeo = async (address) => {
  delete axios.defaults.headers.common["Authorization"];
  return axios.get(`https://nominatim.openstreetmap.org/search`, {
    params: {
      q: address,
      format: 'json',
      countrycodes: 'il',
    },
    // withCredentials: true,
    // mode: 'cors',
    // credentials: 'include',
    // headers: {
    //   'Access-Control-Allow-Origin': '*',
    // },
  });

  // return axios.get(`https://nominatim.openstreetmap.org/search/${address}?format=json&countrycodes=il`);
};

export const uploadFile = async (file) => {
  axios.defaults.headers.common["Authorization"] = localStorage.getItem("ittt");
  const data = new FormData();
  data.append("file", file);

  return axios.post(url + "api/upload", data, {headers: {
    'content-type': 'multipart/form-data'
  }});
};

export const deleteFile = async (file) => {
  axios.defaults.headers.common["Authorization"] = localStorage.getItem("ittt");
  return axios.get(url + `api/upload/delete/${file}`);
};

export const regLogin = async (data) => {
  return axios.post(url + "api/users/reglogin", data);
};

export const createLog = async (data) => {
  axios.defaults.headers.common["Authorization"] = localStorage.getItem("ittt");
  return axios.post(url + "api/upload/sitetesting", data);
};

export const getRating = async (id) => {
  axios.defaults.headers.common["Authorization"] = localStorage.getItem("ittt");
  return axios.get(url + `api/rating/${id}`);
};

export const getBulkRating = async (data) => {
  axios.defaults.headers.common["Authorization"] = localStorage.getItem("ittt");
  return axios.post(url + `api/rating/bulk`, data);
};

export const setRating = async (data) => {
  axios.defaults.headers.common["Authorization"] = localStorage.getItem("ittt");
  return axios.post(url + `api/rating`, data);
};

export const getComments = async (id) => {
  axios.defaults.headers.common["Authorization"] = localStorage.getItem("ittt");
  return axios.get(url + `api/comments/${id}`);
};

export const setComment = async (data) => {
  axios.defaults.headers.common["Authorization"] = localStorage.getItem("ittt");
  return axios.post(url + `api/comments`, data);
};

export const getBulkComments = async (data) => {
  axios.defaults.headers.common["Authorization"] = localStorage.getItem("ittt");
  return axios.post(url + `api/comments/bulk`, data);
};

export const report = async (data) => {
  axios.defaults.headers.common["Authorization"] = localStorage.getItem("ittt");
  return axios.post(url + `api/mail/report`, data);
};
export const order = async (data) => {
  axios.defaults.headers.common["Authorization"] = localStorage.getItem("ittt");
  return axios.post(url + `api/mail/order`, data);
};

export const getNews = async () => {
  return axios.get("https://israeltravel.co.il/wp-json/wp/v2/posts?categories=14");
};

export const forgotPassword = async (email) => {
  return axios.post(url + `api/password/forgot`, { email });
};

export const resetPassword = async (data) => {
  return axios.post(url + `api/password/reset`, { data });
};
