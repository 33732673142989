import "./Register.scss";
import React, { useCallback, useContext, useRef, useState } from "react";
import { SnackBarsContext } from "../context/SnackBarsContext";
import * as api from "../shared/api";
import * as priorityApi from "../shared/priorityApi";
import { useHistory } from "react-router-dom";
import Loader from "../components/Loader";

const Register = () => {
  const history = useHistory();
  const { dispatchSnackBar } = useContext(SnackBarsContext);
  const [loading, setLoding] = useState(false);
  const [auth, setAuth] = useState(null);
  const username = auth?.credentials.split(";")[0];
  const password = auth?.credentials.split(";")[1];
  const [user, setUser] = useState({ username: "", password: "" });
  const data = useRef({
    type: 2,
    email: "",
    name: "",
    password: "",
    company: "",
    role: "",
    phone: "",
    provider: "",
  });

  const handleChangeUser = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeData = (e) => {
    data.current[e.target.name] = e.target.value;
  };

  const login = useCallback(async () => {
    setLoding(true);
    const res = await api.regLogin(user);
    if (res.data.success) {
      setAuth(res.data.data);
      setLoding(false);
    } else {
      switch (res.data.code) {
        case 1:
          setLoding(false);
          dispatchSnackBar({
            type: "SET_ERROR",
            payload: "משהו השתבש! אנא פנה למנהל המערכת.",
          });
          break;
        case 2:
          setLoding(false);
          dispatchSnackBar({
            type: "SET_ERROR",
            payload: res.data.msg,
          });
          break;
        default:
        // code block
      }
    }
  });

  const registerUser = useCallback(async () => {
    if (!auth.token) {
      dispatchSnackBar({
        type: "SET_ERROR",
        payload: "No permission, Login first.",
      });
      history.push("/");
      return null;
    }
    setLoding(true);
    if (data.current.provider.length === 0) {
      const provider = await getProvider();
      if (provider) {
        data.current.provider = provider;
      } else {
        setLoding(false);
        dispatchSnackBar({
          type: "SET_ERROR",
          payload: "Something went wrong please contact support.",
        });
        return;
      }
    }

    try {
      const res = await api.registerUser(data.current, auth.token);
      if (res.data.success) {
        dispatchSnackBar({
          type: "SET_SUCCESS",
          payload: "User Created.",
        });
        localStorage.removeItem("ittt");
        history.push("/");
      } else {
        switch (res.data.code) {
          case 0:
            dispatchSnackBar({
              type: "SET_ERROR",
              payload: "No permission, Login first.",
            });
            localStorage.removeItem("ittt");
            history.push("/");
            break;
          case 1:
            setLoding(false);
            dispatchSnackBar({
              type: "SET_ERROR",
              payload: "Something went wrong please contact support.",
            });
            break;
          case 2:
            setLoding(false);
            dispatchSnackBar({
              type: "SET_ERROR",
              payload: res.data.msg,
            });
            break;
          default:
        }
      }
    } catch (err) {
      setLoding(false);
      dispatchSnackBar({
        type: "SET_ERROR",
        payload: "Something went wrong please contact support.",
      });
      return;
    }
  });

  const getProvider = useCallback(async () => {
    if (data.current.name.length !== 0) {
      try {
        const res = await priorityApi.createUser(
          { TYPE: "S", TEXT1: data.current.name, TEXT23: data.current.phone, TEXT20: data.current.email },
        );
        if (res.data.TEXT3) {
          return res.data.TEXT3;
        }
      } catch (err) {
        return null;
      }
    } else {
      return null;
    }
  });

  return (
    <div className="Register">
      {auth ? (
        <div className="content register-part">
          <h1>Registration</h1>
          <p>* You are about to sign up for the Tourist Board system to manage your attractions.</p>
          <form>
            <div className="col">
              <input type="text" name="email" onChange={handleChangeData} placeholder="Email" />
              <input type="text" name="name" onChange={handleChangeData} placeholder="Full Name" />
              <input type="password" name="password" onChange={handleChangeData} placeholder="Password" />
            </div>
            <div className="col">
              <input type="text" name="company" onChange={handleChangeData} placeholder="Company" />
              <input type="text" name="role" onChange={handleChangeData} placeholder="Position" />
              <input type="text" name="phone" onChange={handleChangeData} placeholder="Phone Number" />
            </div>
            {loading ? (
              <div className="loader-wrapper">
                <Loader />
              </div>
            ) : (
              <div className="btn" onClick={registerUser}>
                Register
              </div>
            )}
          </form>
        </div>
      ) : (
        <div className="content login-part">
          <h1>Tourism Site Manager Register</h1>
          <form>
            <input type="text" name="username" onChange={handleChangeUser} placeholder="Username" />
            <input type="password" name="password" onChange={handleChangeUser} placeholder="Password" />
            {loading ? (
              <Loader />
            ) : (
              <div className="btn" onClick={login}>
                Log In
              </div>
            )}
          </form>
        </div>
      )}
    </div>
  );
};

export default Register;
